import { ApolloProvider } from '@apollo/client';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Button } from 'src/components/atoms/Button';
import { LoadingSpinner } from 'src/components/organisms/LoadingSpinner';
import { useLiff } from 'src/contexts/liff';
import { graphqlClient } from 'src/lib/graphqlClient';
import { centeredFlexLine } from 'styles/mixin';
import { colors, colorOptions } from 'styles/styles';

const StyledAuthorizedApollo = styled.div`
  height: 100vh;
  ${centeredFlexLine}
  padding: 2rem;
  text-align: center;
  .caution-title {
    font-family: 'Noto_Sans_JP_Bold', sans-serif;
    margin: 0;
  }
  .caution-description {
    font-size: 0.9rem;
    color: ${colors.darkGrey};
    margin: 0.5rem 0 1.5rem;
  }
`;

export const AuthorizedApolloProvider = ({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element => {
  const { idToken, initialized, inliffClient, loggedIn, closeWindow, isExpires, login, logout } =
    useLiff();
  const [storagedIdToken, setToken] = useState<string>(undefined);
  const token = idToken || storagedIdToken;

  // LIFFのIDトークンは有効期限が60分で、それを超えるとバックエンド側で認証ができなくなる。
  // そのため60分を超えると一度強制的にアプリケーションを再起動してもらうようにしている。
  if (isExpires()) {
    alert('サーバー接続の有効期限が切れました。再度アプリをお開きください。');
    sessionStorage.removeItem('idToken');
    if (inliffClient) {
      closeWindow();
    } else {
      logout();
    }
  }

  // 外部サイトからアプリケーションに戻ろうとするとIDトークンが取得できないという事象があるため、
  // 一度Session Storageに保存しておいて、アプリケーションに戻ったタイミングで再度IDトークンをSession Storageから取得する。
  // https://github.com/lincwell/femapp/pull/114
  useEffect(() => {
    if (idToken && !sessionStorage.getItem('idToken')) {
      sessionStorage.setItem('idToken', idToken);
    }
  }, [idToken]);

  useEffect(() => {
    const storagedIdToken = sessionStorage.getItem('idToken');
    if (storagedIdToken) {
      setToken(storagedIdToken);
    }
  }, []);

  const LoginButton = () => {
    return (
      <StyledAuthorizedApollo>
        <p className="caution-title">スマートフォンからのご利用が便利です</p>
        <p className="caution-description">
          こちらのブラウザでは画面が正しく表示されない可能性があります。
          <br />
          最適な状態でご利用いただくためにはスマートフォンでのご利用をお願いいたします。
        </p>
        <Button
          fontSize="1rem"
          padding="0.5rem 4rem"
          themeColor={colorOptions.BLACK}
          onClick={login}
        >
          このままログイン
        </Button>
      </StyledAuthorizedApollo>
    );
  };

  // LIFFログインするために、LINE Developers consoleにてLIFFアプリのエンドポイントURLを設定する必要があるため
  // なお、バックエンドにて、idTokenがnullでもアクセス許可するように認証処理を事前に緩和しておく必要あり
  if (!process.env.NEXT_PUBLIC_DEV_MODE) {
    if (!token) {
      if (!initialized) {
        return <LoadingSpinner />;
      }
      if (!loggedIn) {
        return <LoginButton />;
      }
    }
  }

  const client = graphqlClient(token);
  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};
