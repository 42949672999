import axios, { AxiosError } from 'axios';
import { useLiff } from 'src/contexts/liff';

// LINE Messaging Serviceのメッセージテンプレート
// https://github.com/lincwell/line-messaging-service/tree/develop/src/message_templates/femapp
type Template =
  | 'appExplanation'
  | 'appExplanationForPillBeginner'
  | 'appExplanationForPillExpert'
  | 'changingNotificationSetting'
  | 'stoppingPillIntake';
type Mode = 'menstruation' | 'pill';
type Values = { [key: string]: string };
type Response = {
  pushMessage: (template: Template, values?: Values) => void;
  changeRichMenu: (mode: Mode) => void;
};

export const useLineMessagingService = (): Response => {
  const { idToken } = useLiff();

  const pushMessage = (template: Template, values?: Values) => {
    const params = values ? { idToken, template, params: values } : { idToken, template };

    axios.get('/api/pushMessage', { params }).catch((error: AxiosError) => {
      console.log(error);
    });
  };
  const changeRichMenu = (mode: Mode) => {
    axios
      .get('/api/changeRichMenu', {
        params: {
          idToken,
          mode,
        },
      })
      .catch((error: AxiosError) => {
        console.log(error);
      });
  };

  return { pushMessage, changeRichMenu };
};
