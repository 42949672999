import React, { ReducerWithoutAction } from 'react';
import { CycleState, FemappUser, Note, UserBehavior } from 'src/generated/graphql';

export type Action =
  | { type: 'setfemappUser'; payload: FemappUser }
  | { type: 'setfemappUserBehaviors'; payload: UserBehavior[] }
  | { type: 'setfemappCycle'; payload: CycleState }
  | { type: 'setFemappUserNotes'; payload: Note[] }
  | { type: 'setSelectedDate'; payload: Date }
  | { type: 'handleCalendarIconDescriptionBottomSheet'; payload: boolean }
  | { type: 'handlePillDescriptionBottomSheet'; payload: boolean }
  | { type: 'handleNoteBottomSheet'; payload: boolean }
  | { type: 'handleCalendarHeader'; payload: boolean }
  | { type: 'setMaxPillInsightSheetNumber'; payload: number }
  | { type: 'setCalendarPageToast'; payload?: CalendarPageToastProps['name'] }
  | { type: 'setCalendarScrollPosition'; payload?: number }
  | { type: 'setUserPageToast'; payload?: UserPageToastProps['name'] };

export type State = {
  femappUser?: FemappUser;
  femappUserBehaviors?: UserBehavior[];
  femappCycle?: CycleState;
  femappUserNotes?: Note[];
  selectedDate?: Date;
  openCalendarIconDescriptionBottomSheet: boolean;
  openCalendarHeader: boolean;
  openPillDescriptionBottomSheet: boolean;
  openNoteBottomSheet: boolean;
  maxPillInsightSheetNumber?: number;
  calendarPageToast: CalendarPageToastProps['name'] | undefined;
  calendarScrollPosition: number | undefined;
  userPageToast: UserPageToastProps['name'] | undefined;
};

export type CalendarPageToastProps = {
  name: 'stoppingPillIntake';
  text: string;
  color: string;
};

export type UserPageToastProps = {
  name: 'hasChangedPillMode';
  text: string;
  color: string;
};

export type NotePageToastProps = {
  name: 'stoppingPillIntake';
  text: string;
  color: string;
};

const initialState: State = {
  femappUser: undefined,
  femappUserBehaviors: undefined,
  femappCycle: undefined,
  femappUserNotes: undefined,
  selectedDate: undefined,
  openCalendarIconDescriptionBottomSheet: false,
  openCalendarHeader: true,
  openPillDescriptionBottomSheet: false,
  openNoteBottomSheet: false,
  maxPillInsightSheetNumber: undefined,
  calendarPageToast: undefined,
  calendarScrollPosition: undefined,
  userPageToast: undefined,
};

type Payload =
  | FemappUser
  | UserBehavior[]
  | CalendarPageToastProps['name']
  | CycleState
  | Note[]
  | Date
  | boolean
  | number
  | undefined;

type TypeAndPayload = { type: string; payload: Payload };

function reducer(state: State, { type, payload }: TypeAndPayload) {
  switch (type) {
    case 'setfemappUser':
      return { ...state, femappUser: payload };
    case 'setfemappUserBehaviors':
      return { ...state, femappUserBehaviors: payload };
    case 'setfemappCycle':
      return { ...state, femappCycle: payload };
    case 'setFemappUserNotes':
      return { ...state, femappUserNotes: payload };
    case 'setSelectedDate':
      return { ...state, selectedDate: payload };
    case 'handleCalendarHeader':
      return { ...state, openCalendarHeader: payload };
    case 'handleCalendarIconDescriptionBottomSheet':
      return { ...state, openCalendarIconDescriptionBottomSheet: payload };
    case 'handlePillDescriptionBottomSheet':
      return { ...state, openPillDescriptionBottomSheet: payload };
    case 'handleNoteBottomSheet':
      return { ...state, openNoteBottomSheet: payload };
    case 'setMaxPillInsightSheetNumber':
      return { ...state, maxPillInsightSheetNumber: payload };
    case 'setCalendarPageToast':
      return { ...state, calendarPageToast: payload };
    case 'setCalendarScrollPosition':
      return { ...state, calendarScrollPosition: payload };
    case 'setUserPageToast':
      return { ...state, userPageToast: payload };
    default:
      return { ...state };
  }
}

export interface Reducer {
  state: State;
  dispatch: Dispatch;
}

export type Dispatch = (action: Action) => void;

const ReducerContext = React.createContext<Reducer | undefined>(undefined);

export const ActionProvider = ({ children }: { children: React.ReactNode }): JSX.Element => {
  const [state, dispatch] = React.useReducer(reducer as ReducerWithoutAction<any>, initialState); // eslint-disable-line @typescript-eslint/no-explicit-any

  return <ReducerContext.Provider value={{ state, dispatch }}>{children}</ReducerContext.Provider>;
};

export const useActionProvider = (): Reducer => {
  const context = React.useContext(ReducerContext);

  if (context === undefined) {
    throw new Error('useActionProvider cannot use.');
  }
  return context;
};
