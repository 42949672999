import { NextPage } from 'next';
import React from 'react';
import styled from 'styled-components';
import { Button } from 'src/components/atoms/Button';
import { centeredFlexLine } from 'styles/mixin';
import { colorOptions } from 'styles/styles';

const StyledSeverError = styled.div`
  height: 100vh;
  ${centeredFlexLine}
  text-align: center;
  .main-text {
    margin: 1rem 0 0 0;
    font-family: 'Noto_Sans_JP_Bold', sans-serif;
    width: 90%;
  }

  .sub-text {
    margin: 0.5rem 0 1rem 0;
    font-size: 0.8rem;
    width: 80%;
  }
`;

const Custom500: NextPage = () => {
  return (
    <StyledSeverError>
      <img className="warning" src="/warning.png" alt="warning" width="40px" height="40px" />
      <p className="main-text">正常に処理できませんでした</p>
      <p className="sub-text">しばらくしてからもう一度お試しください。</p>
      <Button
        fontSize="0.8rem"
        padding="0.8rem 3rem"
        margin="0.3rem"
        themeColor={colorOptions.BLACK}
        onClick={() => location.reload()}
      >
        再読み込み
      </Button>
    </StyledSeverError>
  );
};

export default Custom500;
