import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /**
   * Represents non-fractional signed whole numeric values. Since the value may
   * exceed the size of a 32-bit integer, it's encoded as a string.
   */
  BigInt: any;
  /** An ISO 8601-encoded date */
  Date: any;
  /** An ISO 8601-encoded datetime */
  DateTime: any;
  /** Represents untyped JSON */
  JSON: any;
  /** Formatted Time object */
  Time: any;
};

/** 予約に紐づく追加問診の回答 */
export type AdditionalReservationInterviewAnswer = MedicalCheckupAdditionalInterviewAnswer;

/** ユーザーに紐づく追加問診の回答 */
export type AdditionalUserInterviewAnswer = CosmeticAdditionalInterviewAnswer;

/** [public]  */
export type Advice = {
  __typename?: 'Advice';
  /** [public]  */
  content: Scalars['String'];
  /** [public]  */
  heading: Scalars['String'];
  /** [public]  */
  viewpoint: FemappAdviceViewpoint;
};

/** [public]  */
export type AgaUser = {
  __typename?: 'AgaUser';
  /** [public]  */
  address?: Maybe<Scalars['String']>;
  /** [public]  */
  email?: Maybe<Scalars['String']>;
  /** [public]  */
  familyName: Scalars['String'];
  /** [public]  */
  hasExamHistory?: Maybe<Scalars['Boolean']>;
  /** [public]  */
  id: Scalars['ID'];
  /** [public]  */
  postcode?: Maybe<Scalars['Int']>;
  /** [public]  */
  selfCheckedMedicalInformation?: Maybe<SelfCheckedMedicalInformation>;
  /** [public]  */
  telNumber?: Maybe<Scalars['String']>;
};

/** [public]  */
export type AgeGroupsClassification = {
  __typename?: 'AgeGroupsClassification';
  /** [public]  */
  count?: Maybe<Scalars['Int']>;
  /** [public]  */
  rangeOfAge?: Maybe<Scalars['Int']>;
};

export enum AlcoholFrequency {
  /** 週2,3日 */
  AlcoholFrequency_2Or_3DaysInAWeek = 'alcohol_frequency_2_or_3_days_in_a_week',
  /** 週5日ほど */
  AlcoholFrequency_5DaysInAWeek = 'alcohol_frequency_5_days_in_a_week',
  /** 毎日 */
  AlcoholFrequencyEveryday = 'alcohol_frequency_everyday',
  /** 飲まない */
  AlcoholFrequencyNone = 'alcohol_frequency_none',
  /** 機会があったら */
  AlcoholFrequencySometimes = 'alcohol_frequency_sometimes',
}

/** [public]  */
export type AmazonPayButtonConfig = {
  __typename?: 'AmazonPayButtonConfig';
  /** [public]  */
  payloadJSON: Scalars['String'];
  /** [public]  */
  publicKeyId: Scalars['String'];
  /** [public]  */
  signature: Scalars['String'];
};

/** Amazon Payページへ遷移する前の注文入力内容 */
export type AmazonPayDraftOrderInput = {
  /** クーポンコード */
  couponCode?: Maybe<Scalars['String']>;
  /** 注文内容 */
  ecOrder: EcOrderInput;
  /** 決済手段 */
  payment?: Maybe<PaymentInput>;
  /** 利用ポイント */
  point: Scalars['Int'];
  /** ポイント利用方法 */
  pointUse: Scalars['String'];
};

/** CreateEcOrderMutationInput型と同等の型 */
export type AmazonPayOrderInput = {
  /** 注文内容 */
  ecOrder: EcOrderInput;
  /** 決済手段 */
  payment: PaymentInput;
  /** 利用ポイント */
  point?: Maybe<Scalars['Int']>;
};

/** [user]  */
export type AntibodyTestResult = {
  __typename?: 'AntibodyTestResult';
  /** [public]  */
  clinic: Clinic;
  /** [public]  */
  createdAt: Scalars['DateTime'];
  /** [public]  */
  examinedOn: Scalars['Date'];
  /** [public]  */
  formatType: Scalars['String'];
  /** [public]  */
  id: Scalars['ID'];
  /** [public]  */
  resultValue?: Maybe<Scalars['Float']>;
  /** [public]  */
  uniqueSrlKey?: Maybe<Scalars['String']>;
  /** [public]  */
  userOrcaPatient: UserOrcaPatient;
};

/** [public]  */
export type Article = {
  __typename?: 'Article';
  /** [public]  */
  articleUrl: Scalars['String'];
  /** [public]  */
  content: Scalars['String'];
  /** [public]  */
  heading: Scalars['String'];
  /** [public]  */
  subtitle: Scalars['String'];
  /** [public]  */
  thumbnailUrl: Scalars['String'];
  /** [public]  */
  title: Scalars['String'];
  /** [public]  */
  urlAddition?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of AssociateAmazonPayCheckoutSessionMutation */
export type AssociateAmazonPayCheckoutSessionMutationInput = {
  checkoutSessionId: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of AssociateAmazonPayCheckoutSessionMutation */
export type AssociateAmazonPayCheckoutSessionMutationPayload = {
  __typename?: 'AssociateAmazonPayCheckoutSessionMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /**
   * エラーコード表
   * U00002: ただいま混み合っております。お時間をおいてから再度お試しください。
   */
  errors: Array<GraphqlError>;
  /** AmazonPayDraftOrderInputのJSONデータ */
  orderInputJson: Scalars['JSON'];
  /** 支払い方法名 */
  paymentDescriptor: Scalars['String'];
};

export enum BeautyCounselingReservationDesiredTreatments {
  /** CO2レーザー */
  Co2Laser = 'CO2_laser',
  /** HIFU */
  Hifu = 'HIFU',
  /** Qスイッチルビーレーザー */
  QSwitchedRubyLaser = 'Q_switched_ruby_laser',
  /** 美容点滴（高濃度ビタミンC、ホワイトプレミアムなど） */
  BeautyDrip = 'beauty_drip',
  /** ボトックス */
  Botox = 'botox',
  /** ダーマペン */
  Dermapen = 'dermapen',
  /** ダイエット */
  Diet = 'diet',
  /** CO2フラクショナルレーザー */
  FractionalLaser = 'fractional_laser',
  /** ジェネシス */
  Genesis = 'genesis',
  /** ヒアルロン酸 */
  HyaluronicAcid = 'hyaluronic_acid',
  /** その他 */
  Other = 'other',
  /** ピーリング */
  Peeling = 'peeling',
  /** フォトフェイシャル */
  PhotoFacial = 'photo_facial',
  /** PICOレーザー */
  PicoFractionalLaser = 'pico_fractional_laser',
  /** 美容注射 */
  ShiratamaInjection = 'shiratama_injection',
  /** サブスクプラン */
  SubscriptionPlan = 'subscription_plan',
  /** 水光注射 */
  WaterLightInjection = 'water_light_injection',
}

export type BeautyCounselingReservationHopeInput = {
  /** 第x希望日 */
  hopeDay: Scalars['Date'];
  /** 第x希望時間 */
  hopeTimeBand: BeautyCounselingReservationTimeBands;
};

export type BeautyCounselingReservationInput = {
  /** お電話を差し上げて良い時間帯 */
  availablePhoneDatetimeComment: Scalars['String'];
  /** ご希望のクリニックID */
  clinicId: Scalars['ID'];
  /** (上記でその他を選択した際の)ご希望の施術内容 */
  desiredTreatmentOthers?: Maybe<Scalars['String']>;
  /** ご希望の施術内容 */
  desiredTreatments: Array<BeautyCounselingReservationDesiredTreatments>;
  /** 初診またはこれまでにも受診歴がある */
  firstVisit?: Maybe<Scalars['String']>;
  /** ご希望の施術日時 */
  hopeReservationSpots: Array<BeautyCounselingReservationHopeInput>;
  /** 電話番号 */
  telNumber?: Maybe<Scalars['String']>;
};

export enum BeautyCounselingReservationTimeBands {
  /** 9-12時 */
  From09to12 = 'from09to12',
  /** 12-15時 */
  From12to15 = 'from12to15',
  /** 15-18時 */
  From15to18 = 'from15to18',
  /** 18-20時 */
  From18to20 = 'from18to20',
}

/** [public]  */
export type BeautyCounselingReservationTreatment = {
  __typename?: 'BeautyCounselingReservationTreatment';
  /** [public]  */
  id: BeautyCounselingReservationDesiredTreatments;
  /** [public]  */
  name: Scalars['String'];
};

/** [public]  */
export type BeautyInsight = {
  __typename?: 'BeautyInsight';
  /** [public]  */
  description: Scalars['String'];
  /** [public]  */
  image: Scalars['String'];
  /** [public]  */
  subtitle: Scalars['String'];
  /** [public]  */
  title: Scalars['String'];
};

/** [public]  */
export type BeautySubscriptionPlan = {
  __typename?: 'BeautySubscriptionPlan';
  /** [public]  */
  code: Scalars['String'];
  /** [public]  */
  id: Scalars['ID'];
  /** [public]  */
  name: Scalars['String'];
  /** [public]  */
  price: Scalars['Int'];
  /** [public]  */
  stripePriceId: Scalars['String'];
};

export enum BreastFeedingStatus {
  BreastFeedingStatusUnconfirmed = 'breast_feeding_status_unconfirmed',
  IsBreastFeeding = 'is_breast_feeding',
  IsNotBreastFeeding = 'is_not_breast_feeding',
}

/** [public]  */
export type BusinessHour = {
  __typename?: 'BusinessHour';
  /** [public]  */
  closedAt: Scalars['DateTime'];
  /** [public]  */
  createdAt: Scalars['DateTime'];
  /** [public]  */
  dayOfWeek: Scalars['Int'];
  /** [public]  */
  id: Scalars['ID'];
  /** [public]  */
  numberOfWeek?: Maybe<Scalars['String']>;
  /** [public]  */
  openedAt: Scalars['DateTime'];
  /** [public]  */
  resumedAt?: Maybe<Scalars['DateTime']>;
  /** [public]  */
  suspendedAt?: Maybe<Scalars['DateTime']>;
  /** [public]  */
  updatedAt: Scalars['DateTime'];
};

/** Autogenerated input type of CancelEcSubscriptionOrderMutation */
export type CancelEcSubscriptionOrderMutationInput = {
  /** 中止の理由。キーはquestion1,answer1...5までによる連想配列 */
  cancelReason: Scalars['JSON'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** 定期配送ID */
  ecSubscriptionOrderId: Scalars['ID'];
};

/** Autogenerated return type of CancelEcSubscriptionOrderMutation */
export type CancelEcSubscriptionOrderMutationPayload = {
  __typename?: 'CancelEcSubscriptionOrderMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** 定期配送 */
  ecSubscriptionOrder?: Maybe<EcSubscriptionOrder>;
  /**
   *   エラーコード表
   *   U00001: リクエスト内容が正しくありません (その他エラーの際の汎用コード)
   *         ec_payment_task.invalid_payment_service_type: 非対応の支払い手段です。
   * ec_payment_task.settlement_error: (決済失敗時につきエラー内容は可変)。
   * ec_payment_task.create_error: 決済に失敗しました。お手数をおかけしますが、もう一度購入のお手続きをお願い致します。（何度も購入に失敗する場合は、恐れ入りますがお問い合わせいただけますようお願い致します。
   * ec_payment_task.cancel_subscription_error: AmazonPayのお支払い停止処理中にエラーが発生しました。しばらく時間を空けて再度お試しください。
   */
  errors: Array<GraphqlError>;
};

/** [public]  */
export type CancelRate = {
  __typename?: 'CancelRate';
  /** [public]  */
  cancelCount: Scalars['Int'];
  /** [public]  */
  cancelRate: Scalars['Float'];
  /** [public]  */
  noshowCount: Scalars['Int'];
  /** [public]  */
  noshowRate: Scalars['Float'];
  /** [public]  */
  reservedOn: Scalars['Date'];
  /** [public]  */
  totalReservationsCount: Scalars['Int'];
};

/** Autogenerated input type of CancelReservationMutation */
export type CancelReservationMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  onlineCancelReason?: Maybe<ReservationCancelReasonInput>;
  realCancelReason?: Maybe<RealReservationCancelReasonInput>;
  reservationId: Scalars['ID'];
};

/** Autogenerated return type of CancelReservationMutation */
export type CancelReservationMutationPayload = {
  __typename?: 'CancelReservationMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /**
   * エラーコード表
   * U00001: リクエスト内容が正しくありません (その他エラーの際の汎用コード)
   * reservation.editable_time_limit_has_passed: 予約時刻前15分をすぎると変更・キャンセルできません。
   * reservation.allow_editing_with_order_status: こちらのご予約は変更できません。
   */
  errors: Array<GraphqlError>;
  reservation?: Maybe<Reservation>;
};

/** [public]  */
export type CapacitiesInSlot = {
  __typename?: 'CapacitiesInSlot';
  /** [public]  */
  actualCapacities: Scalars['Float'];
  /** [public]  */
  capacitiesLimit: Scalars['Float'];
  /** [public]  */
  slotAt: Scalars['DateTime'];
};

/** [public]  */
export type Clinic = Node & {
  __typename?: 'Clinic';
  /** [public]  */
  accessGuide?: Maybe<Scalars['String']>;
  /** [public]  */
  accessGuideShort?: Maybe<Scalars['String']>;
  /** [public]  */
  businessHours: Array<BusinessHour>;
  /** [public]  */
  clinicCode: Scalars['String'];
  /** [public]  */
  clinicImagePath?: Maybe<Scalars['String']>;
  /** [public]  */
  clinicKey: Scalars['String'];
  /** [public]  */
  clinicSignature: ClinicSignature;
  /** [public]  */
  closedAt: Scalars['DateTime'];
  /** [public]  */
  createdAt: Scalars['DateTime'];
  /** [public]  */
  departmentContents: Array<DepartmentContent>;
  /** [public] 診療科 */
  departments?: Maybe<Array<Department>>;
  /** [public]  */
  feedbackUrl?: Maybe<Scalars['String']>;
  /** [public]  */
  googlemapsEmbedUrl?: Maybe<Scalars['String']>;
  /** [public]  */
  googlemapsUrl?: Maybe<Scalars['String']>;
  /** [public]  */
  hasBeauty: Scalars['Boolean'];
  /** [public]  */
  id: Scalars['ID'];
  /** [public]  */
  images?: Maybe<Array<Scalars['String']>>;
  /** [public]  */
  isIntegrated: Scalars['Boolean'];
  /** [public]  */
  logoUrl?: Maybe<Scalars['String']>;
  /** [public]  */
  name: Scalars['String'];
  /** [public]  */
  nameJa: Scalars['String'];
  /** [public]  */
  nameShortJa?: Maybe<Scalars['String']>;
  /** [public] クリニックお知らせを取得 */
  news: Array<ClinicNews>;
  /** [public]  */
  openedAt: Scalars['DateTime'];
  /** [public]  */
  platformCaution?: Maybe<Scalars['String']>;
  /** [public]  */
  platformHeadline?: Maybe<ClinicHeadline>;
  /** [public]  */
  platformImageUrls: Array<Scalars['String']>;
  /** [public]  */
  platformThumbnailUrl?: Maybe<Scalars['String']>;
  /** [public]  */
  prTextsFull: Scalars['String'];
  /** [public]  */
  preIntegratedClinics: Array<Clinic>;
  /** [public]  */
  simpleNameJa?: Maybe<Scalars['String']>;
  /** [public]  */
  updatedAt: Scalars['DateTime'];
  /** [public] 対面診療予約用のクリニック予約画面のURL */
  webviewUrl: Scalars['String'];
  /** [public]  */
  weekdayReservableTo: Scalars['DateTime'];
};

/** Autogenerated input type of ClinicCreateReservationMutation */
export type ClinicCreateReservationMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  departmentId: Scalars['ID'];
  inquiryResultId?: Maybe<Scalars['ID']>;
  purpose?: Maybe<Scalars['String']>;
  reservedAt: Scalars['DateTime'];
  userUuid: Scalars['String'];
};

/** Autogenerated return type of ClinicCreateReservationMutation */
export type ClinicCreateReservationMutationPayload = {
  __typename?: 'ClinicCreateReservationMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /**
   * エラーコード表
   * U00001: リクエスト内容が正しくありません (その他エラーの際の汎用コード)
   * reservation.exceeded_the_slot_capacity: 指定の予約時間は既に予約がいっぱいです。
   */
  errors: Array<GraphqlError>;
  reservation?: Maybe<Reservation>;
};

/** Autogenerated input type of ClinicCreateUserMutation */
export type ClinicCreateUserMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  password: Scalars['String'];
  passwordConfirmation: Scalars['String'];
  userAttributes: UserInformationInput;
};

/** Autogenerated return type of ClinicCreateUserMutation */
export type ClinicCreateUserMutationPayload = {
  __typename?: 'ClinicCreateUserMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /**
   * エラーコード表
   * U00001: リクエスト内容が正しくありません (その他エラーの際の汎用コード)
   * U00010: 指定したメールアドレスはすでに登録されています
   * U00011: 携帯会社のメールアドレスはご利用できません
   * user.family_name_kana.not_katakana: 受診者姓（全角カタカナ）は全角カタカナで入力してください
   * user.given_name_kana.not_katakana: 受診者名（全角カタカナ）は全角カタカナで入力してください
   * user.birthday_cannot_be_in_the_future: 生年月日が未来日となっています
   */
  errors: Array<GraphqlError>;
  user?: Maybe<User>;
};

/** [public]  */
export type ClinicHeadline = {
  __typename?: 'ClinicHeadline';
  /** [public]  */
  main: Scalars['String'];
  /** [public]  */
  sub: Scalars['String'];
};

/** [public]  */
export type ClinicMenuDetail = {
  __typename?: 'ClinicMenuDetail';
  /** [public]  */
  contents: Array<ClinicMenuDetailContent>;
  /** [public]  */
  description: Scalars['String'];
  /** [public]  */
  heading: Scalars['String'];
  /** [public]  */
  id: Scalars['ID'];
};

/** [public]  */
export type ClinicMenuDetailContent = {
  __typename?: 'ClinicMenuDetailContent';
  /** [public]  */
  listing: Array<Scalars['String']>;
  /** [public]  */
  title: Scalars['String'];
};

/** [public]  */
export type ClinicNews = {
  __typename?: 'ClinicNews';
  /** [public]  */
  date: Scalars['Date'];
  /** [public]  */
  id: Scalars['ID'];
  /** [public]  */
  title: Scalars['String'];
  /** [public]  */
  webviewUrl: Scalars['String'];
};

/** [public]  */
export type ClinicSignature = {
  __typename?: 'ClinicSignature';
  /** [public]  */
  addressBuilding?: Maybe<Scalars['String']>;
  /** [public]  */
  addressPostcode?: Maybe<Scalars['String']>;
  /** [public]  */
  addressPrefectureId: Scalars['Int'];
  /** [public]  */
  addressPrefectureName: Scalars['String'];
  /** [public]  */
  addressStreet?: Maybe<Scalars['String']>;
  /** [public]  */
  createdAt: Scalars['DateTime'];
  /** [public]  */
  email: Scalars['String'];
  /** [public]  */
  id: Scalars['ID'];
  /** [public]  */
  nameJa: Scalars['String'];
  /** [public]  */
  telNumber?: Maybe<Scalars['String']>;
  /** [public]  */
  updatedAt: Scalars['DateTime'];
  /** [public]  */
  website?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of ClinicUpdateInterviewAnswerMutation */
export type ClinicUpdateInterviewAnswerMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  interviewType: Scalars['String'];
  interviewVersion: Scalars['Int'];
  reservationId: Scalars['ID'];
  result: Scalars['String'];
  userUuid: Scalars['String'];
};

/** Autogenerated return type of ClinicUpdateInterviewAnswerMutation */
export type ClinicUpdateInterviewAnswerMutationPayload = {
  __typename?: 'ClinicUpdateInterviewAnswerMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /**
   * エラーコード表
   * U00001: リクエスト内容が正しくありません (その他エラーの際の汎用コード)
   * TODO
   */
  errors: Array<GraphqlError>;
  interviewAnswer?: Maybe<InterviewAnswer>;
};

/** Autogenerated input type of ClinicUpdateReservationMutation */
export type ClinicUpdateReservationMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['ID']>;
  departmentId?: Maybe<Scalars['ID']>;
  inquiryResultId?: Maybe<Scalars['ID']>;
  purpose?: Maybe<Scalars['String']>;
  reservationId: Scalars['ID'];
  reservedAt?: Maybe<Scalars['DateTime']>;
  userAttributes?: Maybe<UserInformationInput>;
  userUuid: Scalars['String'];
};

/** Autogenerated return type of ClinicUpdateReservationMutation */
export type ClinicUpdateReservationMutationPayload = {
  __typename?: 'ClinicUpdateReservationMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /**
   * エラーコード表
   * U00001: リクエスト内容が正しくありません (その他エラーの際の汎用コード)
   * reservation.exceeded_the_slot_capacity: 指定の予約時間は既に予約がいっぱいです。
   */
  errors: Array<GraphqlError>;
  reservation?: Maybe<Reservation>;
};

/** Autogenerated input type of ClinicUpdateSelfCheckedMedicalInformationMutation */
export type ClinicUpdateSelfCheckedMedicalInformationMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  selfCheckedMedicalInformationAttributes: SelfCheckedMedicalInformationInput;
  userUuid: Scalars['String'];
};

/** Autogenerated return type of ClinicUpdateSelfCheckedMedicalInformationMutation */
export type ClinicUpdateSelfCheckedMedicalInformationMutationPayload = {
  __typename?: 'ClinicUpdateSelfCheckedMedicalInformationMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /**
   * エラーコード表
   * U00001: リクエスト内容が正しくありません (その他エラーの際の汎用コード)
   * TODO
   */
  errors: Array<GraphqlError>;
  user?: Maybe<User>;
};

/** Autogenerated input type of ClinicUpdateUserMutation */
export type ClinicUpdateUserMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  userAttributes: UserInformationInput;
  userUuid: Scalars['String'];
};

/** Autogenerated return type of ClinicUpdateUserMutation */
export type ClinicUpdateUserMutationPayload = {
  __typename?: 'ClinicUpdateUserMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /**
   * エラーコード表
   * U00001: リクエスト内容が正しくありません (その他エラーの際の汎用コード)
   * user.family_name_kana.not_katakana: 受診者姓（全角カタカナ）は全角カタカナで入力してください
   * user.given_name_kana.not_katakana: 受診者名（全角カタカナ）は全角カタカナで入力してください
   * user.birthday_cannot_be_in_the_future: 生年月日が未来日となっています
   */
  errors: Array<GraphqlError>;
  user?: Maybe<User>;
};

/** Autogenerated input type of ConfirmEmailMutation */
export type ConfirmEmailMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  confirmationToken: Scalars['String'];
};

/** Autogenerated return type of ConfirmEmailMutation */
export type ConfirmEmailMutationPayload = {
  __typename?: 'ConfirmEmailMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /**
   * エラーコード表
   * I00001: システムエラーです。お時間をおいてから再度お試しください。
   * I00010: リクエストが許可されていません
   * U00001: リクエスト内容が正しくありません
   * U00022: 該当リソースにはアクセスできません。
   */
  errors: Array<GraphqlError>;
  success: Scalars['Boolean'];
};

/** [public]  */
export type ConfirmationEcPaymentAmount = {
  __typename?: 'ConfirmationEcPaymentAmount';
  /** [public] 割引金額 */
  discountAmount: Scalars['Int'];
  /**
   * [public]           エラーコード表
   *           U00001: リクエスト内容が正しくありません
   */
  errors: Array<GraphqlError>;
  /** [public] 予約料・診察料 */
  medicalFee: Scalars['Int'];
  /** [public] 支払い手段ごとの手数料 */
  paymentFee: Scalars['Int'];
  /** [public] ポイント割引金額 */
  pointAmount: Scalars['Int'];
  /** [public] 商品の合計金額 */
  purchaseAmount: Scalars['Int'];
  /** [public] 送料 */
  shippingFee: Scalars['Int'];
  /** [public] 合計金額 */
  total: Scalars['Int'];
  /** [public] 獲得ポイント合計値 */
  totalPointValue: Scalars['Int'];
  /** [public] 利用可能ポイント */
  userPointSummary: UserPointSummary;
  /**
   * [public]           エラーコード表(エラーでも金額がレスポンスされる)
   *               ec_order_create_validator.valid_reservation.missing_reservation: ご指定の予約が存在しません。
   *     ec_order_create_validator.valid_reservation.invalid_user: 予約時と同じアカウントでログインしてください。
   *     ec_order_create_validator.valid_reservation.already_created: この予約での注文はすでに終わっています。
   *     ec_order_create_validator.valid_reservation.invalid_reservation: 決済対象の予約ではありません。
   *     ec_order_create_validator.valid_products.only_on_sale: 取扱いが終了したお薬が含まれています。
   *     ec_order_create_validator.valid_products.multiple_categories: 組み合わせ不可能なお薬が選択されています。
   *     ec_order_create_validator.can_additional_shipping.invalid_category: 追加発送不可のお薬が含まれています。
   *     ec_order_create_validator.can_additional_shipping.no_sent_status_ec_orders_exist: 過去にオンライン診療した履歴が見つかりませんでした。新しくご予約いただくか、お問い合わせください
   *     ec_order_create_validator.can_additional_shipping.waiting_an_hour_is_required:
   * 直近1時間以内に同じ診療科の注文があります。さらに購入されたい場合、お手数をおかけしますが1時間以上時間を空けてから再度購入してください。
   *     ec_order_create_validator.valid_user.missing_tel_number: 電話番号が登録されていません。
   *     ec_order_create_validator.valid_shipping_address.shipping_address_is_required: 配送先を入力してください。(郵便番号はハイフン無しで入力してください。）
   *     ec_order_create_validator.valid_shipping_address.must_select_from_same_as_destination: 同上指定でのみ注文可能な商品が含まれています。
   *     ec_order_create_validator.valid_products.invalid_quantity_range: 注文可能な最低個数未満か、最大個数を超えています。
   *     ec_order_create_validator.valid_products.only_products_without_first_shipping:
   * 初回発送なしの商品のみ選択されています。通常の定期商品をご選択いただくようにお願いいたします。
   *     ec_order_create_validator.valid_discount_association: 企業優待とクーポンの同時使用はできません。
   *     ec_order_create_validator.validate_only_tretinoin: セラピューティック用トレチノインのみの購入をご希望の場合は代金引換および後払いをご利用いただけないため、クレジットカードでご購入いただけますようお願い致します。
   *     ec_order_create_validator.validate_only_english_medical_certificate:
   * 各種英文診断書のみの購入をご希望の場合は代金引換および後払いをご利用いただけないため、クレジットカードでご購入いただけますようお願い致します。
   *     ec_order_create_validator.validate_must_select_product.prohibit_medical_fee_only_with_discount: 診察料無料のクーポンは、商品選択時のみ利用可能です。処方された商品をご選択のうえ、決済を行なってください。
   *     ec_order_create_validator.validate_must_select_product.with_medical_fee:
   * お薬を選択してください。診察料のみ決済の場合は、クレジットカードでご購入いただけますようお願い致します。
   *     ec_order_create_validator.validate_must_select_product.without_medical_fee: お薬を選択してください。
   *     ec_order_create_validator.validate_only_stripe_for_corporate_benefit: 企業優待をご使用の場合は、クレジットカード決済を行うようお願いいたします。
   *
   *               ec_order_useable_coupon_validator.valid_ec_coupon.expired: 入力されたクーポンは期限切れです
   *     ec_order_useable_coupon_validator.valid_ec_coupon.already_used: このクーポンは使用済みです
   *     ec_order_useable_coupon_validator.valid_ec_coupon.not_target_category: このクーポンはこちらの診療科では利用できません
   *     ec_order_useable_coupon_validator.valid_ec_coupon.not_target_product: 割引対象の商品が選択されていません。
   *     ec_order_useable_coupon_validator.valid_ec_coupon.unusable: このクーポンはご利用いただけません。
   *     ec_order_useable_coupon_validator.valid_ec_coupon_department: 保険診療では企業優待のみご利用可能です。
   *     ec_order_useable_coupon_validator.valid_ec_corporate_benefit.expired: 入力されたクーポンは期限切れです
   *
   *                 ec_payment_task.invalid_payment_service_type: 非対応の支払い手段です。
   *       ec_payment_task.settlement_error: (決済失敗時につきエラー内容は可変)。
   *       ec_payment_task.create_error: 決済に失敗しました。お手数をおかけしますが、もう一度購入のお手続きをお願い致します。（何度も購入に失敗する場合は、恐れ入りますがお問い合わせいただけますようお願い致します。
   *       ec_payment_task.cancel_subscription_error: AmazonPayのお支払い停止処理中にエラーが発生しました。しばらく時間を空けて再度お試しください。
   */
  validationErrors: Array<GraphqlError>;
};

/** [public]  */
export type ConfirmationExamPaymentAmount = {
  __typename?: 'ConfirmationExamPaymentAmount';
  /** [public] 割引金額 */
  discountAmount: Scalars['Int'];
  /**
   * [public]           エラーコード表
   *           U00001: リクエスト内容が正しくありません
   */
  errors: Array<GraphqlError>;
  /** [public] 支払い手段ごとの手数料 */
  paymentFee: Scalars['Int'];
  /** [public] 商品の合計金額 */
  purchaseAmount: Scalars['Int'];
  /** [public] 送料 */
  shippingFee: Scalars['Int'];
  /** [public] 合計金額 */
  total: Scalars['Int'];
  /**
   * [public]           エラーコード表(エラーでも金額がレスポンスされる)
   *                 exam_payment_task.settlement_error: (決済失敗時につきエラー内容は可変)。
   *       exam_payment_task.create_error: 決済に失敗しました。お手数をおかけしますが、もう一度購入のお手続きをお願い致します。（何度も購入に失敗する場合は、恐れ入りますがお問い合わせいただけますようお願い致します。
   *
   *               exam_order_useable_coupon_validator.valid_exam_coupon.expired: 入力されたクーポンは期限切れです
   *     exam_order_useable_coupon_validator.valid_exam_coupon.already_used: このクーポンは使用済みです
   *     exam_order_useable_coupon_validator.valid_exam_coupon.not_target_category: このクーポンはこちらの検査では利用できません
   *     exam_order_useable_coupon_validator.valid_exam_coupon.not_target_product: 割引対象の商品が選択されていません。
   *     exam_order_useable_coupon_validator.valid_exam_coupon.unusable: このクーポンはご利用いただけません。
   *
   *               exam_order_create_validator.valid_products.only_on_sale: 取扱いが終了した商品が含まれています。
   *     exam_order_create_validator.valid_products.mismatch_gender: 選択した商品は性別が異なるため注文できません。検査内容をご確認の上、商品をお選びください。
   *     exam_order_create_validator.can_additional_shipping.waiting_an_hour_is_required: 直近1時間以内に同じ商品の注文があります。さらに購入されたい場合、お手数をおかけしますが1時間以上時間を空けてから再度購入してください。
   *     exam_order_create_validator.valid_user.missing_tel_number: 電話番号を入力してください。
   *     exam_order_create_validator.valid_shipping_address.shipping_address_is_required: 配送先を入力してください。(郵便番号はハイフン無しで入力してください。）
   *     exam_order_create_validator.create_exam_order_mutation.must_select_product_error: 商品を選択してください。
   */
  validationErrors: Array<GraphqlError>;
};

/** [public]  */
export type CosmeticAdditionalInterviewAnswer = Node & {
  __typename?: 'CosmeticAdditionalInterviewAnswer';
  /** [public] アレルギー */
  allergies: Array<CosmeticAllergies>;
  /** [public] 授乳中 */
  breastFeeding: Scalars['Boolean'];
  /** [public]  */
  createdAt: Scalars['Date'];
  /** [public] 使用中の外用薬 */
  externalMedicines: Array<CosmeticExternalMedicines>;
  /** [public] 日焼けの既往・予定 */
  hasHistoryOrPlanOfSunburn: Scalars['Boolean'];
  /** [public] 皮下における人工物（金、プロテーゼ） */
  hasSubcutaneousArtificialMaterial: Scalars['Boolean'];
  /** [public] 重要なイベント */
  hasUpcomingImportantEvent: Scalars['Boolean'];
  /** [public]  */
  id: Scalars['ID'];
  /** [public] 服用中の内服薬 */
  internalMedicines: Array<CosmeticInternalMedicines>;
  /** [public] 既往歴 */
  medicalHistories: Array<CosmeticMedicalHistories>;
  /** [public] 妊娠中 */
  pregnantStatus: CosmeticPregnantStatus;
  /** [public] 過去に受けた美容施術 */
  recentCosmeticTreatments?: Maybe<Array<CosmeticRecentCosmeticTreatments>>;
  /** [public] 過去に受けた美容施術（その他） */
  recentCosmeticTreatmentsText?: Maybe<Scalars['String']>;
  /** [public]  */
  updatedAt: Scalars['Date'];
};

export type CosmeticAdditionalInterviewAnswerInput = {
  /** アレルギー */
  allergies: Array<CosmeticAllergies>;
  /** 授乳中 */
  breastFeeding: Scalars['Boolean'];
  /** 使用中の外用薬 */
  externalMedicines: Array<CosmeticExternalMedicines>;
  /** 日焼けの既往・予定 */
  hasHistoryOrPlanOfSunburn: Scalars['Boolean'];
  /** 皮下における人工物（金、プロテーゼ） */
  hasSubcutaneousArtificialMaterial: Scalars['Boolean'];
  /** 重要なイベント */
  hasUpcomingImportantEvent: Scalars['Boolean'];
  /** 服用中の内服薬 */
  internalMedicines: Array<CosmeticInternalMedicines>;
  /** 既往歴 */
  medicalHistories: Array<CosmeticMedicalHistories>;
  /** 妊娠中 */
  pregnantStatus: CosmeticPregnantStatus;
  /** 過去に受けた美容施術 */
  recentCosmeticTreatments?: Maybe<Array<CosmeticRecentCosmeticTreatments>>;
  /** 過去に受けた美容施術（その他） */
  recentCosmeticTreatmentsText?: Maybe<Scalars['String']>;
};

export enum CosmeticAllergies {
  /** 麻酔薬 */
  AnesthesiaAllergy = 'anesthesia_allergy',
  /** 金属アレルギー */
  MetalAllergy = 'metal_allergy',
  /** 該当なし */
  Nothing = 'nothing',
}

export enum CosmeticExternalMedicines {
  /** ニキビ治療薬 */
  AcneCream = 'acne_cream',
  /** 該当なし */
  Nothing = 'nothing',
  /** ステロイド */
  Steroid = 'steroid',
}

export enum CosmeticInternalMedicines {
  /** ニキビ薬 */
  AcneMedicine = 'acne_medicine',
  /** 血液凝固阻止薬 */
  BloodCoagulationMedicine = 'blood_coagulation_medicine',
  /** 美容内服薬 */
  CosmeticInternalMedicine = 'cosmetic_internal_medicine',
  /** 該当なし */
  Nothing = 'nothing',
  /** ピル */
  Pill = 'pill',
}

export enum CosmeticMedicalHistories {
  /** アトピー性皮膚炎 */
  AtopicDermatitis = 'atopic_dermatitis',
  /** てんかん */
  Epilepsy = 'epilepsy',
  /** 該当なし */
  Nothing = 'nothing',
  /** 光線過敏症 */
  Photosensitivity = 'photosensitivity',
}

export enum CosmeticPregnantStatus {
  /** いいえ */
  IsNotPregnant = 'is_not_pregnant',
  /** 可能性がある */
  IsPotentiallyPregnant = 'is_potentially_pregnant',
  /** はい */
  IsPregnant = 'is_pregnant',
}

export enum CosmeticRecentCosmeticTreatments {
  /** ボトックス */
  Botox = 'botox',
  /** CO2フラクショナル */
  Co2Fractional = 'co2_fractional',
  /** 美容手術 */
  CosmeticSurgery = 'cosmetic_surgery',
  /** ダーマペン */
  Dermapen = 'dermapen',
  /** 脱毛 */
  Epilation = 'epilation',
  /** ジェネシス */
  Genesis = 'genesis',
  /** HIFU */
  Hifu = 'hifu',
  /** ヒアルロン酸注射 */
  HyaluronicAcidInjection = 'hyaluronic_acid_injection',
  /** ホクロ・イボ取り */
  MoleRemoval = 'mole_removal',
  /** ニードルRF */
  NeedleRf = 'needle_rf',
  /** その他 */
  Others = 'others',
  /** ピーリング */
  Peeling = 'peeling',
  /** フォトフェイシャル */
  Photofacial = 'photofacial',
  /** ピコフラクショナル */
  PicoFractional = 'pico_fractional',
  /** ピコスポット */
  PicoSpot = 'pico_spot',
  /** ピコトーニング */
  PicoToning = 'pico_toning',
  /** ルビーレーザー */
  RubyLaser = 'ruby_laser',
  /** 水光注射 */
  WaterLightInjection = 'water_light_injection',
}

export enum CouponMail {
  /** AGAのりかえ割 */
  AgaChangeClinic = 'aga_change_clinic',
  /** AGA予防割 */
  AgaHairKeepPlan = 'aga_hair_keep_plan',
  /** FAGAトライアルプラン:ライト割 */
  FagaTrialPlanLight = 'faga_trial_plan_light',
  /** 低用量ピルのりかえ割 */
  PillChangeClinic = 'pill_change_clinic',
  /** 低用量ピル復活割 */
  PillRevival = 'pill_revival',
  /** 低用量ピル学割 */
  StudentDiscount = 'student_discount',
}

/** Autogenerated input type of CreateAmazonPayPaymentMethodMutation */
export type CreateAmazonPayPaymentMethodMutationInput = {
  /** AmazonPayからユーザーの情報を取得するためのアクセストークン */
  accessToken: Scalars['String'];
  /** AmazonPayのauto payをする際に必要なid */
  billingAgreementId: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of CreateAmazonPayPaymentMethodMutation */
export type CreateAmazonPayPaymentMethodMutationPayload = {
  __typename?: 'CreateAmazonPayPaymentMethodMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /**
   * エラーコード表
   * I00001: システムエラーです。お時間をおいてから再度お試しください。
   * U00001: リクエスト内容が正しくありません (その他エラーの際の汎用コード)
   * U00002: ただいま混み合っております。お時間をおいてから再度お試しください。（外部サービスの通信エラーでリトライが必要な際の汎用コード）
   */
  errors: Array<GraphqlError>;
  paymentMethod?: Maybe<PaymentMethod>;
  user?: Maybe<User>;
};

/** Autogenerated input type of CreateBeautyCounselingReservationMutation */
export type CreateBeautyCounselingReservationMutationInput = {
  beautyCounselingReservation: BeautyCounselingReservationInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of CreateBeautyCounselingReservationMutation */
export type CreateBeautyCounselingReservationMutationPayload = {
  __typename?: 'CreateBeautyCounselingReservationMutationPayload';
  /** 予約ID */
  beautyCounselingReservationId?: Maybe<Scalars['String']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /**
   * エラーコード表
   * U00001: リクエスト内容が正しくありません
   * U00002: ただいま混み合っております。お時間をおいてから再度お試しください。
   * beauty_counseling_reservation.no_duplicate_reservation: 1度に複数の予約申し込みはできません。しばらく時間を空けてお試しください
   */
  errors: Array<GraphqlError>;
};

/** Autogenerated input type of CreateBeautySubscriptionMutation */
export type CreateBeautySubscriptionMutationInput = {
  beautySubscriptionPlanId: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  clinicId: Scalars['ID'];
  /** 決済手段 */
  payment: PaymentInput;
};

/** Autogenerated return type of CreateBeautySubscriptionMutation */
export type CreateBeautySubscriptionMutationPayload = {
  __typename?: 'CreateBeautySubscriptionMutationPayload';
  /** 決済状況 */
  beautySubscriptionUserStatus: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /**
   *   エラーコード表
   *   U00001: リクエスト内容が正しくありません
   *   U00002: ただいま混み合っております。お時間をおいてから再度お試しください。
   *         beauty_subscription_payment_task.settlement_error: (決済失敗時につきエラー内容は可変)。
   * beauty_subscription_payment_task.create_error: 決済に失敗しました。お手数をおかけしますが、もう一度購入のお手続きをお願い致します。（何度も購入に失敗する場合は、恐れ入りますがお問い合わせいただけますようお願い致します。
   * beauty_subscription_payment_task.already_subscribed_error: すでに登録済みです。
   */
  errors: Array<GraphqlError>;
};

/** Autogenerated input type of CreateCycleMutation */
export type CreateCycleMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  cycleAttributes: FemappCycleInput;
};

/** Autogenerated return type of CreateCycleMutation */
export type CreateCycleMutationPayload = {
  __typename?: 'CreateCycleMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  cycleState?: Maybe<CycleState>;
  /**
   * エラーコード表
   * I00001: システムエラーです。お時間をおいてから再度お試しください。
   * I00010: リクエストが許可されていません
   * U00001: リクエスト内容が正しくありません
   * U00001: 生理開始日が同じデータがあります。別日を選択してください。
   * U00001: 生理開始日は、生年月日以降の日付を選択してください。
   * U00001: 生理開始日は、登録済の生理期間内には設定できません。登録済の生理情報を削除するか、別日を選択してください。
   * U00001: 生理終了日は、生理開始日以降の日付を選択してください。
   * U00001: 生理終了日は、登録済の生理期間内には設定できません。登録済の生理情報を削除するか、別日を選択してください。
   * U00001: 登録しようとした生理期間の間に、登録済の生理日があります。登録済の生理情報を削除するか、別日を選択してください。
   * U00020: IDトークンの有効期限が切れました。再度トークンを取得してください。
   * U00021: ユーザー情報が不足しています。ユーザー情報を登録してから再度お試しください。
   */
  errors: Array<GraphqlError>;
};

/** Autogenerated input type of CreateDiagnosticAnswersMutation */
export type CreateDiagnosticAnswersMutationInput = {
  answers: Array<DiagnosticAnswerInput>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  reservationId: Scalars['ID'];
};

/** Autogenerated return type of CreateDiagnosticAnswersMutation */
export type CreateDiagnosticAnswersMutationPayload = {
  __typename?: 'CreateDiagnosticAnswersMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /**
   * エラーコード表
   * U00001: リクエスト内容が正しくありません (その他エラーの際の汎用コード)
   * diagnostic_answer.resrvation_not_found: 予約が見つかりません
   */
  errors: Array<GraphqlError>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of CreateEcOrderMutation */
export type CreateEcOrderMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** 注文内容 */
  ecOrder: EcOrderInput;
  /** 決済手段 */
  payment: PaymentInput;
  /** 利用ポイント */
  point?: Maybe<Scalars['Int']>;
};

/** Autogenerated return type of CreateEcOrderMutation */
export type CreateEcOrderMutationPayload = {
  __typename?: 'CreateEcOrderMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** 注文結果 */
  ecOrder?: Maybe<EcOrder>;
  /**
   *         エラーコード表
   *         U00001: リクエスト内容が正しくありません
   *         U00002: ただいま混み合っております。お時間をおいてから再度お試しください。
   *         create_ec_order_mutation.must_select_product_error: お薬を選択してください。
   *         create_ec_order_mutation.only_tretinoin_error: セラピューティック用トレチノインのみの購入をご希望の場合は代金引換および後払いをご利用いただけないため、クレジットカードでご購入いただけますようお願い致します。
   *             ec_order_create_validator.valid_reservation.missing_reservation: ご指定の予約が存在しません。
   *     ec_order_create_validator.valid_reservation.invalid_user: 予約時と同じアカウントでログインしてください。
   *     ec_order_create_validator.valid_reservation.already_created: この予約での注文はすでに終わっています。
   *     ec_order_create_validator.valid_reservation.invalid_reservation: 決済対象の予約ではありません。
   *     ec_order_create_validator.valid_products.only_on_sale: 取扱いが終了したお薬が含まれています。
   *     ec_order_create_validator.valid_products.multiple_categories: 組み合わせ不可能なお薬が選択されています。
   *     ec_order_create_validator.can_additional_shipping.invalid_category: 追加発送不可のお薬が含まれています。
   *     ec_order_create_validator.can_additional_shipping.no_sent_status_ec_orders_exist: 過去にオンライン診療した履歴が見つかりませんでした。新しくご予約いただくか、お問い合わせください
   *     ec_order_create_validator.can_additional_shipping.waiting_an_hour_is_required:
   * 直近1時間以内に同じ診療科の注文があります。さらに購入されたい場合、お手数をおかけしますが1時間以上時間を空けてから再度購入してください。
   *     ec_order_create_validator.valid_user.missing_tel_number: 電話番号が登録されていません。
   *     ec_order_create_validator.valid_shipping_address.shipping_address_is_required: 配送先を入力してください。(郵便番号はハイフン無しで入力してください。）
   *     ec_order_create_validator.valid_shipping_address.must_select_from_same_as_destination: 同上指定でのみ注文可能な商品が含まれています。
   *     ec_order_create_validator.valid_products.invalid_quantity_range: 注文可能な最低個数未満か、最大個数を超えています。
   *     ec_order_create_validator.valid_products.only_products_without_first_shipping:
   * 初回発送なしの商品のみ選択されています。通常の定期商品をご選択いただくようにお願いいたします。
   *     ec_order_create_validator.valid_discount_association: 企業優待とクーポンの同時使用はできません。
   *     ec_order_create_validator.validate_only_tretinoin: セラピューティック用トレチノインのみの購入をご希望の場合は代金引換および後払いをご利用いただけないため、クレジットカードでご購入いただけますようお願い致します。
   *     ec_order_create_validator.validate_only_english_medical_certificate:
   * 各種英文診断書のみの購入をご希望の場合は代金引換および後払いをご利用いただけないため、クレジットカードでご購入いただけますようお願い致します。
   *     ec_order_create_validator.validate_must_select_product.prohibit_medical_fee_only_with_discount: 診察料無料のクーポンは、商品選択時のみ利用可能です。処方された商品をご選択のうえ、決済を行なってください。
   *     ec_order_create_validator.validate_must_select_product.with_medical_fee:
   * お薬を選択してください。診察料のみ決済の場合は、クレジットカードでご購入いただけますようお願い致します。
   *     ec_order_create_validator.validate_must_select_product.without_medical_fee: お薬を選択してください。
   *     ec_order_create_validator.validate_only_stripe_for_corporate_benefit: 企業優待をご使用の場合は、クレジットカード決済を行うようお願いいたします。
   *
   *             ec_order_useable_coupon_validator.valid_ec_coupon.expired: 入力されたクーポンは期限切れです
   *     ec_order_useable_coupon_validator.valid_ec_coupon.already_used: このクーポンは使用済みです
   *     ec_order_useable_coupon_validator.valid_ec_coupon.not_target_category: このクーポンはこちらの診療科では利用できません
   *     ec_order_useable_coupon_validator.valid_ec_coupon.not_target_product: 割引対象の商品が選択されていません。
   *     ec_order_useable_coupon_validator.valid_ec_coupon.unusable: このクーポンはご利用いただけません。
   *     ec_order_useable_coupon_validator.valid_ec_coupon_department: 保険診療では企業優待のみご利用可能です。
   *     ec_order_useable_coupon_validator.valid_ec_corporate_benefit.expired: 入力されたクーポンは期限切れです
   *
   *               ec_payment_task.invalid_payment_service_type: 非対応の支払い手段です。
   *       ec_payment_task.settlement_error: (決済失敗時につきエラー内容は可変)。
   *       ec_payment_task.create_error: 決済に失敗しました。お手数をおかけしますが、もう一度購入のお手続きをお願い致します。（何度も購入に失敗する場合は、恐れ入りますがお問い合わせいただけますようお願い致します。
   *       ec_payment_task.cancel_subscription_error: AmazonPayのお支払い停止処理中にエラーが発生しました。しばらく時間を空けて再度お試しください。
   *
   *         ec_orders.create.examination_not_completed:
   * 診察が完了してから決済を行ってください。（診察が完了しているにもかかわらず決済ができない場合は、お手数おかけしますがサポートにお問い合わせください）
   */
  errors: Array<GraphqlError>;
};

/** Autogenerated input type of CreateExamOrderMutation */
export type CreateExamOrderMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** data: [{question: string, answer: string}] の型で表現される */
  examInterviewResultJson: Scalars['JSON'];
  /** 注文内容 */
  examOrder: ExamOrderInput;
  /** 決済手段 */
  payment: PaymentInput;
};

/** Autogenerated return type of CreateExamOrderMutation */
export type CreateExamOrderMutationPayload = {
  __typename?: 'CreateExamOrderMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /**
   *     エラーコード表
   *     U00001: リクエスト内容が正しくありません
   *     U00002: ただいま混み合っております。お時間をおいてから再度お試しください。
   *           exam_payment_task.settlement_error: (決済失敗時につきエラー内容は可変)。
   *   exam_payment_task.create_error: 決済に失敗しました。お手数をおかけしますが、もう一度購入のお手続きをお願い致します。（何度も購入に失敗する場合は、恐れ入りますがお問い合わせいただけますようお願い致します。
   *
   *         exam_order_useable_coupon_validator.valid_exam_coupon.expired: 入力されたクーポンは期限切れです
   * exam_order_useable_coupon_validator.valid_exam_coupon.already_used: このクーポンは使用済みです
   * exam_order_useable_coupon_validator.valid_exam_coupon.not_target_category: このクーポンはこちらの検査では利用できません
   * exam_order_useable_coupon_validator.valid_exam_coupon.not_target_product: 割引対象の商品が選択されていません。
   * exam_order_useable_coupon_validator.valid_exam_coupon.unusable: このクーポンはご利用いただけません。
   *
   *         exam_order_create_validator.valid_products.only_on_sale: 取扱いが終了した商品が含まれています。
   * exam_order_create_validator.valid_products.mismatch_gender: 選択した商品は性別が異なるため注文できません。検査内容をご確認の上、商品をお選びください。
   * exam_order_create_validator.can_additional_shipping.waiting_an_hour_is_required: 直近1時間以内に同じ商品の注文があります。さらに購入されたい場合、お手数をおかけしますが1時間以上時間を空けてから再度購入してください。
   * exam_order_create_validator.valid_user.missing_tel_number: 電話番号を入力してください。
   * exam_order_create_validator.valid_shipping_address.shipping_address_is_required: 配送先を入力してください。(郵便番号はハイフン無しで入力してください。）
   * exam_order_create_validator.create_exam_order_mutation.must_select_product_error: 商品を選択してください。
   */
  errors: Array<GraphqlError>;
  /** 注文結果 */
  examOrder?: Maybe<ExamOrder>;
};

/** Autogenerated input type of CreateFcmTokenMutation */
export type CreateFcmTokenMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  token: Scalars['String'];
};

/** Autogenerated return type of CreateFcmTokenMutation */
export type CreateFcmTokenMutationPayload = {
  __typename?: 'CreateFcmTokenMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /**
   * エラーコード表
   * U00001: リクエスト内容が正しくありません (その他エラーの際の汎用コード)
   */
  errors: Array<GraphqlError>;
  fcmToken?: Maybe<FcmToken>;
};

/** Autogenerated input type of CreateNoteMutation */
export type CreateNoteMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  noteAttributes: FemappNoteInput;
};

/** Autogenerated return type of CreateNoteMutation */
export type CreateNoteMutationPayload = {
  __typename?: 'CreateNoteMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /**
   * エラーコード表
   * I00001: システムエラーです。お時間をおいてから再度お試しください。
   * I00010: リクエストが許可されていません
   * U00001: リクエスト内容が正しくありません
   * U00001: 日付が同じデータがあります。別日を選択してください。
   * U00001: 基礎体温は、32度〜42度の範囲で選択してください。
   * U00001: メモは、1000文字以内で入力してください。
   * U00020: IDトークンの有効期限が切れました。再度トークンを取得してください。
   */
  errors: Array<GraphqlError>;
  note?: Maybe<Note>;
};

/** Autogenerated input type of CreateOrFetchPlatformUserMutation */
export type CreateOrFetchPlatformUserMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  fcmToken: Scalars['String'];
};

/** Autogenerated return type of CreateOrFetchPlatformUserMutation */
export type CreateOrFetchPlatformUserMutationPayload = {
  __typename?: 'CreateOrFetchPlatformUserMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  deviceId: Scalars['String'];
};

/** Autogenerated input type of CreatePatientImageMutation */
export type CreatePatientImageMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  detailImageType?: Maybe<DetailImage>;
  /** 画像に紐づくデータがある際に利用する。例：{ reservation_id: xxx } */
  optionsJson?: Maybe<Scalars['JSON']>;
  patientImageType: PatientImage;
  s3Key: Scalars['String'];
};

/** Autogenerated return type of CreatePatientImageMutation */
export type CreatePatientImageMutationPayload = {
  __typename?: 'CreatePatientImageMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /**
   *     エラーコード表
   *         s3_patient_image.over_max_size: 画像サイズは5MB未満にしてください。
   * s3_patient_image.not_found: 指定された画像が存在しません。
   * s3_patient_image.invalid_file_type: 許可されていないファイルです。JPEGやPNGの画像をアップロードしてください。
   *
   *     U00001: リクエスト内容が正しくありません (その他エラーの際の汎用コード)
   */
  errors: Array<GraphqlError>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of CreatePaymentMethodMutation */
export type CreatePaymentMethodMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** StripeAPIにより作成されたPaymentMethodのID */
  stripePaymentMethodId: Scalars['String'];
};

/** Autogenerated return type of CreatePaymentMethodMutation */
export type CreatePaymentMethodMutationPayload = {
  __typename?: 'CreatePaymentMethodMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /**
   * エラーコード表
   * I00001: システムエラーです。お時間をおいてから再度お試しください。
   * U00001: リクエスト内容が正しくありません (その他エラーの際の汎用コード)
   * U00002: ただいま混み合っております。お時間をおいてから再度お試しください。（外部サービスの通信エラーでリトライが必要な際の汎用コード）
   * create_payment_method_mutation.duplicate_error: ご指定のカードは既に登録されています。
   */
  errors: Array<GraphqlError>;
  user?: Maybe<User>;
};

/** Autogenerated input type of CreateQuickInterviewMutation */
export type CreateQuickInterviewMutationInput = {
  answers: Array<QuickInterviewAnswerInput>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  version: Scalars['String'];
};

/** Autogenerated return type of CreateQuickInterviewMutation */
export type CreateQuickInterviewMutationPayload = {
  __typename?: 'CreateQuickInterviewMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  interviewAnswer: InterviewAnswer;
};

/** Autogenerated input type of CreateReminderMutation */
export type CreateReminderMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  reminderAttributes: ReminderInput;
};

/** Autogenerated return type of CreateReminderMutation */
export type CreateReminderMutationPayload = {
  __typename?: 'CreateReminderMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /**
   * エラーコード表
   * I00001: システムエラーです。お時間をおいてから再度お試しください。
   * I00010: リクエストが許可されていません
   * U00001: リクエスト内容が正しくありません
   */
  errors: Array<GraphqlError>;
  reminder?: Maybe<Reminder>;
};

/** Autogenerated input type of CreateReservationMutation */
export type CreateReservationMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  clinicId: Scalars['ID'];
  departmentId: Scalars['ID'];
  lineToken?: Maybe<Scalars['String']>;
  purpose?: Maybe<Scalars['String']>;
  quickInterviewId?: Maybe<Scalars['ID']>;
  reservedAt: Scalars['DateTime'];
  reservedVia?: Maybe<ReservedVia>;
};

/** Autogenerated return type of CreateReservationMutation */
export type CreateReservationMutationPayload = {
  __typename?: 'CreateReservationMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /**
   * エラーコード表
   * U00001: リクエスト内容が正しくありません (その他エラーの際の汎用コード)
   * reservation.already_reserved_the_same_day: 指定の予約時間を予約できませんでした。同日の予約を既にお持ちです。
   * reservation.over_the_reservation_limit: 指定の予約時間を予約できませんでした。お一人当たりの予約数が上限に達しています。
   * reservation.reach_reserving_limit_in_a_day: 指定の予約時間を予約できませんでした。同日の予約上限数に達しています。
   * reservation.exceeded_the_slot_capacity: 指定の予約時間は既に予約がいっぱいです。
   * reservation.reserved_with_the_past_date: 指定の予約時間を予約できませんでした。過去の予約を取ることはできません。
   * reservation.already_reserved_at_the_time: 同時刻にすでにご予約済みです。
   * reservation.department_banned_within_period: 診療科は予約できない時間帯です
   * reservation.out_of_clinic_service: 指定の予約時間クリニックの営業期間外です
   */
  errors: Array<GraphqlError>;
  reservation?: Maybe<Reservation>;
};

/** Autogenerated input type of CreateS3PresignedUrlMutation */
export type CreateS3PresignedUrlMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  filename: Scalars['String'];
  /** ファイル種類 例:image/png */
  filetype?: Maybe<Scalars['String']>;
  patientImageType: PatientImage;
};

/** Autogenerated return type of CreateS3PresignedUrlMutation */
export type CreateS3PresignedUrlMutationPayload = {
  __typename?: 'CreateS3PresignedUrlMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /**
   * エラーコード表
   * U00001: リクエスト内容が正しくありません (その他エラーの際の汎用コード)
   */
  errors: Array<GraphqlError>;
  presignedFields: Scalars['JSON'];
  presignedUrl: Scalars['String'];
};

/** Autogenerated input type of CreateTelemedicineReservationMutation */
export type CreateTelemedicineReservationMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  clinicId: Scalars['ID'];
  departmentId: Scalars['ID'];
  ecAffiliateUtmLog?: Maybe<EcAffiliateUtmLogInput>;
  lineToken?: Maybe<Scalars['String']>;
  purpose?: Maybe<Scalars['String']>;
  reservedAt: Scalars['DateTime'];
  reservedVia?: Maybe<ReservedVia>;
  telemedicineVia?: Maybe<TelemedicineVia>;
};

/** Autogenerated return type of CreateTelemedicineReservationMutation */
export type CreateTelemedicineReservationMutationPayload = {
  __typename?: 'CreateTelemedicineReservationMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /**
   * エラーコード表
   * U00001: リクエスト内容が正しくありません (その他エラーの際の汎用コード)
   * reservation.already_reserved_the_same_day: 指定の予約時間を予約できませんでした。同日の予約を既にお持ちです。
   * reservation.over_the_reservation_limit: 指定の予約時間を予約できませんでした。お一人当たりの予約数が上限に達しています。
   * reservation.reach_reserving_limit_in_a_day: 指定の予約時間を予約できませんでした。同日の予約上限数に達しています。
   * reservation.exceeded_the_slot_capacity: 指定の予約時間は既に予約がいっぱいです。
   * reservation.reserved_with_the_past_date: 指定の予約時間を予約できませんでした。過去の予約を取ることはできません。
   * reservation.already_reserved_at_the_time: 同時刻にすでにご予約済みです。
   * reservation.department_banned_within_period: 診療科は予約できない時間帯です
   * reservation.out_of_clinic_service: 指定の予約時間クリニックの営業期間外です
   */
  errors: Array<GraphqlError>;
  reservation?: Maybe<Reservation>;
  sentLineMessage: Scalars['Boolean'];
};

/** Autogenerated input type of CreateUserBehaviorMutation */
export type CreateUserBehaviorMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  userBehaviorAttributes: FemappUserBehaviorInput;
};

/** Autogenerated return type of CreateUserBehaviorMutation */
export type CreateUserBehaviorMutationPayload = {
  __typename?: 'CreateUserBehaviorMutationPayload';
  behavior?: Maybe<UserBehavior>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /**
   * エラーコード表
   * I00001: システムエラーです。お時間をおいてから再度お試しください。
   * I00010: リクエストが許可されていません
   * U00001: リクエスト内容が正しくありません
   */
  errors: Array<GraphqlError>;
};

/** Autogenerated input type of CreateUserEventMutation */
export type CreateUserEventMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  event: Scalars['JSON'];
};

/** Autogenerated return type of CreateUserEventMutation */
export type CreateUserEventMutationPayload = {
  __typename?: 'CreateUserEventMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /**
   * エラーコード表
   * I00001: システムエラーです。お時間をおいてから再度お試しください。
   * I00010: リクエストが許可されていません
   * U00001: リクエスト内容が正しくありません
   * U00020: IDトークンの有効期限が切れました。再度トークンを取得してください。
   */
  errors: Array<GraphqlError>;
  isSuccess?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated input type of CreateUserInvitedCodeMutation */
export type CreateUserInvitedCodeMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  invitationCode: Scalars['String'];
};

/** Autogenerated return type of CreateUserInvitedCodeMutation */
export type CreateUserInvitedCodeMutationPayload = {
  __typename?: 'CreateUserInvitedCodeMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /**
   * エラーコード表
   * I00001: システムエラーです。お時間をおいてから再度お試しください。
   * U00001: リクエスト内容が正しくありません
   * create_user_invited_code_mutation.blank: 入力された友達紹介コードが存在しません。
   * create_user_invited_code_mutation.same_as_own: 自分の友達紹介コードは登録できません。
   * create_user_invited_code_mutation.failed: 友達紹介コードの登録に失敗しました。
   * create_user_invited_code_mutation.already_exists: 友達紹介コードは既に登録済みです。
   */
  errors: Array<GraphqlError>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of CreateUserSurveyAnswerMutation */
export type CreateUserSurveyAnswerMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  surveyAnswerId: Scalars['ID'];
};

/** Autogenerated return type of CreateUserSurveyAnswerMutation */
export type CreateUserSurveyAnswerMutationPayload = {
  __typename?: 'CreateUserSurveyAnswerMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /**
   * エラーコード表
   * I00001: システムエラーです。お時間をおいてから再度お試しください。
   * I00010: リクエストが許可されていません
   * U00001: リクエスト内容が正しくありません
   * U00020: IDトークンの有効期限が切れました。再度トークンを取得してください。
   */
  errors: Array<GraphqlError>;
  isSuccess?: Maybe<Scalars['Boolean']>;
};

/** [public]  */
export type CriticalNotification = {
  __typename?: 'CriticalNotification';
  /** [public]  */
  content?: Maybe<Scalars['String']>;
  /** [public]  */
  id: Scalars['ID'];
  /** [public]  */
  notifiedDate?: Maybe<Scalars['Date']>;
  /** [public]  */
  redirectTo?: Maybe<Scalars['String']>;
  /** [public]  */
  summary: Scalars['String'];
  /** [public]  */
  title?: Maybe<Scalars['String']>;
};

/** [public]  */
export type Cycle = {
  __typename?: 'Cycle';
  /** [public]  */
  id?: Maybe<Scalars['String']>;
  /** [public]  */
  ovulationScheduledOn?: Maybe<Scalars['Date']>;
  /** [public]  */
  periodLength: Scalars['Int'];
  /** [public]  */
  periodStartOn: Scalars['Date'];
  /** [public]  */
  pmsEndOn?: Maybe<Scalars['Date']>;
  /** [public]  */
  pmsStartOn?: Maybe<Scalars['Date']>;
};

/** [public]  */
export type CycleState = {
  __typename?: 'CycleState';
  /** [public]  */
  cycles: Array<Cycle>;
  /** [public]  */
  stage: FemappPhaseStage;
  /** [public]  */
  stageWhenReadingSummary: FemappPhaseStage;
  /** [public]  */
  status: FemappPhaseStatus;
  /** [public]  */
  statusWhenReadingSummary: FemappPhaseStatus;
};

/** Autogenerated input type of DeleteCycleMutation */
export type DeleteCycleMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of DeleteCycleMutation */
export type DeleteCycleMutationPayload = {
  __typename?: 'DeleteCycleMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  cycleState?: Maybe<CycleState>;
  /**
   * エラーコード表
   * I00001: システムエラーです。お時間をおいてから再度お試しください。
   * I00010: リクエストが許可されていません
   * U00001: リクエスト内容が正しくありません
   * U00020: IDトークンの有効期限が切れました。再度トークンを取得してください。
   * U00022: 該当リソースにはアクセスできません。
   */
  errors: Array<GraphqlError>;
};

/** Autogenerated input type of DeleteNoteMutation */
export type DeleteNoteMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of DeleteNoteMutation */
export type DeleteNoteMutationPayload = {
  __typename?: 'DeleteNoteMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /**
   * エラーコード表
   * I00001: システムエラーです。お時間をおいてから再度お試しください。
   * I00010: リクエストが許可されていません
   * U00001: リクエスト内容が正しくありません
   * U00020: IDトークンの有効期限が切れました。再度トークンを取得してください。
   * U00022: 該当リソースにはアクセスできません。
   */
  errors: Array<GraphqlError>;
  id?: Maybe<Scalars['ID']>;
};

/** Autogenerated input type of DeletePaymentMethodMutation */
export type DeletePaymentMethodMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  paymentMethodId: Scalars['ID'];
};

/** Autogenerated return type of DeletePaymentMethodMutation */
export type DeletePaymentMethodMutationPayload = {
  __typename?: 'DeletePaymentMethodMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /**
   * エラーコード表
   * I00001: システムエラーです。お時間をおいてから再度お試しください。
   * U00001: リクエスト内容が正しくありません (その他エラーの際の汎用コード)
   * payment_method.being_used_in_subscription: 定期配送に使用されている支払方法は削除できません。削除する前に定期配送の支払方法を変更してください。
   */
  errors: Array<GraphqlError>;
  user?: Maybe<User>;
};

/** Autogenerated input type of DeleteReminderMutation */
export type DeleteReminderMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of DeleteReminderMutation */
export type DeleteReminderMutationPayload = {
  __typename?: 'DeleteReminderMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /**
   * エラーコード表
   * I00001: システムエラーです。お時間をおいてから再度お試しください。
   * I00010: リクエストが許可されていません
   * U00001: リクエスト内容が正しくありません
   * U00022: 該当リソースにはアクセスできません。
   */
  errors: Array<GraphqlError>;
  id?: Maybe<Scalars['ID']>;
};

/** Autogenerated input type of DeleteTelemedicineKarteImageMutation */
export type DeleteTelemedicineKarteImageMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  telemedicineKarteImageId: Scalars['ID'];
};

/** Autogenerated return type of DeleteTelemedicineKarteImageMutation */
export type DeleteTelemedicineKarteImageMutationPayload = {
  __typename?: 'DeleteTelemedicineKarteImageMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /**
   * エラーコード表
   * I00001: システムエラーです。お時間をおいてから再度お試しください。
   * U00001: リクエスト内容が正しくありません (その他エラーの際の汎用コード)
   */
  errors: Array<GraphqlError>;
  success: Scalars['Boolean'];
};

/** [public]  */
export type Department = Node & {
  __typename?: 'Department';
  /** [public]  */
  agesAndUp?: Maybe<Scalars['Int']>;
  /** [public]  */
  clinicMenuDetail?: Maybe<ClinicMenuDetail>;
  /** [public]  */
  createdAt: Scalars['DateTime'];
  /** [public]  */
  humanName: Scalars['String'];
  /** [public]  */
  id: Scalars['ID'];
  /** [public]  */
  isMedicalFeesFree?: Maybe<Scalars['Boolean']>;
  /** [public] オンライン診療の保険診療かどうか */
  isTelemedicineInsurance: Scalars['Boolean'];
  /** [public]  */
  name: Scalars['String'];
  /** [public]  */
  occupancyRate: Scalars['Float'];
  /** [public]  */
  platformDisplayType: DepartmentPlatformDisplay;
  /** [public]  */
  platformName: Scalars['String'];
  /** [public]  */
  platformThumbnailUrl?: Maybe<Scalars['String']>;
  /** [public]  */
  telemedicineMenuDetail?: Maybe<TelemedicineMenuDetail>;
  /** [public]  */
  updatedAt?: Maybe<Scalars['DateTime']>;
  /** [public] オンライン診療追加配送画面のURL。オンライン診療の特定診療科のみ値を持つ */
  webviewAdditionalDeliveryUrl?: Maybe<Scalars['String']>;
  /** [public] 対面診療LPのURL。対面診療の診療科のみ値を持つ */
  webviewLpUrl?: Maybe<Scalars['String']>;
  /** [public] オンライン診療予約画面のURL。オンライン診療の診療科のみ値を持つ */
  webviewReservationUrl?: Maybe<Scalars['String']>;
};

/** [public]  */
export type DepartmentContent = Node & {
  __typename?: 'DepartmentContent';
  /** [public]  */
  agesAndUp?: Maybe<Scalars['Int']>;
  /** [public]  */
  alertText?: Maybe<Scalars['String']>;
  /** [public]  */
  appliedFrom: Scalars['DateTime'];
  /** [public]  */
  appliedTo?: Maybe<Scalars['DateTime']>;
  /** [public]  */
  contentType: Scalars['Int'];
  /** [public]  */
  departmentId: Scalars['ID'];
  /** [public]  */
  id: Scalars['ID'];
  /** [public]  */
  sortPriority: Scalars['Int'];
  /** [public]  */
  userLabel: Scalars['String'];
};

export enum DepartmentPlatformDisplay {
  Hidden = 'hidden',
  OnlyFemale = 'only_female',
  OnlyMale = 'only_male',
  Whole = 'whole',
}

export enum DetailImage {
  /** 正面のはえぎわ */
  FrontHead = 'front_head',
  /** 左前からのはえぎわ */
  FrontLeftHead = 'front_left_head',
  /** 右前からのはえぎわ */
  FrontRightHead = 'front_right_head',
  /** 診断結果 */
  MedicalCheckupResult = 'medical_checkup_result',
  /** 頭のてっぺん */
  TopHead = 'top_head',
}

/** [public]  */
export type DiagnoseBlock = {
  __typename?: 'DiagnoseBlock';
  /** [public]  */
  blockType: Scalars['Int'];
  /** [public]  */
  choices: Array<DiagnoseChoice>;
  /**
   * [public]           エラーコード表
   *           U00001: リクエスト内容が正しくありません (その他エラーの際の汎用コード)
   */
  errors: Array<GraphqlError>;
  /** [public]  */
  id: Scalars['ID'];
  /** [public]  */
  layoutType: Scalars['Int'];
  /** [public]  */
  message: Scalars['String'];
  /** [public]  */
  textareaPlaceholder?: Maybe<Scalars['String']>;
  /** [public]  */
  withTextarea: Scalars['Boolean'];
};

/** [public]  */
export type DiagnoseChoice = {
  __typename?: 'DiagnoseChoice';
  /**
   * [public]           エラーコード表l
   *           U00001: リクエスト内容が正しくありません (その他エラーの際の汎用コード)
   */
  errors: Array<GraphqlError>;
  /** [public]  */
  id: Scalars['ID'];
  /** [public]  */
  nextPageId?: Maybe<Scalars['Int']>;
  /** [public]  */
  title: Scalars['String'];
};

export type DiagnosticAnswerInput = {
  blockId: Scalars['Int'];
  choiceId?: Maybe<Scalars['Int']>;
  message?: Maybe<Scalars['String']>;
};

/** [public]  */
export type DietInsight = {
  __typename?: 'DietInsight';
  /** [public]  */
  description: Scalars['String'];
  /** [public]  */
  image: Scalars['String'];
  /** [public]  */
  name: Scalars['String'];
};

/** [public]  */
export type DuplicatedEcSubscription = {
  __typename?: 'DuplicatedEcSubscription';
  /** [public] 重複する薬剤を含んだキャンセルされる商品 */
  duplicatedCancelEcProducts: Array<Scalars['String']>;
  /** [public] 重複する薬剤を含んだ定期契約 */
  duplicatedEcSubscriptionOrders: Array<EcSubscriptionOrder>;
  /** [public] 重複する薬剤 */
  duplicatedPrescriptions: Array<Scalars['String']>;
  /** [public] 重複する薬剤を含まないキャンセルされる商品 */
  notDuplicatedCancelEcProducts: Array<Scalars['String']>;
};

export type EcAffiliateUtmLogInput = {
  utmCampaign?: Maybe<Scalars['String']>;
  utmContent?: Maybe<Scalars['String']>;
  utmMedium?: Maybe<Scalars['String']>;
  utmSource?: Maybe<Scalars['String']>;
};

/** [user]  */
export type EcCorporateBenefit = {
  __typename?: 'EcCorporateBenefit';
  /** [public]  */
  applicableIds?: Maybe<Array<Scalars['BigInt']>>;
  /** [public]  */
  appliedFrom?: Maybe<Scalars['Date']>;
  /** [public]  */
  appliedTo?: Maybe<Scalars['Date']>;
  /** [public]  */
  companyName: Scalars['String'];
  /** [public] 金額指定割引の際には必須 */
  discountPrice?: Maybe<Scalars['Int']>;
  /** [public] パーセント割引の際には必須 */
  discountRate?: Maybe<Scalars['Float']>;
  /** [public]  */
  discountType: EcCorporateBenefitDiscount;
  /** [public] 社員番号入力有無 */
  hasEmployeeNumber: Scalars['Boolean'];
  /** [public] 保険証情報入力有無 */
  hasInsuredInfo: Scalars['Boolean'];
  /** [public]  */
  id: Scalars['ID'];
  /** [public]  */
  isMedicalFeesFree: Scalars['Boolean'];
  /** [public]  */
  isShippingFeeFree: Scalars['Boolean'];
  /** [public]  */
  kind: EcCouponKind;
};

export enum EcCorporateBenefitDiscount {
  /** 指定なし */
  MedicineDiscountNone = 'medicine_discount_none',
  /** お薬代を金額指定で割引 */
  MedicineDiscountPrice = 'medicine_discount_price',
  /** お薬代をパーセント割引 */
  MedicineDiscountRate = 'medicine_discount_rate',
}

export enum EcCorporateBenefitHistoryUserType {
  /** 家族 */
  Family = 'family',
  /** 本人 */
  Individual = 'individual',
  /** 不明 */
  Unknown = 'unknown',
}

/** [user]  */
export type EcCoupon = {
  __typename?: 'EcCoupon';
  /** [public]  */
  applicableIds?: Maybe<Array<Scalars['BigInt']>>;
  /** [public]  */
  appliedFrom?: Maybe<Scalars['Date']>;
  /** [public]  */
  appliedTo?: Maybe<Scalars['Date']>;
  /** [public]  */
  createdAt: Scalars['DateTime'];
  /** [public] 金額指定割引の際には必須 */
  discountPrice?: Maybe<Scalars['Int']>;
  /** [public] パーセント割引の際には必須 */
  discountRate?: Maybe<Scalars['Float']>;
  /** [public]  */
  discountType?: Maybe<EcCouponDiscount>;
  /** [public] 商品指定割引の対象商品 */
  ecProductCoupons: Array<EcProductCoupon>;
  /** [public]  */
  id: Scalars['ID'];
  /** [public]  */
  isMedicalFeesFree?: Maybe<Scalars['Boolean']>;
  /** [public]  */
  isShippingFeeFree?: Maybe<Scalars['Boolean']>;
  /** [public]  */
  kind: EcCouponKind;
  /** [public]  */
  updatedAt: Scalars['DateTime'];
  /** [public]  */
  userLabel?: Maybe<Scalars['String']>;
};

export enum EcCouponDiscount {
  /** 指定なし */
  MedicineDiscountNone = 'medicine_discount_none',
  /** お薬代を金額指定で割引 */
  MedicineDiscountPrice = 'medicine_discount_price',
  /** お薬代をパーセント割引 */
  MedicineDiscountRate = 'medicine_discount_rate',
}

export enum EcCouponKind {
  /** すべての商品 */
  AllProduct = 'all_product',
  /** 診療科指定 */
  Category = 'category',
  /** 診療科指定かつ商品指定 */
  CategoryAndProduct = 'category_and_product',
}

/** [user]  */
export type EcOrder = Node & {
  __typename?: 'EcOrder';
  /** [public]  */
  canOrderAdditionalDelivery: Scalars['Boolean'];
  /** [public]  */
  cancelledAt?: Maybe<Scalars['DateTime']>;
  /** [public]  */
  createdAt: Scalars['DateTime'];
  /** [public] クーポン */
  ecCoupon?: Maybe<EcCoupon>;
  /** [public] 商品と数量 */
  ecOrderProducts: Array<EcOrderProduct>;
  /** [public] 金額の内訳 */
  ecPaymentAmount: EcPaymentAmount;
  /** [public] 定期配送 */
  ecSubscriptionOrder?: Maybe<EcSubscriptionOrder>;
  /** [public] 定期配送 */
  ecSubscriptionOrders: Array<EcSubscriptionOrder>;
  /** [public] 配送先住所 */
  ecUserShippingAddress?: Maybe<EcUserShippingAddress>;
  /** [public]  */
  failedAt?: Maybe<Scalars['DateTime']>;
  /** [public] 付与ポイント */
  grantedPoint?: Maybe<Point>;
  /** [public]  */
  id: Scalars['ID'];
  /** [public] 定期購入の場合には必須 */
  nthOrder?: Maybe<Scalars['Int']>;
  /** [public]  */
  paidAt?: Maybe<Scalars['DateTime']>;
  /** [public] 決済手段 */
  paymentType?: Maybe<Payment>;
  /** [public]  */
  reservation?: Maybe<Reservation>;
  /** [public]  */
  shippedAt?: Maybe<Scalars['DateTime']>;
  /** [public]  */
  shippingNumber?: Maybe<Scalars['String']>;
  /** [public]  */
  status: EcOrderStatus;
  /** [public]  */
  updatedAt: Scalars['DateTime'];
  /** [public] 利用ポイント */
  usedPoints: Array<Point>;
};

/** The connection type for EcOrder. */
export type EcOrderConnection = {
  __typename?: 'EcOrderConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<EcOrderEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<EcOrder>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type EcOrderEdge = {
  __typename?: 'EcOrderEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<EcOrder>;
};

export type EcOrderInput = {
  /** 企業優待ID */
  ecCorporateBenefitId?: Maybe<Scalars['ID']>;
  /** クーポンID */
  ecCouponId?: Maybe<Scalars['ID']>;
  /** 購入するお薬情報 */
  ecOrderProducts: Array<EcOrderProductInput>;
  /** 郵送先情報 */
  ecUserShippingAddress: EcUserShippingAddressInput;
  /** 社員番号 */
  employeeNumber?: Maybe<Scalars['String']>;
  /** 保険証情報 */
  insuredInfo?: Maybe<Scalars['String']>;
  /** 予約ID。追加注文の場合は指定なし */
  reservationId?: Maybe<Scalars['ID']>;
  /** 使用者種別 */
  userType?: Maybe<EcCorporateBenefitHistoryUserType>;
};

/** [user]  */
export type EcOrderProduct = {
  __typename?: 'EcOrderProduct';
  /** [public]  */
  ecProduct: EcProduct;
  /** [public]  */
  id: Scalars['ID'];
  /** [public]  */
  quantity: Scalars['Int'];
};

export type EcOrderProductInput = {
  /** 商品ID */
  ecProductId: Scalars['ID'];
  /** 個数 */
  quantity: Scalars['Int'];
};

export enum EcOrderStatus {
  Cancelled = 'cancelled',
  Created = 'created',
  Failed = 'failed',
  Paid = 'paid',
  Sent = 'sent',
}

/** [user]  */
export type EcPaymentAmount = {
  __typename?: 'EcPaymentAmount';
  /** [public] 割引金額 */
  discountAmount: Scalars['Int'];
  /** [public] 注文情報 */
  ecOrder?: Maybe<EcOrder>;
  /** [public]  */
  id: Scalars['ID'];
  /** [public] 予約料・診察料 */
  medicalFee: Scalars['Int'];
  /** [public] 支払い手段ごとの手数料 */
  paymentFee: Scalars['Int'];
  /** [public] ポイント割引金額 */
  pointAmount: Scalars['Int'];
  /** [public] 商品の合計金額 */
  purchaseAmount: Scalars['Int'];
  /** [public] 送料 */
  shippingFee: Scalars['Int'];
  /** [public] 合計金額 */
  total: Scalars['Int'];
  /** [public] 獲得ポイント合計値 */
  totalPointValue: Scalars['Int'];
};

/** [user]  */
export type EcPaymentSchedule = {
  __typename?: 'EcPaymentSchedule';
  /** [public]  */
  customScheduleDays?: Maybe<Array<Scalars['String']>>;
  /** [public]  */
  deliverAtFirst: Scalars['Boolean'];
  /** [public]  */
  hasRestPeriod: Scalars['Boolean'];
  /** [public]  */
  id: Scalars['ID'];
  /** [public]  */
  intervalDaysToEachDelivery: Scalars['Int'];
  /** [public]  */
  intervalDaysToTheNextDelivery?: Maybe<Scalars['Int']>;
  /** [public]  */
  name?: Maybe<Scalars['String']>;
};

/** [user]  */
export type EcProduct = {
  __typename?: 'EcProduct';
  /** [public]  */
  amountText: Scalars['String'];
  /** [public]  */
  childProducts: Array<EcProduct>;
  /** [public]  */
  discountRateText?: Maybe<Scalars['String']>;
  /** [public]  */
  displayType?: Maybe<EcProductDisplay>;
  /** [public]  */
  ecPaymentSchedule?: Maybe<EcPaymentSchedule>;
  /** [public]  */
  ecProductTag?: Maybe<EcProductTag>;
  /** [public]  */
  id: Scalars['ID'];
  /** [public]  */
  isOnSale?: Maybe<Scalars['Boolean']>;
  /** [public]  */
  isQuantity: Scalars['Boolean'];
  /** [public]  */
  isRecurring?: Maybe<Scalars['Boolean']>;
  /** [public]  */
  isSetProduct: Scalars['Boolean'];
  /** [public]  */
  maxOrdersCount?: Maybe<Scalars['Int']>;
  /** [public]  */
  month?: Maybe<Scalars['Int']>;
  /** [public]  */
  name: Scalars['String'];
  /** [public]  */
  notes?: Maybe<Scalars['String']>;
  /** [public]  */
  pointValue: Scalars['Int'];
  /** [public]  */
  price: Scalars['Int'];
  /** [public]  */
  productCategoryId: ProductCategoryId;
  /** [public]  */
  quantityMax?: Maybe<Scalars['Int']>;
  /** [public]  */
  quantityMin?: Maybe<Scalars['Int']>;
  /** [public]  */
  skuCode: Scalars['String'];
  /** [public]  */
  sortKey?: Maybe<Scalars['Int']>;
  /** [public]  */
  summary?: Maybe<Scalars['String']>;
  /** [public]  */
  uncancellableNumberOfTimes: Scalars['Int'];
  /** [public]  */
  yamatoCoolType?: Maybe<YamatoCool>;
};

/** [user]  */
export type EcProductCoupon = {
  __typename?: 'EcProductCoupon';
  /** [public]  */
  ecCoupon: EcCoupon;
  /** [public]  */
  ecProduct: EcProduct;
  /** [public]  */
  id: Scalars['ID'];
};

export enum EcProductDisplay {
  /** 診察決済・追加配送両方表示 */
  DisplayAll = 'display_all',
  /** 追加配送のみ表示 */
  OnlyAdditional = 'only_additional',
  /** 定期配送解約希望者のみ表示 */
  OnlyRequestRegularCancellation = 'only_request_regular_cancellation',
  /** 診察決済のみ表示 */
  OnlyReservation = 'only_reservation',
}

/** [user]  */
export type EcProductTag = {
  __typename?: 'EcProductTag';
  /** [public]  */
  color: Scalars['String'];
  /** [public]  */
  ecProductTagDescription?: Maybe<EcProductTagDescription>;
  /** [public]  */
  ecProductTagImage?: Maybe<EcProductTagImage>;
  /** [public]  */
  ecProducts?: Maybe<Array<EcProduct>>;
  /** [public]  */
  id: Scalars['ID'];
  /** [public]  */
  name: Scalars['String'];
  /** [public]  */
  productCategoryId: ProductCategoryId;
  /** [public]  */
  sortKey?: Maybe<Scalars['Int']>;
  /** [public]  */
  telemedicinePrescriptions: Array<TelemedicinePrescription>;
  /** [public]  */
  textColor?: Maybe<Scalars['String']>;
};

/** [user]  */
export type EcProductTagDescription = {
  __typename?: 'EcProductTagDescription';
  /** [public]  */
  cautionForUse?: Maybe<Scalars['String']>;
  /** [public]  */
  description?: Maybe<Scalars['String']>;
  /** [public]  */
  efficacy?: Maybe<Scalars['String']>;
  /** [public]  */
  id: Scalars['ID'];
  /** [public]  */
  medicinalSubstance?: Maybe<Scalars['String']>;
  /** [public]  */
  recommendDescription?: Maybe<Scalars['String']>;
  /** [public]  */
  regularPlanPrice?: Maybe<Scalars['Int']>;
  /** [public]  */
  sideEffect?: Maybe<Scalars['String']>;
  /** [public]  */
  subscriptionPlanPrice?: Maybe<Scalars['Int']>;
  /** [public]  */
  tabletCount?: Maybe<Scalars['Int']>;
  /** [public]  */
  tabletCountPerDay?: Maybe<Scalars['Int']>;
  /** [public]  */
  targetUsers?: Maybe<Scalars['String']>;
  /** [public]  */
  usage?: Maybe<Scalars['String']>;
};

/** [user]  */
export type EcProductTagImage = {
  __typename?: 'EcProductTagImage';
  /** [public]  */
  id: Scalars['ID'];
  /** [public]  */
  largeImageUrl?: Maybe<Scalars['String']>;
  /** [public]  */
  mediumImageUrl?: Maybe<Scalars['String']>;
};

/** [public]  */
export type EcProductsAndCheckedEcProductTags = {
  __typename?: 'EcProductsAndCheckedEcProductTags';
  /** [public]  */
  checkedEcProductTags: Array<EcProductTag>;
  /** [public]  */
  ecProducts: Array<EcProduct>;
  /** [public]  */
  lastPurchasedEcProducts: Array<EcProduct>;
};

/** [user]  */
export type EcSubscriptionOrder = Node & {
  __typename?: 'EcSubscriptionOrder';
  /** [public]  */
  cancelledAt?: Maybe<Scalars['DateTime']>;
  /** [public]  */
  createdAt: Scalars['DateTime'];
  /** [public] 支払い方法変更に伴う、次回請求予定額 */
  ecOrderPrice: Scalars['Int'];
  /** [public] 注文情報 */
  ecOrders: EcOrderConnection;
  /** [public] 商品と数量 */
  ecSubscriptionOrderProducts: Array<EcSubscriptionOrderProduct>;
  /** [public] 同時にキャンセルされない定期配送を返す */
  ecSubscriptionOrdersNotCancelSameTime: Array<EcSubscriptionOrder>;
  /** [public] 同時にキャンセルする対象の定期配送を返す */
  ecSubscriptionOrdersToCancelSameTime: Array<EcSubscriptionOrder>;
  /** [public] 配送先住所 */
  ecUserShippingAddress: EcUserShippingAddress;
  /** [public]  */
  id: Scalars['ID'];
  /** [public] 直近の注文 */
  latestOrder: EcOrder;
  /** [public] 決済回数の最大値を取得 */
  maxNthOrderValue: Scalars['Int'];
  /** [public] 定期解約不可回数の最大値を取得 */
  maxUncancellableValue: Scalars['Int'];
  /** [public] 購入回数 */
  ordersCount: Scalars['Int'];
  /** [public] 支払い方法 */
  paymentMethod?: Maybe<PaymentMethod>;
  /** [public] 決済手段 */
  paymentType: Payment;
  /** [public] 予約 */
  reservation?: Maybe<Reservation>;
  /** [public] 次回配送予定日 */
  scheduledToBeShippedAt?: Maybe<Scalars['Date']>;
  /** [public]  */
  status: EcSubscriptionOrderStatus;
  /** [public] ステータスの日本語表記 */
  statusI18n: Scalars['String'];
  /** [public]  */
  suspendedAt?: Maybe<Scalars['DateTime']>;
  /** [public] 獲得ポイント合計値 */
  totalPointValue: Scalars['Int'];
  /** [public]  */
  updatedAt: Scalars['DateTime'];
};

/** [user]  */
export type EcSubscriptionOrderEcOrdersArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** The connection type for EcSubscriptionOrder. */
export type EcSubscriptionOrderConnection = {
  __typename?: 'EcSubscriptionOrderConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<EcSubscriptionOrderEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<EcSubscriptionOrder>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type EcSubscriptionOrderEdge = {
  __typename?: 'EcSubscriptionOrderEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<EcSubscriptionOrder>;
};

/** [user]  */
export type EcSubscriptionOrderProduct = {
  __typename?: 'EcSubscriptionOrderProduct';
  /** [public]  */
  ecProduct: EcProduct;
  /** [public]  */
  id: Scalars['ID'];
  /** [public]  */
  quantity: Scalars['Int'];
};

export enum EcSubscriptionOrderStatus {
  Active = 'active',
  Cancelled = 'cancelled',
  Rejected = 'rejected',
  Suspend = 'suspend',
}

/** [user]  */
export type EcUserShippingAddress = {
  __typename?: 'EcUserShippingAddress';
  /** [public] 住所 */
  address: Scalars['String'];
  /** [public]  */
  createdAt: Scalars['DateTime'];
  /** [public]  */
  entriedAt: Scalars['DateTime'];
  /** [public]  */
  id: Scalars['ID'];
  /** [public] 発送元をご自身からとするか否か */
  isShipFromSameAsDestination?: Maybe<Scalars['Boolean']>;
  /** [public] 郵便番号 */
  postcode: Scalars['String'];
  /** [public]  */
  updatedAt: Scalars['DateTime'];
};

export type EcUserShippingAddressInput = {
  /** 住所 */
  address?: Maybe<Scalars['String']>;
  /** 発送元をご自身からとするか否か */
  isShipFromSameAsDestination: Scalars['Boolean'];
  /** 郵便番号 */
  postcode?: Maybe<Scalars['String']>;
};

/** [user]  */
export type ExamBloodResult = Node & {
  __typename?: 'ExamBloodResult';
  /** [public]  */
  appliedAt?: Maybe<Scalars['DateTime']>;
  /** [public]  */
  birthday?: Maybe<Scalars['Date']>;
  /** [public]  */
  categoryList: Array<ExamBloodResultCategory>;
  /** [public]  */
  completedAt?: Maybe<Scalars['DateTime']>;
  /** [public]  */
  contactNumber: Scalars['String'];
  /** [public]  */
  contentJson: Scalars['JSON'];
  /** [public]  */
  createdAt: Scalars['DateTime'];
  /** [public]  */
  id: Scalars['ID'];
  /** [public]  */
  lowOutlierCategoryNames: Array<Scalars['String']>;
  /** [public]  */
  outlierCategoryNames: Array<Scalars['String']>;
  /** [public]  */
  status: Scalars['Int'];
  /** [public]  */
  summaryComment: Scalars['String'];
  /** [public]  */
  summaryTitle: Scalars['String'];
  /** [public]  */
  summaryType: ExamBloodResultSummary;
  /** [public]  */
  user: User;
};

/** [public]  */
export type ExamBloodResultCategory = {
  __typename?: 'ExamBloodResultCategory';
  /** [public] カテゴリに属する検査項目 */
  bloodResultItems: Array<ExamBloodResultItem>;
  /** [public] 表示カテゴリ肝機能など */
  categoryName: Scalars['String'];
};

/** The connection type for ExamBloodResult. */
export type ExamBloodResultConnection = {
  __typename?: 'ExamBloodResultConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ExamBloodResultEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<ExamBloodResult>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type ExamBloodResultEdge = {
  __typename?: 'ExamBloodResultEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<ExamBloodResult>;
};

/** [public]  */
export type ExamBloodResultItem = {
  __typename?: 'ExamBloodResultItem';
  /** [public] 検査項目コード */
  code?: Maybe<Scalars['String']>;
  /** [public] 医師アドバイス */
  doctorAdviceText?: Maybe<Scalars['String']>;
  /** [public] 血液検査の表示名 */
  itemName: Scalars['String'];
  /** [public] 検査値が受診勧奨値 ~ 基準値下限のコメント */
  lowerRecommendedComment?: Maybe<Scalars['String']>;
  /** [public] 検査値が受診勧奨値未満のコメント */
  lowerStandardComment?: Maybe<Scalars['String']>;
  /** [public] 基準値下限 */
  lowerStandardValue?: Maybe<Scalars['Float']>;
  /** [public] 血液検査の検査項目名 */
  name: Scalars['String'];
  /** [public] 推奨値 */
  recommendedValue?: Maybe<Scalars['Float']>;
  /** [public] 検査結果種別 */
  summaryType: ExamBloodResultSummary;
  /** [public] 検査項目単位 */
  unit?: Maybe<Scalars['String']>;
  /** [public] 検査値が基準値上限 ~ 受診勧奨値のコメント */
  upperRecommendedComment?: Maybe<Scalars['String']>;
  /** [public] 検査値が受診勧奨値以上のコメント */
  upperStandardComment?: Maybe<Scalars['String']>;
  /** [public] 基準値上限 */
  upperStandardValue?: Maybe<Scalars['Float']>;
  /** [public] 検査値 */
  value?: Maybe<Scalars['Float']>;
};

export enum ExamBloodResultSummary {
  /** 軽度異常値あり */
  LowOutlier = 'low_outlier',
  /** 異常値なし */
  Nothing = 'nothing',
  /** 異常値あり */
  Outlier = 'outlier',
}

/** [user]  */
export type ExamCoupon = {
  __typename?: 'ExamCoupon';
  /** [public]  */
  applicableIds?: Maybe<Array<Scalars['BigInt']>>;
  /** [public]  */
  appliedFrom?: Maybe<Scalars['Date']>;
  /** [public]  */
  appliedTo?: Maybe<Scalars['Date']>;
  /** [public]  */
  createdAt: Scalars['DateTime'];
  /** [public] 金額指定割引の際には必須 */
  discountPrice?: Maybe<Scalars['Int']>;
  /** [public] パーセント割引の際には必須 */
  discountRate?: Maybe<Scalars['Float']>;
  /** [public]  */
  discountType?: Maybe<ExamCouponDiscount>;
  /** [public] 商品指定割引の対象商品 */
  examProductCoupons: Array<ExamProductCoupon>;
  /** [public]  */
  id: Scalars['ID'];
  /** [public]  */
  isShippingFeeFree?: Maybe<Scalars['Boolean']>;
  /** [public]  */
  kind: ExamCouponKind;
  /** [public]  */
  updatedAt: Scalars['DateTime'];
  /** [public]  */
  userLabel?: Maybe<Scalars['String']>;
};

export enum ExamCouponDiscount {
  /** 指定なし */
  MedicineDiscountNone = 'medicine_discount_none',
  /** お薬代を金額指定で割引 */
  MedicineDiscountPrice = 'medicine_discount_price',
  /** お薬代をパーセント割引 */
  MedicineDiscountRate = 'medicine_discount_rate',
}

export enum ExamCouponKind {
  /** すべての商品 */
  AllProduct = 'all_product',
  /** 診療科指定 */
  Category = 'category',
  /** 診療科指定かつ商品指定 */
  CategoryAndProduct = 'category_and_product',
}

/** [user]  */
export type ExamHpvResult = Node & {
  __typename?: 'ExamHpvResult';
  /** [public]  */
  applicationNumber: Scalars['String'];
  /** [public]  */
  appliedAt?: Maybe<Scalars['DateTime']>;
  /** [public]  */
  birthday?: Maybe<Scalars['Date']>;
  /** [public]  */
  completedAt?: Maybe<Scalars['DateTime']>;
  /** [public]  */
  createdAt: Scalars['DateTime'];
  /** [public]  */
  hpv16ResultCode: ExamHpvResultHpvSixteenResultCode;
  /** [public]  */
  hpv18ResultCode: ExamHpvResultHpvEighteenResultCode;
  /** [public]  */
  hpvOtherResultCode: ExamHpvResultHpvOtherResultCode;
  /** [public]  */
  id: Scalars['ID'];
  /** [public]  */
  receivedAt?: Maybe<Scalars['DateTime']>;
  /** [public]  */
  status: ExamHpvResultStatus;
  /** [public]  */
  user: User;
};

/** The connection type for ExamHpvResult. */
export type ExamHpvResultConnection = {
  __typename?: 'ExamHpvResultConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ExamHpvResultEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<ExamHpvResult>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type ExamHpvResultEdge = {
  __typename?: 'ExamHpvResultEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<ExamHpvResult>;
};

export enum ExamHpvResultHpvEighteenResultCode {
  /** 判定不能 */
  Hpv_18Indeterminate = 'hpv_18_indeterminate',
  /** 陰性 */
  Hpv_18Negative = 'hpv_18_negative',
  /** 陽性 */
  Hpv_18Positive = 'hpv_18_positive',
}

export enum ExamHpvResultHpvOtherResultCode {
  /** 判定不能 */
  HpvOtherIndeterminate = 'hpv_other_indeterminate',
  /** 陰性 */
  HpvOtherNegative = 'hpv_other_negative',
  /** 陽性 */
  HpvOtherPositive = 'hpv_other_positive',
}

export enum ExamHpvResultHpvSixteenResultCode {
  /** 判定不能 */
  Hpv_16Indeterminate = 'hpv_16_indeterminate',
  /** 陰性 */
  Hpv_16Negative = 'hpv_16_negative',
  /** 陽性 */
  Hpv_16Positive = 'hpv_16_positive',
}

export enum ExamHpvResultStatus {
  /** キャンセル */
  Cancelled = 'cancelled',
  /** 発送済み */
  Delivered = 'delivered',
  /** 分析完了 */
  Finished = 'finished',
  /** 未発送 */
  NoDeliver = 'no_deliver',
  /** 検体受領済み */
  Received = 'received',
  /** 検査中止 */
  Stopped = 'stopped',
}

/** [public]  */
export type ExamOnlineTestResults = {
  __typename?: 'ExamOnlineTestResults';
  /** [public]  */
  antibodyTestResults: Array<AntibodyTestResult>;
  /** [public]  */
  examBloodResults: Array<ExamBloodResult>;
  /** [public]  */
  examHpvResults: Array<ExamHpvResult>;
  /** [public]  */
  examStdResults: Array<ExamStdResult>;
  /** [public]  */
  pcrResults: Array<PcrResult>;
  /** [public]  */
  stdResultReservations: Array<Reservation>;
};

/** [user]  */
export type ExamOrder = Node & {
  __typename?: 'ExamOrder';
  /** [public]  */
  cancelledAt?: Maybe<Scalars['DateTime']>;
  /** [public]  */
  createdAt: Scalars['DateTime'];
  /** [public] クーポン */
  examCoupon?: Maybe<ExamCoupon>;
  /** [public] 商品と数量 */
  examOrderProducts: Array<ExamOrderProduct>;
  /** [public] 金額の内訳 */
  examPaymentAmount: ExamPaymentAmount;
  /** [public] 配送先住所 */
  examUserShippingAddress?: Maybe<ExamUserShippingAddress>;
  /** [public]  */
  failedAt?: Maybe<Scalars['DateTime']>;
  /** [public]  */
  id: Scalars['ID'];
  /** [public]  */
  paidAt?: Maybe<Scalars['DateTime']>;
  /** [public] 決済手段 */
  paymentType?: Maybe<Payment>;
  /** [public]  */
  shippedAt?: Maybe<Scalars['DateTime']>;
  /** [public]  */
  shippingNumber?: Maybe<Scalars['String']>;
  /** [public]  */
  status: ExamOrderStatus;
  /** [public]  */
  updatedAt: Scalars['DateTime'];
};

/** The connection type for ExamOrder. */
export type ExamOrderConnection = {
  __typename?: 'ExamOrderConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ExamOrderEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<ExamOrder>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type ExamOrderEdge = {
  __typename?: 'ExamOrderEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<ExamOrder>;
};

export type ExamOrderInput = {
  /** クーポンID */
  examCouponId?: Maybe<Scalars['ID']>;
  /** 購入するキット情報 */
  examOrderProducts: Array<ExamOrderProductInput>;
  /** 郵送先情報 */
  examUserShippingAddress: ExamUserShippingAddressInput;
};

/** [user]  */
export type ExamOrderProduct = {
  __typename?: 'ExamOrderProduct';
  /** [public]  */
  examProduct: ExamProduct;
  /** [public]  */
  id: Scalars['ID'];
  /** [public]  */
  quantity: Scalars['Int'];
};

export type ExamOrderProductInput = {
  /** 商品ID */
  examProductId: Scalars['ID'];
  /** 個数 */
  quantity: Scalars['Int'];
};

export enum ExamOrderStatus {
  Cancelled = 'cancelled',
  Created = 'created',
  Failed = 'failed',
  Paid = 'paid',
  Sent = 'sent',
}

/** [user]  */
export type ExamPaymentAmount = {
  __typename?: 'ExamPaymentAmount';
  /** [public] 割引金額 */
  discountAmount: Scalars['Int'];
  /** [public] 注文情報 */
  examOrder?: Maybe<ExamOrder>;
  /** [public]  */
  id: Scalars['ID'];
  /** [public] 支払い手段ごとの手数料 */
  paymentFee: Scalars['Int'];
  /** [public] ポイント割引金額 */
  pointAmount: Scalars['Int'];
  /** [public] 商品の合計金額 */
  purchaseAmount: Scalars['Int'];
  /** [public] 送料 */
  shippingFee: Scalars['Int'];
  /** [public] 合計金額 */
  total: Scalars['Int'];
};

/** [public]  */
export type ExamProduct = {
  __typename?: 'ExamProduct';
  /** [public]  */
  examType: ExamProductExamType;
  /** [public]  */
  id: Scalars['ID'];
  /** [public]  */
  inspectionText: Scalars['String'];
  /** [public]  */
  isOnSale?: Maybe<Scalars['Boolean']>;
  /** [public]  */
  name: Scalars['String'];
  /** [public]  */
  note: Scalars['String'];
  /** [public]  */
  price: Scalars['Int'];
  /** [public]  */
  stdMenu?: Maybe<StdMenu>;
};

/** [user]  */
export type ExamProductCoupon = {
  __typename?: 'ExamProductCoupon';
  /** [public]  */
  examCoupon: ExamCoupon;
  /** [public]  */
  examProduct: ExamProduct;
  /** [public]  */
  id: Scalars['ID'];
};

export enum ExamProductExamType {
  /** 血液検査キット一覧 */
  Blood = 'blood',
  /** HPV検査キット一覧 */
  Hpv = 'hpv',
  /** STD検査キット一覧 */
  Std = 'std',
}

/** [user]  */
export type ExamStdInspectionResult = {
  __typename?: 'ExamStdInspectionResult';
  /** [public]  */
  examStdResult: ExamStdResult;
  /** [public]  */
  examinationName: Scalars['String'];
  /** [public]  */
  id: Scalars['ID'];
  /** [public]  */
  result: ExamStdInspectionResultResult;
  /** [public]  */
  resultComment: Scalars['String'];
  /** [public]  */
  specimenName: Scalars['String'];
  /** [public]  */
  user: User;
};

export enum ExamStdInspectionResultResult {
  /** 陽性 */
  Equiv = 'equiv',
  /** 陰性 */
  Negative = 'negative',
  /** 陽性 */
  Positive = 'positive',
  /** 検体採取量不足 */
  ResultInvalid = 'result_invalid',
}

/** [user]  */
export type ExamStdResult = Node & {
  __typename?: 'ExamStdResult';
  /** [public]  */
  appliedAt: Scalars['DateTime'];
  /** [public]  */
  completedAt?: Maybe<Scalars['DateTime']>;
  /** [public]  */
  createdAt: Scalars['DateTime'];
  /** [public]  */
  examStdInspectionResults: Array<ExamStdInspectionResult>;
  /** [public]  */
  id: Scalars['ID'];
  /** [public]  */
  summaryComment: Scalars['String'];
  /** [public]  */
  summaryTitle: Scalars['String'];
  /** [public]  */
  summaryType: ExamStdResultSummary;
  /** [public]  */
  user: User;
};

/** The connection type for ExamStdResult. */
export type ExamStdResultConnection = {
  __typename?: 'ExamStdResultConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ExamStdResultEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<ExamStdResult>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type ExamStdResultEdge = {
  __typename?: 'ExamStdResultEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<ExamStdResult>;
};

export enum ExamStdResultSummary {
  /** 陰性 */
  Negative = 'negative',
  /** 陽性 */
  Positive = 'positive',
}

/** [user]  */
export type ExamUserShippingAddress = {
  __typename?: 'ExamUserShippingAddress';
  /** [public] 住所 */
  address: Scalars['String'];
  /** [public]  */
  createdAt: Scalars['DateTime'];
  /** [public]  */
  entriedAt: Scalars['DateTime'];
  /** [public]  */
  id: Scalars['ID'];
  /** [public] 発送元をご自身からとするか否か */
  isShipFromSameAsDestination?: Maybe<Scalars['Boolean']>;
  /** [public] 郵便番号 */
  postcode: Scalars['String'];
  /** [public]  */
  updatedAt: Scalars['DateTime'];
};

export type ExamUserShippingAddressInput = {
  /** 住所 */
  address?: Maybe<Scalars['String']>;
  /** 発送元をご自身からとするか否か */
  isShipFromSameAsDestination: Scalars['Boolean'];
  /** 郵便番号 */
  postcode?: Maybe<Scalars['String']>;
};

/** [public]  */
export type Examination = {
  __typename?: 'Examination';
  /** [public]  */
  detail: TelemedicineMenuDetail;
  /** [public]  */
  id: Scalars['ID'];
  /** [public]  */
  name: Scalars['String'];
  /** [public]  */
  platformThumbnailUrl?: Maybe<Scalars['String']>;
  /** [public] オンライン検査注文用のURL */
  webviewUrl: Scalars['String'];
};

/** [public]  */
export type ExaminationResultItem = {
  __typename?: 'ExaminationResultItem';
  /** [public]  */
  examinedDate?: Maybe<Scalars['Date']>;
  /** [public]  */
  id: Scalars['ID'];
  /** [public]  */
  name: Scalars['String'];
  /** [public]  */
  webviewUrl: Scalars['String'];
};

/** [public]  */
export type Extension = {
  __typename?: 'Extension';
  /** [public] サービスエラーコード */
  appCode?: Maybe<Scalars['String']>;
  /** [public] GraphqlのVaridationエラーおよび、500エラーの場合コードを記載 */
  code?: Maybe<Scalars['String']>;
  /** [public] サービスドメイン - lincwell */
  domain?: Maybe<Scalars['String']>;
};

/** [public]  */
export type FcmToken = {
  __typename?: 'FcmToken';
  /** [public]  */
  createdAt: Scalars['DateTime'];
  /** [public]  */
  id: Scalars['ID'];
  /** [public]  */
  token: Scalars['String'];
  /** [public]  */
  updatedAt?: Maybe<Scalars['DateTime']>;
  /** [public]  */
  userId: Scalars['Int'];
};

export enum FemappAdviceViewpoint {
  /** ビューティ */
  Beauty = 'BEAUTY',
  /** ダイエット */
  Diet = 'DIET',
  /** 全般 */
  General = 'GENERAL',
  /** ココロ */
  Mental = 'MENTAL',
  /** カラダ */
  Physical = 'PHYSICAL',
}

export type FemappCycleInput = {
  periodLength: Scalars['Int'];
  periodStartOn?: Maybe<Scalars['Date']>;
};

export enum FemappDischargeAmount {
  /** 多い */
  Heavy = 'HEAVY',
  /** 少ない */
  Light = 'LIGHT',
  /** 普通 */
  Medium = 'MEDIUM',
}

export enum FemappDischargeCondition {
  /** どろっと */
  Creamy = 'CREAMY',
  /** ぼろぼろ */
  Crumbly = 'CRUMBLY',
  /** 鼻につく */
  Smelly = 'SMELLY',
  /** べたべた */
  Thick = 'THICK',
  /** さらさら */
  Thin = 'THIN',
}

export enum FemappFlowAmount {
  /** 多い */
  Heavy = 'HEAVY',
  /** 少ない */
  Light = 'LIGHT',
  /** 普通 */
  Medium = 'MEDIUM',
}

export enum FemappMentalCondition {
  /** 不安 */
  Anxious = 'ANXIOUS',
  /** 気が散る */
  Distracted = 'DISTRACTED',
  /** 情緒不安定 */
  Emotional = 'EMOTIONAL',
  /** やる気が出ない */
  Fatigue = 'FATIGUE',
  /** 元気 */
  Fine = 'FINE',
  /** ムラムラする */
  Horny = 'HORNY',
  /** イライラする */
  Irritated = 'IRRITATED',
  /** やる気が出る */
  Motivated = 'MOTIVATED',
  /** 悲しい */
  Sad = 'SAD',
  /** 死にたくなる */
  WantToDie = 'WANT_TO_DIE',
}

export type FemappMentalConditionInput = {
  name?: Maybe<FemappMentalCondition>;
};

export type FemappNoteInput = {
  bodyTemperature?: Maybe<Scalars['Float']>;
  date?: Maybe<Scalars['Date']>;
  dischargeAmount?: Maybe<FemappDischargeAmount>;
  dischargeCondition?: Maybe<FemappDischargeCondition>;
  flowAmount?: Maybe<FemappFlowAmount>;
  memo?: Maybe<Scalars['String']>;
  mentalConditions?: Maybe<Array<FemappMentalConditionInput>>;
  physicalConditions?: Maybe<Array<FemappPhysicalConditionInput>>;
  pillIntake?: Maybe<FemappPillIntake>;
  sexualActivity?: Maybe<FemappSexualActivity>;
};

export enum FemappPhaseStage {
  /** 初期 */
  Early = 'EARLY',
  /** 後期 */
  Late = 'LATE',
  /** 中期 */
  Middle = 'MIDDLE',
  /** 遅れ初期 */
  Terminal = 'TERMINAL',
  /** 未定 */
  Unknown = 'UNKNOWN',
}

export enum FemappPhaseStatus {
  /** 卵胞期 */
  Follicular = 'FOLLICULAR',
  /** 生理が遅れている */
  Late = 'LATE',
  /** 黄体期 */
  Luteal = 'LUTEAL',
  /** 月経期 */
  Menstrual = 'MENSTRUAL',
  /** 排卵期 */
  Ovulation = 'OVULATION',
  /** 未定 */
  Unknown = 'UNKNOWN',
}

export enum FemappPhysicalCondition {
  /** 貧血気味 */
  Anemic = 'ANEMIC',
  /** 腰痛 */
  Backache = 'BACKACHE',
  /** 胸が痛い */
  BleastPainful = 'BLEAST_PAINFUL',
  /** 胸が張る */
  BleastSwelling = 'BLEAST_SWELLING',
  /** 不正出血 */
  Bleeding = 'BLEEDING',
  /** お腹が張る */
  Bloated = 'BLOATED',
  /** 息切れ */
  Breathlessness = 'BREATHLESSNESS',
  /** 便秘 */
  Constipation = 'CONSTIPATION',
  /** 下痢 */
  Diarrhea = 'DIARRHEA',
  /** 目眩 */
  Dizzy = 'DIZZY',
  /** だるい */
  Dull = 'DULL',
  /** 脱毛 */
  Epilation = 'EPILATION',
  /** 調子が良い */
  Fine = 'FINE',
  /** 頭痛 */
  Headache = 'HEADACHE',
  /** 火照っている */
  Hot = 'HOT',
  /** 黄疸 */
  Jaundice = 'JAUNDICE',
  /** 吐き気 */
  Nauseous = 'NAUSEOUS',
  /** 食欲不振 */
  NoAppetite = 'NO_APPETITE',
  /** その他 */
  Others = 'OTHERS',
  /** 食べ過ぎる */
  Overeating = 'OVEREATING',
  /** ドキドキする */
  Palpitating = 'PALPITATING',
  /** 色素沈着 */
  Pigmentation = 'PIGMENTATION',
  /** 発疹 */
  Rash = 'RASH',
  /** 肌荒れ */
  RoughSkin = 'ROUGH_SKIN',
  /** 眠れない */
  Sleepless = 'SLEEPLESS',
  /** 眠い */
  Sleepy = 'SLEEPY',
  /** 胃が痛い */
  Stomachache = 'STOMACHACHE',
  /** むくむ */
  Swelling = 'SWELLING',
  /** のどが乾く */
  Thirsty = 'THIRSTY',
  /** 視力障害 */
  VisualProblem = 'VISUAL_PROBLEM',
}

export type FemappPhysicalConditionInput = {
  name?: Maybe<FemappPhysicalCondition>;
};

export enum FemappPillIntake {
  /** 服薬した */
  Intaked = 'INTAKED',
}

export enum FemappSexualActivity {
  /** した（避妊なし） */
  SexWithoutContraception = 'SEX_WITHOUT_CONTRACEPTION',
  /** した（避妊あり） */
  SexWithContraception = 'SEX_WITH_CONTRACEPTION',
}

/** [public]  */
export type FemappUser = Node & {
  __typename?: 'FemappUser';
  /** [public]  */
  behaviors: Array<UserBehavior>;
  /** [public]  */
  birthday?: Maybe<Scalars['Date']>;
  /** [public]  */
  cycleState: CycleState;
  /** [public]  */
  displayedId?: Maybe<Scalars['String']>;
  /** [public]  */
  email?: Maybe<Scalars['String']>;
  /** [public]  */
  hasAcceptedAccountLinking: Scalars['Boolean'];
  /** [public]  */
  hasClinicforUser: Scalars['Boolean'];
  /** [public]  */
  hasEmotionalReminderMessage: Scalars['Boolean'];
  /** [public]  */
  hasPillNotification: Scalars['Boolean'];
  /** [public]  */
  hasPillPlugin: Scalars['Boolean'];
  /** [public]  */
  hasPillSheetImage: Scalars['Boolean'];
  /** [public]  */
  hasPlaceboPeriodNotification: Scalars['Boolean'];
  /** [public]  */
  hasReservationsFromLineMiniapp: Scalars['Boolean'];
  /** [public]  */
  id: Scalars['ID'];
  /** [public] Menstrual note list which includes only up to 400 notes */
  notes: Array<Note>;
  /** [public]  */
  pill?: Maybe<Pill>;
  /** [public]  */
  pillClinic?: Maybe<PillClinic>;
  /** [public]  */
  pillRemindedAt?: Maybe<Scalars['Time']>;
  /** [public]  */
  regularCycleLength?: Maybe<Scalars['Int']>;
  /** [public]  */
  regularPeriodLength?: Maybe<Scalars['Int']>;
  /** [public]  */
  sheetStartDate?: Maybe<Scalars['Date']>;
  /** [public]  */
  snoozeSettings?: Maybe<Scalars['JSON']>;
};

/** [public]  */
export type FemappUserNotesArgs = {
  dateFrom: Scalars['Date'];
  dateTo: Scalars['Date'];
};

export enum FemappUserBehavior {
  /** 初回カレンダー編集 */
  EditCalendarInitially = 'EDIT_CALENDAR_INITIALLY',
  /** ピル効果自覚無し */
  FeelNoEffectOfPill = 'FEEL_NO_EFFECT_OF_PILL',
  /** サマリー閲覧 */
  ReadSummary = 'READ_SUMMARY',
  /** シート数登録 */
  RegisterSheetNumber = 'REGISTER_SHEET_NUMBER',
  /** ピル継続困難 */
  WantToNotContinuePill = 'WANT_TO_NOT_CONTINUE_PILL',
}

export type FemappUserBehaviorInput = {
  behaviorType?: Maybe<FemappUserBehavior>;
  datetime?: Maybe<Scalars['DateTime']>;
};

export type FemappUserInput = {
  birthday?: Maybe<Scalars['Date']>;
  email?: Maybe<Scalars['String']>;
  hasAcceptedAccountLinking?: Maybe<Scalars['Boolean']>;
  hasEmotionalReminderMessage?: Maybe<Scalars['Boolean']>;
  hasPillNotification?: Maybe<Scalars['Boolean']>;
  hasPillPlugin?: Maybe<Scalars['Boolean']>;
  hasPillSheetImage?: Maybe<Scalars['Boolean']>;
  hasPlaceboPeriodNotification?: Maybe<Scalars['Boolean']>;
  pillClinicId?: Maybe<Scalars['ID']>;
  pillId?: Maybe<Scalars['ID']>;
  pillRemindedAt?: Maybe<Scalars['Time']>;
  regularCycleLength?: Maybe<Scalars['Int']>;
  regularPeriodLength?: Maybe<Scalars['Int']>;
  sheetStartDate?: Maybe<Scalars['Date']>;
  snoozeSettings?: Maybe<Scalars['JSON']>;
};

/** [public]  */
export type GeneralInsight = {
  __typename?: 'GeneralInsight';
  /** [public]  */
  description: Scalars['String'];
};

/** [public]  */
export type GraphqlError = {
  __typename?: 'GraphqlError';
  /** [public]  */
  extensions: Extension;
  /** [public]  */
  message: Scalars['String'];
};

/** [public]  */
export type HealthcareTopic = {
  __typename?: 'HealthcareTopic';
  /** [public]  */
  id: Scalars['ID'];
  /** [public]  */
  mediaName: Scalars['String'];
  /** [public]  */
  thumbnailUrl?: Maybe<Scalars['String']>;
  /** [public]  */
  title: Scalars['String'];
  /** [public]  */
  webviewUrl: Scalars['String'];
};

/** The connection type for HealthcareTopic. */
export type HealthcareTopicConnection = {
  __typename?: 'HealthcareTopicConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<HealthcareTopicEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<HealthcareTopic>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type HealthcareTopicEdge = {
  __typename?: 'HealthcareTopicEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<HealthcareTopic>;
};

/** [public]  */
export type Insight = {
  __typename?: 'Insight';
  /** [public]  */
  advices: Array<Advice>;
  /** [public]  */
  articles: Array<Article>;
  /** [public]  */
  stage: FemappPhaseStage;
  /** [public]  */
  status: FemappPhaseStatus;
};

/** [public]  */
export type InsuranceCard = {
  __typename?: 'InsuranceCard';
  /** [public]  */
  createdAt: Scalars['DateTime'];
  /** [public]  */
  id: Scalars['ID'];
  /** [public]  */
  largeImageUrl?: Maybe<Scalars['String']>;
  /** [public]  */
  updatedAt?: Maybe<Scalars['DateTime']>;
  /** [public]  */
  userId: Scalars['Int'];
};

/** [public]  */
export type InterviewAnswer = {
  __typename?: 'InterviewAnswer';
  /** [public]  */
  googleformUrl: Scalars['String'];
  /** [public]  */
  id: Scalars['ID'];
  /** [public]  */
  interviewType: Scalars['String'];
  /** [public]  */
  interviewVersion: Scalars['Int'];
  /** [public]  */
  result: Scalars['String'];
};

/** [public]  */
export type MedicalCheckupAdditionalInterviewAnswer = Node & {
  __typename?: 'MedicalCheckupAdditionalInterviewAnswer';
  /** [public]  */
  createdAt: Scalars['Date'];
  /** [public] 希望する健康診断の内容 */
  examContent?: Maybe<MedicalCheckupExamContent>;
  /** [public] 希望する健康診断の内容（その他） */
  examContentText?: Maybe<Scalars['String']>;
  /** [public] 身長 */
  height: Scalars['Float'];
  /** [public]  */
  id: Scalars['ID'];
  /** [public] 指定書式の有無 */
  medicalCertificateFormat: MedicalCheckupMedicalCertificateFormat;
  /** [public] 希望するオプションメニュー */
  optionalMenu?: Maybe<Array<MedicalCheckupOptionalMenu>>;
  /** [public] 希望するオプションメニュー（その他） */
  optionalMenuText?: Maybe<Scalars['String']>;
  /** [public] 健康診断の目的 */
  purposeOfExam: MedicalCheckupPurposeOfExam;
  /** [public] 健康診断の目的（その他） */
  purposeOfExamText?: Maybe<Scalars['String']>;
  /** [public] 診断書の受け渡し方法 */
  resultDeliveryMethod: MedicalCheckupResultDeliveryMethod;
  /** [public]  */
  updatedAt: Scalars['Date'];
  /** [public] 体重 */
  weight: Scalars['Float'];
};

export type MedicalCheckupAdditionalInterviewAnswerInput = {
  /** 希望する健康診断の内容 */
  examContent: MedicalCheckupExamContent;
  /** 希望する健康診断の内容（その他） */
  examContentText?: Maybe<Scalars['String']>;
  /** 身長 */
  height: Scalars['Float'];
  /** 指定書式の有無 */
  medicalCertificateFormat: MedicalCheckupMedicalCertificateFormat;
  /** 希望するオプションメニュー */
  optionalMenu?: Maybe<Array<MedicalCheckupOptionalMenu>>;
  /** 希望するオプションメニュー（その他） */
  optionalMenuText?: Maybe<Scalars['String']>;
  /** 健康診断の目的 */
  purposeOfExam: MedicalCheckupPurposeOfExam;
  /** 健康診断の目的（その他） */
  purposeOfExamText?: Maybe<Scalars['String']>;
  /** 予約ID */
  reservationId: Scalars['ID'];
  /** 診断書の受け渡し方法 */
  resultDeliveryMethod: MedicalCheckupResultDeliveryMethod;
  /** 体重 */
  weight: Scalars['Float'];
};

export enum MedicalCheckupExamContent {
  /** 医師、看護師と相談して決めたい */
  DecideWithDoctorNurse = 'decide_with_doctor_nurse',
  /** 雇入健診 */
  EmploymentCheckup = 'employment_checkup',
  /** フルコース健診 */
  FullCourseCheckup = 'full_course_checkup',
  /** 団信②（採血あり） */
  GroupLifeInsuranceWithBloodExam = 'group_life_insurance_with_blood_exam',
  /** 団信①（採血なし） */
  GroupLifeInsuranceWithoutBloodExam = 'group_life_insurance_without_blood_exam',
  /** 生活予防健診 */
  LifestylePreventionCheckup = 'lifestyle_prevention_checkup',
  /** 深夜業健診 */
  NightShiftCheckup = 'night_shift_checkup',
  /** その他 */
  Others = 'others',
  /** 海外渡航健診 */
  OverseasTravelCheckup = 'overseas_travel_checkup',
  /** 定期検診①（採血あり） */
  RegularCheckupWithBloodExam = 'regular_checkup_with_blood_exam',
  /** 定期検診②（採血なし） */
  RegularCheckupWithoutBloodExam = 'regular_checkup_without_blood_exam',
  /** 簡易健診（美容師等） */
  SimpleCheckup = 'simple_checkup',
  /** 若年健診 */
  YoungAgeCheckup = 'young_age_checkup',
}

export enum MedicalCheckupMedicalCertificateFormat {
  /** 指定書式あり */
  SpecifiedFormat = 'specified_format',
  /** 指定項目あり */
  SpecifiedItems = 'specified_items',
  /** 指定なし */
  Unspecified = 'unspecified',
}

export enum MedicalCheckupOptionalMenu {
  /** アレルギー検査 */
  AllergyExam = 'allergy_exam',
  /** 血液型検査 */
  BloodTypeExam = 'blood_type_exam',
  /** 医師、看護師と相談して決めたい */
  DecideWithDoctorNurse = 'decide_with_doctor_nurse',
  /** 便潜血検査 */
  FecalOccultBloodExam = 'fecal_occult_blood_exam',
  /** 女性ホルモン（卵巣予備機能）検査 */
  FemaleHormoneExam = 'female_hormone_exam',
  /** 心不全検査 */
  HeartFailureExam = 'heart_failure_exam',
  /** ピロリ菌検査 */
  HelicobacterPyloriExam = 'helicobacter_pylori_exam',
  /** 肝炎ウイルス検査 */
  HepatitisVirusExam = 'hepatitis_virus_exam',
  /** 生活習慣病検査 */
  LifestyleDiseaseExam = 'lifestyle_disease_exam',
  /** その他 */
  Others = 'others',
  /** 睡眠時無呼吸検査 */
  SleepApneaExam = 'sleep_apnea_exam',
  /** 甲状腺ホルモン検査 */
  ThyroidHormoneExam = 'thyroid_hormone_exam',
  /** 腫瘍マーカー6種検査 */
  TumorMarker_6TypesExam = 'tumor_marker_6_types_exam',
  /** 腫瘍マーカー16種+胃がんリスク分類検査 */
  TumorMarker_16TypesAndStomachCancerRiskExam = 'tumor_marker_16_types_and_stomach_cancer_risk_exam',
  /** 尿沈渣 */
  UrinarySediment = 'urinary_sediment',
}

export enum MedicalCheckupPurposeOfExam {
  /** がん検診のため */
  CancerScreening = 'cancer_screening',
  /** 団体信用生命保険に加入するため */
  GroupLifeInsurance = 'group_life_insurance',
  /** その他 */
  Others = 'others',
  /** 入社時の健康診断 */
  PreEmploymentCheckup = 'pre_employment_checkup',
  /** 入学時の健康診断 */
  PreEntranceCheckup = 'pre_entrance_checkup',
  /** 定期健康診断 */
  RegularCheckup = 'regular_checkup',
}

export enum MedicalCheckupResultDeliveryMethod {
  /** メール（PDF） */
  Email = 'email',
  /** ご来院による手渡し */
  HandOverAtClinic = 'hand_over_at_clinic',
  /** 郵送 */
  MailDelivery = 'mail_delivery',
}

export enum MedicalHistory {
  /** 喘息 */
  Asthma = 'asthma',
  /** アトピー */
  Atopic = 'atopic',
  /** 糖尿病 */
  Diabetes = 'diabetes',
  /** 緑内障 */
  Glaucoma = 'glaucoma',
  /** 高脂血症 */
  Hyperlipidemia = 'hyperlipidemia',
  /** 高血圧 */
  Hypertension = 'hypertension',
  /** 肝疾患 */
  LiverDisease = 'liver_disease',
  /** 心筋梗塞 */
  MyocardialInfarction = 'myocardial_infarction',
  /** 花粉症 */
  PollenAllergy = 'pollen_allergy',
  /** 前立腺肥大 */
  ProstateEnlargement = 'prostate_enlargement',
  /** 腎疾患 */
  RenalDisease = 'renal_disease',
  /** 結核 */
  Tuberculosis = 'tuberculosis',
}

/** [public]  */
export type MentalCondition = {
  __typename?: 'MentalCondition';
  /** [public]  */
  name: FemappMentalCondition;
};

/** [public]  */
export type Mutation = {
  __typename?: 'Mutation';
  /** [clinic] 新規予約 */
  clinicsCreateReservation?: Maybe<ClinicCreateReservationMutationPayload>;
  /** [clinic] ユーザー登録 */
  clinicsCreateUser?: Maybe<ClinicCreateUserMutationPayload>;
  /** [clinic] 問診の回答を登録・更新 */
  clinicsUpdateInterviewAnswer?: Maybe<ClinicUpdateInterviewAnswerMutationPayload>;
  /** [clinic] UpdateReservationMutation */
  clinicsUpdateReservation?: Maybe<ClinicUpdateReservationMutationPayload>;
  /** [clinic] 既往歴の更新 */
  clinicsUpdateSelfCheckedMedicalInformation?: Maybe<ClinicUpdateSelfCheckedMedicalInformationMutationPayload>;
  /** [clinic] UpdateUserMutation */
  clinicsUpdateUser?: Maybe<ClinicUpdateUserMutationPayload>;
  /** [femapp] Create cycle */
  femappCreateCycle?: Maybe<CreateCycleMutationPayload>;
  /** [femapp] Create note */
  femappCreateNote?: Maybe<CreateNoteMutationPayload>;
  /** [femapp] Create user behavior */
  femappCreateUserBehavior?: Maybe<CreateUserBehaviorMutationPayload>;
  /** [femapp] Create femapp user event */
  femappCreateUserEvent?: Maybe<CreateUserEventMutationPayload>;
  /** [femapp] Create femapp user survey answer */
  femappCreateUserSurveyAnswer?: Maybe<CreateUserSurveyAnswerMutationPayload>;
  /** [femapp] Delete cycle */
  femappDeleteCycle?: Maybe<DeleteCycleMutationPayload>;
  /** [femapp] Delete note */
  femappDeleteNote?: Maybe<DeleteNoteMutationPayload>;
  /** [public] Sign up for femapp v2 */
  femappSignUpForV2?: Maybe<SignUpForV2MutationPayload>;
  /** [femapp] Update all cycles */
  femappUpdateAllCycles?: Maybe<UpdateAllCyclesMutationPayload>;
  /** [femapp] Update cycle */
  femappUpdateCycle?: Maybe<UpdateCycleMutationPayload>;
  /** [femapp] Update note */
  femappUpdateNote?: Maybe<UpdateNoteMutationPayload>;
  /** [femapp] Update user */
  femappUpdateUser?: Maybe<UpdateUserMutationPayload>;
  /** [femapp] Update user behavior */
  femappUpdateUserBehavior?: Maybe<UpdateUserBehaviorMutationPayload>;
  /** [user] Amazon Payページで作成したCheckout Sessionを紐付ける */
  patientsAssociateAmazonPayCheckoutSession?: Maybe<AssociateAmazonPayCheckoutSessionMutationPayload>;
  /** [user] 定期配送の中止 */
  patientsCancelEcSubscriptionOrder?: Maybe<CancelEcSubscriptionOrderMutationPayload>;
  /** [user] 予約のキャンセル */
  patientsCancelReservation?: Maybe<CancelReservationMutationPayload>;
  /** [user] AmazonPayにおける支払い方法の追加 */
  patientsCreateAmazonPayPaymentMethod?: Maybe<CreateAmazonPayPaymentMethodMutationPayload>;
  /** [user] 美容皮膚科用の診察希望日次フォーム */
  patientsCreateBeautyCounselingReservation?: Maybe<CreateBeautyCounselingReservationMutationPayload>;
  /** [user] オフライン美容サブスクの決済 */
  patientsCreateBeautySubscription?: Maybe<CreateBeautySubscriptionMutationPayload>;
  /**
   * [user] 事前問診回答を記録する
   * @deprecated This API is not to be used.
   */
  patientsCreateDiagnosticAnswers?: Maybe<CreateDiagnosticAnswersMutationPayload>;
  /** [user] オンライン診療の決済と、処方されたお薬の購入 */
  patientsCreateEcOrder?: Maybe<CreateEcOrderMutationPayload>;
  /** [user] オンライン検査の決済と、キットの購入 */
  patientsCreateExamOrder?: Maybe<CreateExamOrderMutationPayload>;
  /**
   * [user] fcm token 登録
   * @deprecated Fcmによる通知は行わないため
   */
  patientsCreateFcmToken?: Maybe<CreateFcmTokenMutationPayload>;
  /** [user] 画像をアップロードするための Mutation */
  patientsCreatePatientImage?: Maybe<CreatePatientImageMutationPayload>;
  /** [user] 新規カードの追加 */
  patientsCreatePaymentMethod?: Maybe<CreatePaymentMethodMutationPayload>;
  /** [user] クイック問診回答結果の作成 */
  patientsCreateQuickInterview?: Maybe<CreateQuickInterviewMutationPayload>;
  /** [user] 新規予約 */
  patientsCreateReservation?: Maybe<CreateReservationMutationPayload>;
  /** [user] S3アップロードのための署名付きURLの発行 */
  patientsCreateS3PresignedUrl?: Maybe<CreateS3PresignedUrlMutationPayload>;
  /** [user] オンライン診療の新規予約 */
  patientsCreateTelemedicineReservation?: Maybe<CreateTelemedicineReservationMutationPayload>;
  /** [user] 紹介コードの登録 */
  patientsCreateUserInvitedCode?: Maybe<CreateUserInvitedCodeMutationPayload>;
  /** [user] カードの削除 */
  patientsDeletePaymentMethod?: Maybe<DeletePaymentMethodMutationPayload>;
  /** [user] TelemedicineKarteImageの削除 */
  patientsDeleteTelemedicineKarteImage?: Maybe<DeleteTelemedicineKarteImageMutationPayload>;
  /** [user] Amazon PayページでCheckout Sessionを完了するための準備を行う */
  patientsPrepareToCompleteAmazonPayCheckoutSession?: Maybe<PrepareToCompleteAmazonPayCheckoutSessionMutationPayload>;
  /** [user] Amazon PayページでCheckout Sessionを作成するための準備を行う */
  patientsPrepareToCreateAmazonPayCheckoutSession?: Maybe<PrepareToCreateAmazonPayCheckoutSessionMutationPayload>;
  /** [user] Amazon PayページでCheckout Sessionを更新するための準備を行う */
  patientsPrepareToUpdateAmazonPayCheckoutSession?: Maybe<PrepareToUpdateAmazonPayCheckoutSessionMutationPayload>;
  /** [user] 事前問診の保存 */
  patientsPriorInterviewsSaveTelemedicineInterview?: Maybe<SavePillTelemedicineInterviewMutationPayload>;
  /** [user] のりかえ割クーポンの送信 */
  patientsPriorInterviewsSendChangingClinicCoupon?: Maybe<SendChangingClinicCouponMutationPayload>;
  /** [user] クーポンの送信 */
  patientsPriorInterviewsSendCouponMail?: Maybe<SendCouponMailMutationPayload>;
  /** [public] Cognito側のパスワードリセットがエラーになるケースにて利用する。 */
  patientsResendConfirmationInstructions?: Maybe<ResendConfirmationInstructionsMutationPayload>;
  /** [user] 次回発送日の変更 */
  patientsSkipEcSubscriptionOrder?: Maybe<SkipEcSubscriptionOrderMutationPayload>;
  /** [user] 定期配送の住所や決済手段の変更 */
  patientsUpdateEcSubscriptionOrder?: Maybe<UpdateEcSubscriptionOrderMutationPayload>;
  /** [user] メールアドレスの更新 */
  patientsUpdateEmail?: Maybe<UpdateEmailMutationPayload>;
  /** [user] 予約更新 */
  patientsUpdateReservation?: Maybe<UpdateReservationMutationPayload>;
  /** [user] 医学的基本情報の更新 */
  patientsUpdateSelfCheckedMedicalInformation?: Maybe<UpdateSelfCheckedMedicalInformationMutationPayload>;
  /** [user] 受け取るお知らせの更新 */
  patientsUpdateSubscribedMailContent?: Maybe<UpdateSubscribedMailContentMutationPayload>;
  /** [user] 新しい事前問診の更新 */
  patientsUpdateTelemedicineInquiry?: Maybe<UpdateTelemedicineInquiryMutationPayload>;
  /** [user] 事前問診の更新 */
  patientsUpdateTelemedicineInterview?: Maybe<UpdateTelemedicineInterviewMutationPayload>;
  /** [user] ユーザー情報の更新 */
  patientsUpdateUser?: Maybe<UpdatePatientUserMutationPayload>;
  /** [user] ユーザー情報の更新 */
  patientsUpdateUserInformation?: Maybe<UpdateUserInformationMutationPayload>;
  /** [user] 美容皮膚追加問診のUpsert */
  patientsUpsertCosmeticAdditionalInterviewAnswer?: Maybe<UpsertCosmeticAdditionalInterviewAnswerMutationPayload>;
  /** [user] 健康診断追加問診のUpsert */
  patientsUpsertMedicalCheckupAdditionalInterviewAnswer?: Maybe<UpsertMedicalCheckupAdditionalInterviewAnswerMutationPayload>;
  /** [user] OIDCでのログイン後に、OpenIdConnectionレコードを作成する */
  patientsUpsertOpenIdConnection?: Maybe<UpsertOpenIdConnectionMutationPayload>;
  /** [user] 自費コロナ検査の追加問診フォーム */
  patientsUpsertPcrAbroadInterview?: Maybe<UpsertPcrAbroadInterviewMutationPayload>;
  /** [user] 退会処理 */
  patientsWithdrawalRequests?: Maybe<WithdrawalRequestsMutationPayload>;
  /** [public] メールアドレスの確認 */
  platformConfirmEmail?: Maybe<ConfirmEmailMutationPayload>;
  /** [public] PFユーザーの新規作成。すでに存在している場合は該当の端末のDevice IDを返却。 */
  platformCreateOrFetchPlatformUser?: Maybe<CreateOrFetchPlatformUserMutationPayload>;
  /** [user] お薬リマインダーの新規作成 */
  platformCreateReminder?: Maybe<CreateReminderMutationPayload>;
  /** [user] お薬リマインダーの削除 */
  platformDeleteReminder?: Maybe<DeleteReminderMutationPayload>;
  /** [user] お薬リマインダーの再開 */
  platformRestartReminder?: Maybe<RestartReminderMutationPayload>;
  /** [user] 初回ログイン時のCFユーザー紐付け/PFユーザーの情報マージ (プッシュ受信設定のみ) */
  platformSetupPlatformUser?: Maybe<SetupPlatformUserMutationPayload>;
  /** [user] お薬リマインダーの停止 */
  platformStopReminder?: Maybe<StopReminderMutationPayload>;
  /** [user] お薬リマインダーに対する服薬処理 */
  platformTakeDrug?: Maybe<TakeDrugMutationPayload>;
  /** [public] プッシュ通知設定の更新 */
  platformUpdatePushSetting?: Maybe<UpdatePushSettingMutationPayload>;
  /** [user] お薬リマインダーの更新 */
  platformUpdateReminder?: Maybe<UpdateReminderMutationPayload>;
  /** [user] AGA事前問診の保存 */
  saveAgaTelemedicineInterview?: Maybe<SaveAgaTelemedicineInterviewMutationPayload>;
  /**
   * [user] AGAのりかえ割クーポンの送信
   * @deprecated SendCouponMailMutationにまとめた為
   */
  sendAgaChangeClinicCoupon?: Maybe<SendAgaChangeClinicCouponMutationPayload>;
  /**
   * [user] AGA予防割クーポンの送信
   * @deprecated SendCouponMailMutationにまとめた為
   */
  sendAgaHairKeepPlanCoupon?: Maybe<SendAgaHairKeepPlanCouponMutationPayload>;
};

/** [public]  */
export type MutationClinicsCreateReservationArgs = {
  input: ClinicCreateReservationMutationInput;
};

/** [public]  */
export type MutationClinicsCreateUserArgs = {
  input: ClinicCreateUserMutationInput;
};

/** [public]  */
export type MutationClinicsUpdateInterviewAnswerArgs = {
  input: ClinicUpdateInterviewAnswerMutationInput;
};

/** [public]  */
export type MutationClinicsUpdateReservationArgs = {
  input: ClinicUpdateReservationMutationInput;
};

/** [public]  */
export type MutationClinicsUpdateSelfCheckedMedicalInformationArgs = {
  input: ClinicUpdateSelfCheckedMedicalInformationMutationInput;
};

/** [public]  */
export type MutationClinicsUpdateUserArgs = {
  input: ClinicUpdateUserMutationInput;
};

/** [public]  */
export type MutationFemappCreateCycleArgs = {
  input: CreateCycleMutationInput;
};

/** [public]  */
export type MutationFemappCreateNoteArgs = {
  input: CreateNoteMutationInput;
};

/** [public]  */
export type MutationFemappCreateUserBehaviorArgs = {
  input: CreateUserBehaviorMutationInput;
};

/** [public]  */
export type MutationFemappCreateUserEventArgs = {
  input: CreateUserEventMutationInput;
};

/** [public]  */
export type MutationFemappCreateUserSurveyAnswerArgs = {
  input: CreateUserSurveyAnswerMutationInput;
};

/** [public]  */
export type MutationFemappDeleteCycleArgs = {
  input: DeleteCycleMutationInput;
};

/** [public]  */
export type MutationFemappDeleteNoteArgs = {
  input: DeleteNoteMutationInput;
};

/** [public]  */
export type MutationFemappSignUpForV2Args = {
  input: SignUpForV2MutationInput;
};

/** [public]  */
export type MutationFemappUpdateAllCyclesArgs = {
  input: UpdateAllCyclesMutationInput;
};

/** [public]  */
export type MutationFemappUpdateCycleArgs = {
  input: UpdateCycleMutationInput;
};

/** [public]  */
export type MutationFemappUpdateNoteArgs = {
  input: UpdateNoteMutationInput;
};

/** [public]  */
export type MutationFemappUpdateUserArgs = {
  input: UpdateUserMutationInput;
};

/** [public]  */
export type MutationFemappUpdateUserBehaviorArgs = {
  input: UpdateUserBehaviorMutationInput;
};

/** [public]  */
export type MutationPatientsAssociateAmazonPayCheckoutSessionArgs = {
  input: AssociateAmazonPayCheckoutSessionMutationInput;
};

/** [public]  */
export type MutationPatientsCancelEcSubscriptionOrderArgs = {
  input: CancelEcSubscriptionOrderMutationInput;
};

/** [public]  */
export type MutationPatientsCancelReservationArgs = {
  input: CancelReservationMutationInput;
};

/** [public]  */
export type MutationPatientsCreateAmazonPayPaymentMethodArgs = {
  input: CreateAmazonPayPaymentMethodMutationInput;
};

/** [public]  */
export type MutationPatientsCreateBeautyCounselingReservationArgs = {
  input: CreateBeautyCounselingReservationMutationInput;
};

/** [public]  */
export type MutationPatientsCreateBeautySubscriptionArgs = {
  input: CreateBeautySubscriptionMutationInput;
};

/** [public]  */
export type MutationPatientsCreateDiagnosticAnswersArgs = {
  input: CreateDiagnosticAnswersMutationInput;
};

/** [public]  */
export type MutationPatientsCreateEcOrderArgs = {
  input: CreateEcOrderMutationInput;
};

/** [public]  */
export type MutationPatientsCreateExamOrderArgs = {
  input: CreateExamOrderMutationInput;
};

/** [public]  */
export type MutationPatientsCreateFcmTokenArgs = {
  input: CreateFcmTokenMutationInput;
};

/** [public]  */
export type MutationPatientsCreatePatientImageArgs = {
  input: CreatePatientImageMutationInput;
};

/** [public]  */
export type MutationPatientsCreatePaymentMethodArgs = {
  input: CreatePaymentMethodMutationInput;
};

/** [public]  */
export type MutationPatientsCreateQuickInterviewArgs = {
  input: CreateQuickInterviewMutationInput;
};

/** [public]  */
export type MutationPatientsCreateReservationArgs = {
  input: CreateReservationMutationInput;
};

/** [public]  */
export type MutationPatientsCreateS3PresignedUrlArgs = {
  input: CreateS3PresignedUrlMutationInput;
};

/** [public]  */
export type MutationPatientsCreateTelemedicineReservationArgs = {
  input: CreateTelemedicineReservationMutationInput;
};

/** [public]  */
export type MutationPatientsCreateUserInvitedCodeArgs = {
  input: CreateUserInvitedCodeMutationInput;
};

/** [public]  */
export type MutationPatientsDeletePaymentMethodArgs = {
  input: DeletePaymentMethodMutationInput;
};

/** [public]  */
export type MutationPatientsDeleteTelemedicineKarteImageArgs = {
  input: DeleteTelemedicineKarteImageMutationInput;
};

/** [public]  */
export type MutationPatientsPrepareToCompleteAmazonPayCheckoutSessionArgs = {
  input: PrepareToCompleteAmazonPayCheckoutSessionMutationInput;
};

/** [public]  */
export type MutationPatientsPrepareToCreateAmazonPayCheckoutSessionArgs = {
  input: PrepareToCreateAmazonPayCheckoutSessionMutationInput;
};

/** [public]  */
export type MutationPatientsPrepareToUpdateAmazonPayCheckoutSessionArgs = {
  input: PrepareToUpdateAmazonPayCheckoutSessionMutationInput;
};

/** [public]  */
export type MutationPatientsPriorInterviewsSaveTelemedicineInterviewArgs = {
  input: SavePillTelemedicineInterviewMutationInput;
};

/** [public]  */
export type MutationPatientsPriorInterviewsSendChangingClinicCouponArgs = {
  input: SendChangingClinicCouponMutationInput;
};

/** [public]  */
export type MutationPatientsPriorInterviewsSendCouponMailArgs = {
  input: SendCouponMailMutationInput;
};

/** [public]  */
export type MutationPatientsResendConfirmationInstructionsArgs = {
  input: ResendConfirmationInstructionsMutationInput;
};

/** [public]  */
export type MutationPatientsSkipEcSubscriptionOrderArgs = {
  input: SkipEcSubscriptionOrderMutationInput;
};

/** [public]  */
export type MutationPatientsUpdateEcSubscriptionOrderArgs = {
  input: UpdateEcSubscriptionOrderMutationInput;
};

/** [public]  */
export type MutationPatientsUpdateEmailArgs = {
  input: UpdateEmailMutationInput;
};

/** [public]  */
export type MutationPatientsUpdateReservationArgs = {
  input: UpdateReservationMutationInput;
};

/** [public]  */
export type MutationPatientsUpdateSelfCheckedMedicalInformationArgs = {
  input: UpdateSelfCheckedMedicalInformationMutationInput;
};

/** [public]  */
export type MutationPatientsUpdateSubscribedMailContentArgs = {
  input: UpdateSubscribedMailContentMutationInput;
};

/** [public]  */
export type MutationPatientsUpdateTelemedicineInquiryArgs = {
  input: UpdateTelemedicineInquiryMutationInput;
};

/** [public]  */
export type MutationPatientsUpdateTelemedicineInterviewArgs = {
  input: UpdateTelemedicineInterviewMutationInput;
};

/** [public]  */
export type MutationPatientsUpdateUserArgs = {
  input: UpdatePatientUserMutationInput;
};

/** [public]  */
export type MutationPatientsUpdateUserInformationArgs = {
  input: UpdateUserInformationMutationInput;
};

/** [public]  */
export type MutationPatientsUpsertCosmeticAdditionalInterviewAnswerArgs = {
  input: UpsertCosmeticAdditionalInterviewAnswerMutationInput;
};

/** [public]  */
export type MutationPatientsUpsertMedicalCheckupAdditionalInterviewAnswerArgs = {
  input: UpsertMedicalCheckupAdditionalInterviewAnswerMutationInput;
};

/** [public]  */
export type MutationPatientsUpsertOpenIdConnectionArgs = {
  input: UpsertOpenIdConnectionMutationInput;
};

/** [public]  */
export type MutationPatientsUpsertPcrAbroadInterviewArgs = {
  input: UpsertPcrAbroadInterviewMutationInput;
};

/** [public]  */
export type MutationPatientsWithdrawalRequestsArgs = {
  input: WithdrawalRequestsMutationInput;
};

/** [public]  */
export type MutationPlatformConfirmEmailArgs = {
  input: ConfirmEmailMutationInput;
};

/** [public]  */
export type MutationPlatformCreateOrFetchPlatformUserArgs = {
  input: CreateOrFetchPlatformUserMutationInput;
};

/** [public]  */
export type MutationPlatformCreateReminderArgs = {
  input: CreateReminderMutationInput;
};

/** [public]  */
export type MutationPlatformDeleteReminderArgs = {
  input: DeleteReminderMutationInput;
};

/** [public]  */
export type MutationPlatformRestartReminderArgs = {
  input: RestartReminderMutationInput;
};

/** [public]  */
export type MutationPlatformSetupPlatformUserArgs = {
  input: SetupPlatformUserMutationInput;
};

/** [public]  */
export type MutationPlatformStopReminderArgs = {
  input: StopReminderMutationInput;
};

/** [public]  */
export type MutationPlatformTakeDrugArgs = {
  input: TakeDrugMutationInput;
};

/** [public]  */
export type MutationPlatformUpdatePushSettingArgs = {
  input: UpdatePushSettingMutationInput;
};

/** [public]  */
export type MutationPlatformUpdateReminderArgs = {
  input: UpdateReminderMutationInput;
};

/** [public]  */
export type MutationSaveAgaTelemedicineInterviewArgs = {
  input: SaveAgaTelemedicineInterviewMutationInput;
};

/** [public]  */
export type MutationSendAgaChangeClinicCouponArgs = {
  input: SendAgaChangeClinicCouponMutationInput;
};

/** [public]  */
export type MutationSendAgaHairKeepPlanCouponArgs = {
  input: SendAgaHairKeepPlanCouponMutationInput;
};

export enum MynumberMedicalInformationConsent {
  /** 同意しない */
  No = 'no',
  /** 同意する */
  Yes = 'yes',
  /** 未回答 */
  Yet = 'yet',
}

/** An object with an ID. */
export type Node = {
  /** ID of the object. */
  id: Scalars['ID'];
};

/** [public]  */
export type Note = Node & {
  __typename?: 'Note';
  /** [public]  */
  bodyTemperature?: Maybe<Scalars['Float']>;
  /** [public]  */
  date: Scalars['Date'];
  /** [public]  */
  dischargeAmount?: Maybe<FemappDischargeAmount>;
  /** [public]  */
  dischargeCondition?: Maybe<FemappDischargeCondition>;
  /** [public]  */
  flowAmount?: Maybe<FemappFlowAmount>;
  /** [public]  */
  id: Scalars['ID'];
  /** [public]  */
  memo?: Maybe<Scalars['String']>;
  /** [public]  */
  mentalConditions: Array<MentalCondition>;
  /** [public]  */
  physicalConditions: Array<PhysicalCondition>;
  /** [public]  */
  pillIntake?: Maybe<FemappPillIntake>;
  /** [public]  */
  sexualActivity?: Maybe<FemappSexualActivity>;
};

/** [public]  */
export type Notification = {
  __typename?: 'Notification';
  /** [public]  */
  content?: Maybe<Scalars['String']>;
  /** [public]  */
  id: Scalars['ID'];
  /** [public]  */
  isImportant: Scalars['Boolean'];
  /** [public]  */
  notifiedDate: Scalars['Date'];
  /** [public]  */
  redirectTo: Scalars['String'];
  /** [public]  */
  shortTitle?: Maybe<Scalars['String']>;
  /** [public]  */
  title: Scalars['String'];
};

/** The connection type for Notification. */
export type NotificationConnection = {
  __typename?: 'NotificationConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<NotificationEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Notification>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type NotificationEdge = {
  __typename?: 'NotificationEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Notification>;
};

/** [public] 請求書兼領収書: https://www.orca.med.or.jp/receipt/tec/api/report_print/seikyusho.html */
export type OrcaInvoiceRecept = {
  __typename?: 'OrcaInvoiceRecept';
  /** [public] 今回請求額 */
  acMoney?: Maybe<Scalars['Int']>;
  /** [public] 手動入力の調整金額 */
  adMoney1?: Maybe<Scalars['String']>;
  /** [public] 手動入力の調整金額 */
  adMoney2?: Maybe<Scalars['String']>;
  /** [public] 保険分負担金額 */
  aiMoney?: Maybe<Scalars['Int']>;
  /** [public]  */
  caution?: Maybe<Scalars['Int']>;
  /** [public]  */
  createdAt: Scalars['DateTime'];
  /** [public]  */
  departmentName?: Maybe<Scalars['String']>;
  /** [public] 今回入金額 【後払い】決済反映前は0円 */
  icMoney?: Maybe<Scalars['Int']>;
  /** [public]  */
  id: Scalars['ID'];
  /** [public]  */
  insuranceName?: Maybe<Scalars['String']>;
  /** [public]  */
  insuranceRate?: Maybe<Scalars['String']>;
  /** [public]  */
  invoiceNumber?: Maybe<Scalars['String']>;
  /** [public]  */
  issuedDate?: Maybe<Scalars['String']>;
  /** [public]  */
  labelLsiTotalMoney?: Maybe<Scalars['String']>;
  /** [public] 未払い額 【後払い】決済反映前はそれまでの請求分が未払いとして加算される */
  lastAcMoney?: Maybe<Scalars['Int']>;
  /** [public]  */
  lsiTotalMoneyInAiMoney?: Maybe<Scalars['Int']>;
  /** [public]  */
  memo?: Maybe<Scalars['String']>;
  /** [public]  */
  numberDays?: Maybe<Scalars['String']>;
  /** [public]  */
  orcaInvoiceReceptEtcs: Array<OrcaInvoiceReceptEtc>;
  /** [public]  */
  orcaInvoiceReceptItems: Array<OrcaInvoiceReceptItem>;
  /**
   * [public]
   * @deprecated 内容が少ないためOrcaStatementの利用を推奨
   */
  orcaInvoiceReceptStatements: Array<OrcaInvoiceReceptStatement>;
  /** [public]  */
  orderClass?: Maybe<Scalars['String']>;
  /** [public]  */
  performDate?: Maybe<Scalars['String']>;
  /** [public]  */
  performEndDate?: Maybe<Scalars['String']>;
  /** [public]  */
  performMonth?: Maybe<Scalars['String']>;
  /** [public]  */
  periodClass?: Maybe<Scalars['String']>;
  /** [public]  */
  piSmoney?: Maybe<Scalars['String']>;
  /** [public]  */
  repirintSignFlg?: Maybe<Scalars['Int']>;
  /** [public]  */
  statementCaution?: Maybe<Scalars['String']>;
  /** [public] 消費税再掲 */
  taxInAcMoney?: Maybe<Scalars['Int']>;
  /** [public]  */
  taxInTotalMeMoney?: Maybe<Scalars['String']>;
  /** [public]  */
  taxInTotalOeEtcMoney?: Maybe<Scalars['String']>;
  /** [public] 合計請求金額(未払い額が加算される) */
  totalAcMoney?: Maybe<Scalars['Int']>;
  /** [public] 保険適応外合計金額 */
  totalMeMoney?: Maybe<Scalars['String']>;
  /** [public] その他自費合計金額 */
  totalOeEtcMoney?: Maybe<Scalars['String']>;
  /** [public]  */
  totalPoint?: Maybe<Scalars['Int']>;
  /** [public]  */
  updatedAt: Scalars['DateTime'];
  /** [public]  */
  yenFlg?: Maybe<Scalars['Int']>;
};

/** [public] 請求書兼領収書/その他自費 Oe_ETC: https://www.orca.med.or.jp/receipt/tec/api/report_print/seikyusho.html */
export type OrcaInvoiceReceptEtc = {
  __typename?: 'OrcaInvoiceReceptEtc';
  /** [public]  */
  id: Scalars['ID'];
  /** [public]  */
  money?: Maybe<Scalars['String']>;
  /** [public]  */
  name?: Maybe<Scalars['String']>;
};

/** [public] 請求書兼領収書/点数もしくは保険適応外金額情報 Me: https://www.orca.med.or.jp/receipt/tec/api/report_print/seikyusho.html */
export type OrcaInvoiceReceptItem = {
  __typename?: 'OrcaInvoiceReceptItem';
  /** [public]  */
  code?: Maybe<Scalars['String']>;
  /** [public]  */
  createdAt: Scalars['DateTime'];
  /** [public]  */
  id: Scalars['ID'];
  /** [public]  */
  money?: Maybe<Scalars['Int']>;
  /** [public]  */
  name?: Maybe<Scalars['String']>;
  /** [public]  */
  orcaInvoiceRecept: OrcaInvoiceRecept;
  /** [public]  */
  point?: Maybe<Scalars['Int']>;
  /** [public]  */
  updatedAt: Scalars['DateTime'];
};

/** [public]  */
export type OrcaInvoiceReceptStatement = {
  __typename?: 'OrcaInvoiceReceptStatement';
  /** [public]  */
  count?: Maybe<Scalars['String']>;
  /** [public]  */
  description?: Maybe<Scalars['String']>;
  /** [public]  */
  endFlg?: Maybe<Scalars['String']>;
  /** [public]  */
  groupHeadFlg?: Maybe<Scalars['Int']>;
  /** [public]  */
  groupNumber?: Maybe<Scalars['String']>;
  /** [public]  */
  id: Scalars['ID'];
  /** [public]  */
  medicalClassName?: Maybe<Scalars['String']>;
  /** [public]  */
  point?: Maybe<Scalars['String']>;
  /** [public]  */
  totalPoint?: Maybe<Scalars['String']>;
  /** [public]  */
  yenFlg?: Maybe<Scalars['String']>;
};

/** [public] お薬手帳一覧/処方情報 RP/薬剤情報: https://www.orca.med.or.jp/receipt/tec/api/report_print/okusuri_techo.html */
export type OrcaMedication = {
  __typename?: 'OrcaMedication';
  /** [public]  */
  caution?: Maybe<Scalars['String']>;
  /** [public]  */
  color?: Maybe<Scalars['String']>;
  /** [public]  */
  creymd?: Maybe<Scalars['String']>;
  /** [public]  */
  effect?: Maybe<Scalars['String']>;
  /** [public]  */
  makeCompany?: Maybe<Scalars['String']>;
  /** [public]  */
  mark?: Maybe<Scalars['String']>;
  /** [public]  */
  name?: Maybe<Scalars['String']>;
  /** [public]  */
  photo1Filename?: Maybe<Scalars['String']>;
  /** [public]  */
  photo2Filename?: Maybe<Scalars['String']>;
  /** [public]  */
  photo3Filename?: Maybe<Scalars['String']>;
  /** [public]  */
  rennum?: Maybe<Scalars['String']>;
  /** [public]  */
  saleCompany?: Maybe<Scalars['String']>;
  /** [public]  */
  shape?: Maybe<Scalars['String']>;
  /** [public]  */
  srycd?: Maybe<Scalars['String']>;
  /** [public]  */
  termid?: Maybe<Scalars['String']>;
  /** [public]  */
  yjcd?: Maybe<Scalars['String']>;
};

/** The connection type for OrcaMedication. */
export type OrcaMedicationConnection = {
  __typename?: 'OrcaMedicationConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<OrcaMedicationEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<OrcaMedication>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type OrcaMedicationEdge = {
  __typename?: 'OrcaMedicationEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<OrcaMedication>;
};

/** [public] お薬手帳一覧: https://www.orca.med.or.jp/receipt/tec/api/report_print/okusuri_techo.html */
export type OrcaNotebook = {
  __typename?: 'OrcaNotebook';
  /** [public]  */
  doctorCode?: Maybe<Scalars['String']>;
  /** [public]  */
  doctorName?: Maybe<Scalars['String']>;
  /** [public]  */
  id: Scalars['ID'];
  /** [public]  */
  memo?: Maybe<Scalars['String']>;
  /** [public] 処方情報 Rp */
  orcaNotebookPrescriptions: OrcaNotebookPrescriptionConnection;
  /** [public] 処方情報 Rp */
  prescriptions: Array<OrcaNotebookPrescription>;
  /** [public]  */
  qrId?: Maybe<Scalars['String']>;
};

/** [public] お薬手帳一覧: https://www.orca.med.or.jp/receipt/tec/api/report_print/okusuri_techo.html */
export type OrcaNotebookOrcaNotebookPrescriptionsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** [public]  */
export type OrcaNotebookMedication = {
  __typename?: 'OrcaNotebookMedication';
  /** [public]  */
  amount?: Maybe<Scalars['String']>;
  /** [public]  */
  code?: Maybe<Scalars['String']>;
  /** [public]  */
  id: Scalars['ID'];
  /** [public]  */
  name?: Maybe<Scalars['String']>;
  /** [public]  */
  unitName?: Maybe<Scalars['String']>;
};

/** The connection type for OrcaNotebookMedication. */
export type OrcaNotebookMedicationConnection = {
  __typename?: 'OrcaNotebookMedicationConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<OrcaNotebookMedicationEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<OrcaNotebookMedication>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type OrcaNotebookMedicationEdge = {
  __typename?: 'OrcaNotebookMedicationEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<OrcaNotebookMedication>;
};

/** [public] お薬手帳一覧/処方情報 RP: https://www.orca.med.or.jp/receipt/tec/api/report_print/okusuri_techo.html */
export type OrcaNotebookPrescription = {
  __typename?: 'OrcaNotebookPrescription';
  /** [public]  */
  count?: Maybe<Scalars['String']>;
  /** [public]  */
  id: Scalars['ID'];
  /** [public]  */
  medicalClass?: Maybe<Scalars['String']>;
  /** [public] 薬剤情報 */
  medications: Array<OrcaNotebookMedication>;
  /** [public]  */
  notCountable?: Maybe<Scalars['String']>;
  /** [public] 薬剤情報 */
  orcaNotebookMedications: OrcaNotebookMedicationConnection;
  /** [public]  */
  unitName?: Maybe<Scalars['String']>;
};

/** [public] お薬手帳一覧/処方情報 RP: https://www.orca.med.or.jp/receipt/tec/api/report_print/okusuri_techo.html */
export type OrcaNotebookPrescriptionOrcaNotebookMedicationsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** The connection type for OrcaNotebookPrescription. */
export type OrcaNotebookPrescriptionConnection = {
  __typename?: 'OrcaNotebookPrescriptionConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<OrcaNotebookPrescriptionEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<OrcaNotebookPrescription>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type OrcaNotebookPrescriptionEdge = {
  __typename?: 'OrcaNotebookPrescriptionEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<OrcaNotebookPrescription>;
};

/** [public] 診療費明細書: https://www.orca.med.or.jp/receipt/tec/api/report_print/meisaisho.html */
export type OrcaStatement = Node & {
  __typename?: 'OrcaStatement';
  /** [public]  */
  createdAt: Scalars['DateTime'];
  /** [public]  */
  id: Scalars['ID'];
  /** [public]  */
  insuranceRate?: Maybe<Scalars['String']>;
  /** [public]  */
  orcaStatementItems: Array<OrcaStatementItem>;
  /** [public]  */
  updatedAt: Scalars['DateTime'];
};

/** [public] 診療費明細書/診療明細情報 Statement: https://www.orca.med.or.jp/receipt/tec/api/report_print/meisaisho.html */
export type OrcaStatementItem = {
  __typename?: 'OrcaStatementItem';
  /** [public]  */
  count?: Maybe<Scalars['Int']>;
  /** [public]  */
  createdAt: Scalars['DateTime'];
  /** [public]  */
  description?: Maybe<Scalars['String']>;
  /** [public]  */
  endFlg?: Maybe<Scalars['Int']>;
  /** [public]  */
  groupNumber?: Maybe<Scalars['String']>;
  /** [public]  */
  id: Scalars['ID'];
  /** [public]  */
  medicalClassName?: Maybe<Scalars['String']>;
  /** [public]  */
  orcaStatement: OrcaStatement;
  /** [public]  */
  point?: Maybe<Scalars['Int']>;
  /** [public]  */
  totalPoint?: Maybe<Scalars['Int']>;
  /** [public]  */
  updatedAt: Scalars['DateTime'];
};

/** [public] Orca来院履歴: https://www.orca.med.or.jp/receipt/tec/api/visitpatient.html */
export type OrcaVisit = {
  __typename?: 'OrcaVisit';
  /** [public]  */
  clinic: Clinic;
  /** [public]  */
  clinicId: Scalars['Int'];
  /** [public]  */
  clinicNameJa: Scalars['String'];
  /** [public]  */
  createdAt: Scalars['DateTime'];
  /** [public]  */
  id: Scalars['ID'];
  /** [public]  */
  invoiceNumber: Scalars['String'];
  /** [public] 請求書兼領収書: https://www.orca.med.or.jp/receipt/tec/api/report_print/seikyusho.html */
  orcaInvoiceRecepts: Array<OrcaInvoiceRecept>;
  /** [public] お薬手帳一覧: https://www.orca.med.or.jp/receipt/tec/api/report_print/okusuri_techo.html */
  orcaNotebooks: Array<OrcaNotebook>;
  /** [public]  */
  orcaPatientId: Scalars['Int'];
  /** [public] 診療費明細書: https://www.orca.med.or.jp/receipt/tec/api/report_print/meisaisho.html */
  orcaStatements: Array<OrcaStatement>;
  /** [public]  */
  updatedAt?: Maybe<Scalars['DateTime']>;
  /** [public]  */
  updatedAtFromOrca?: Maybe<Scalars['DateTime']>;
  /** [public]  */
  userOrcaPatient?: Maybe<UserOrcaPatient>;
  /** [public]  */
  visitedOn?: Maybe<Scalars['Date']>;
};

export enum OtherHospitalMedicine {
  /** ニキビ治療薬 */
  Acne = 'acne',
  /** AGA/FAGA治療薬 */
  Aga = 'aga',
  /** 美容内服薬 */
  CosmeticInternal = 'cosmetic_internal',
  /** ダイエット治療薬（GLP-1など） */
  Diet = 'diet',
  /** ドライアイ用点眼薬 */
  DryEye = 'dry_eye',
  /** ED治療薬 */
  Ed = 'ed',
  /** 中用量ピル */
  IntermediateDosePill = 'intermediate_dose_pill',
  /** 漢方 */
  Kampo = 'kampo',
  /** 生活習慣病薬（高血圧・高脂血症など） */
  LifestyleDisease = 'lifestyle_disease',
  /** 低用量ピル */
  LowDosePill = 'low_dose_pill',
  /** 特になし */
  Nothing = 'nothing',
  /** 花粉症薬 */
  PollenAllergy = 'pollen_allergy',
}

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
};

export enum PatientImage {
  /** TelemedicineKarteImageへの登録用 */
  AffectedArea = 'affected_area',
  /** InsuranceCardへの登録用 */
  InsuranceCard = 'insurance_card',
  /** 未使用 */
  MedicalCertificate = 'medical_certificate',
  /** StudentIdCardへの登録用 */
  StudentIdCard = 'student_id_card',
}

/** [user]  */
export type Payment = {
  __typename?: 'Payment';
  /** [public]  */
  createdAt: Scalars['DateTime'];
  /** [public]  */
  id: Scalars['ID'];
  /** [public]  */
  serviceType: PaymentTypeService;
  /** [public]  */
  updatedAt: Scalars['DateTime'];
};

export type PaymentInput = {
  /** Checkout Session ID。Amazon Pay CV2の場合にのみ必須 */
  checkoutSessionId?: Maybe<Scalars['String']>;
  /** fraudbuster.jsによるデバイス情報。GMO後払いにのみ利用 */
  deviceInfo?: Maybe<Scalars['String']>;
  /** 支払い方法ID。Stripeの場合にのみ必須（ワンタイム決済は除く） */
  paymentMethodId?: Maybe<Scalars['ID']>;
  /** 支払いSecret。Stripeの場合にのみ必須（ワンタイム決済のみ） */
  paymentSecret?: Maybe<Scalars['String']>;
  /** 支払い手段 */
  serviceType: PaymentTypeService;
};

/** [user]  */
export type PaymentMethod = {
  __typename?: 'PaymentMethod';
  /** [public] 定期配送で利用しているか否か */
  beingUsedInSubscription: Scalars['Boolean'];
  /** [public]  */
  createdAt: Scalars['DateTime'];
  /** [public]  */
  creditCard?: Maybe<PaymentMethodCreditCard>;
  /** [public]  */
  discardedAt?: Maybe<Scalars['DateTime']>;
  /** [public]  */
  id: Scalars['ID'];
  /** [public]  */
  paymentType: Payment;
  /** [public]  */
  updatedAt: Scalars['DateTime'];
};

/** [public]  */
export type PaymentMethodCreditCard = {
  __typename?: 'PaymentMethodCreditCard';
  /** [public]  */
  brand: Scalars['String'];
  /** [public]  */
  expMonth: Scalars['Int'];
  /** [public]  */
  expYear: Scalars['Int'];
  /** [public]  */
  last4: Scalars['String'];
};

export enum PaymentTypeService {
  /** Amazon Pay */
  AmazonPay = 'amazon_pay',
  /** Amazon Pay CV2 */
  AmazonPayCv2 = 'amazon_pay_cv2',
  /** 企業優待 */
  CorporateBenefit = 'corporate_benefit',
  /** 代金引換 */
  Daibiki = 'daibiki',
  /** GMOによるコンビニ払い */
  GmoAtobarai = 'gmo_atobarai',
  /** Stripeによるクレカ決済 */
  Stripe = 'stripe',
  /** 0円決済 */
  Zero = 'zero',
}

/** [user]  */
export type PcrAbroadInterview = {
  __typename?: 'PcrAbroadInterview';
  /** [public]  */
  addressInJapan?: Maybe<Scalars['String']>;
  /** [public]  */
  addressInLocal?: Maybe<Scalars['String']>;
  /** [public]  */
  arrivalAndDepartureDateSame: Scalars['String'];
  /** [public]  */
  birthday: Scalars['DateTime'];
  /** [public]  */
  bodyTemperature: Scalars['Float'];
  /** [public]  */
  country: Scalars['String'];
  /** [public]  */
  departureTime: Scalars['DateTime'];
  /** [public]  */
  familyNameRomaji: Scalars['String'];
  /** [public]  */
  givenNameRomaji: Scalars['String'];
  /** [public]  */
  id: Scalars['ID'];
  /** [public]  */
  nationality: Scalars['String'];
  /** [public]  */
  organizationName: Scalars['String'];
  /** [public]  */
  passportNumber: Scalars['String'];
  /** [public]  */
  reservation: Reservation;
  /** [public]  */
  sex: UserSex;
  /** [public]  */
  user: User;
};

export type PcrAbroadInterviewInput = {
  /** 日本の住所 */
  addressInJapan?: Maybe<Scalars['String']>;
  /** 現地滞在先のご住所 */
  addressInLocal?: Maybe<Scalars['String']>;
  /** 入国日と出国日が同じか */
  arrivalAndDepartureDateSame: Scalars['String'];
  /** 誕生日 */
  birthday: Scalars['DateTime'];
  /** 体温 */
  bodyTemperature: Scalars['Float'];
  /** 居住国 */
  country: Scalars['String'];
  /** 出国予定日 */
  departureTime: Scalars['DateTime'];
  /** 受診者姓 */
  familyNameRomaji: Scalars['String'];
  /** 受診者名 */
  givenNameRomaji: Scalars['String'];
  /** 国籍 */
  nationality: Scalars['String'];
  /** 企業・団体名 */
  organizationName?: Maybe<Scalars['String']>;
  /** パスポート番号 */
  passportNumber: Scalars['String'];
  /** 予約ID */
  reservationId: Scalars['ID'];
  /** 性別 */
  sex: UserSex;
};

export enum PcrCertificate {
  NegativeEn = 'negative_en',
  NegativeEnVietnam = 'negative_en_vietnam',
  NegativeFree = 'negative_free',
  NegativeJa = 'negative_ja',
  PositiveFree = 'positive_free',
}

/** [user]  */
export type PcrResult = {
  __typename?: 'PcrResult';
  /** [public]  */
  clinic: Clinic;
  /** [public]  */
  createdAt: Scalars['DateTime'];
  /** [public]  */
  examinedOn: Scalars['Date'];
  /** [public]  */
  id: Scalars['ID'];
  /** [public]  */
  pcrResultCertificate?: Maybe<PcrResultCertificate>;
  /** [public]  */
  pcrResultCertificates: Array<PcrResultCertificate>;
  /** [public]  */
  reservation?: Maybe<Reservation>;
  /** [public]  */
  resultCode?: Maybe<PcrResultCode>;
  /** [public]  */
  uniqueSrlKey?: Maybe<Scalars['String']>;
  /** [public]  */
  userOrcaPatient: UserOrcaPatient;
};

/** [user]  */
export type PcrResultCertificate = {
  __typename?: 'PcrResultCertificate';
  /** [public]  */
  certificateType?: Maybe<PcrCertificate>;
  /** [public]  */
  documentHash?: Maybe<Scalars['String']>;
  /** [public]  */
  documentUrl: Scalars['String'];
  /** [public]  */
  id: Scalars['ID'];
  /** [public]  */
  isRequire: Scalars['Boolean'];
  /** [public]  */
  pcrResult: PcrResult;
};

export enum PcrResultCode {
  InReexamination = 'in_reexamination',
  Negative = 'negative',
  Positive = 'positive',
  ResubmitRequired = 'resubmit_required',
}

/** [public]  */
export type PhysicalCondition = {
  __typename?: 'PhysicalCondition';
  /** [public]  */
  name: FemappPhysicalCondition;
};

/** [public]  */
export type Pill = Node & {
  __typename?: 'Pill';
  /** [public]  */
  activeDrugSize: Scalars['Int'];
  /** [public]  */
  canContinuousIntake: Scalars['Boolean'];
  /** [public]  */
  description?: Maybe<Scalars['String']>;
  /** [public]  */
  id: Scalars['ID'];
  /** [public]  */
  name: Scalars['String'];
  /** [public]  */
  placeboDrugSize: Scalars['Int'];
};

/** [public]  */
export type PillClinic = Node & {
  __typename?: 'PillClinic';
  /** [public]  */
  id: Scalars['ID'];
  /** [public]  */
  name: Scalars['String'];
};

/** [public]  */
export type PillExamHistory = {
  __typename?: 'PillExamHistory';
  /** [public]  */
  hasExamHistory: Scalars['Boolean'];
};

/** [public]  */
export type PillInsight = {
  __typename?: 'PillInsight';
  /** [public]  */
  beauty: BeautyInsight;
  /** [public]  */
  diet: DietInsight;
  /** [public]  */
  general: GeneralInsight;
  /** [public]  */
  sheetNumber: Scalars['Int'];
  /** [public]  */
  tabletNumber: Scalars['Int'];
};

/** [public]  */
export type PillUser = {
  __typename?: 'PillUser';
  /** [public]  */
  address?: Maybe<Scalars['String']>;
  /** [public]  */
  email?: Maybe<Scalars['String']>;
  /** [public]  */
  hasExamHistory?: Maybe<Scalars['Boolean']>;
  /** [public]  */
  id: Scalars['ID'];
  /** [public]  */
  isShipFromSameAsDestination?: Maybe<Scalars['Boolean']>;
  /** [public]  */
  postcode?: Maybe<Scalars['Int']>;
  /** [public]  */
  selfCheckedMedicalInformation?: Maybe<SelfCheckedMedicalInformation>;
  /** [public]  */
  telNumber?: Maybe<Scalars['String']>;
};

export enum PlatformType {
  /** LINEミニアプリ */
  Line = 'line',
  /** 新共通 web */
  LineWeb = 'line_web',
  /** プラットフォーム */
  Platform = 'platform',
  /** Web */
  Web = 'web',
}

/** [user]  */
export type Point = {
  __typename?: 'Point';
  /** [public]  */
  createdAt: Scalars['DateTime'];
  /** [public]  */
  expiredAt: Scalars['DateTime'];
  /** [public]  */
  id: Scalars['ID'];
  /** [public]  */
  note?: Maybe<Scalars['String']>;
  /** [public]  */
  pointAction?: Maybe<PointAction>;
  /** [public]  */
  pointLabel?: Maybe<PointLabel>;
  /** [public]  */
  pointType: PointType;
  /** [public]  */
  pointUsage?: Maybe<PointUsage>;
  /** [public]  */
  pointUsageId?: Maybe<Scalars['Int']>;
  /** [public]  */
  pointUsageName: Scalars['String'];
  /** [public]  */
  title: Scalars['String'];
  /** [public]  */
  value: Scalars['Int'];
};

/** [user]  */
export type PointAction = {
  __typename?: 'PointAction';
  /** [public]  */
  actedAt: Scalars['DateTime'];
  /** [public]  */
  actionType: PointActionActionType;
  /** [public]  */
  id: Scalars['ID'];
};

export enum PointActionActionType {
  Login = 'login',
}

/** [public]  */
export type PointAggregatedByUsageExpiration = {
  __typename?: 'PointAggregatedByUsageExpiration';
  /** [public]  */
  expiredAt: Scalars['DateTime'];
  /** [public]  */
  pointUsageId?: Maybe<Scalars['Int']>;
  /** [public]  */
  pointUsageName: Scalars['String'];
  /** [public]  */
  value: Scalars['Int'];
};

/** The connection type for Point. */
export type PointConnection = {
  __typename?: 'PointConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<PointEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Point>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type PointEdge = {
  __typename?: 'PointEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Point>;
};

/** [user]  */
export type PointLabel = {
  __typename?: 'PointLabel';
  /** [public]  */
  id: Scalars['ID'];
  /** [public]  */
  itemType: PointLabelItemType;
  /** [public]  */
  saleAmount?: Maybe<Scalars['Int']>;
};

export enum PointLabelItemType {
  EcOrder = 'ec_order',
  ExamOrder = 'exam_order',
}

export enum PointType {
  AddLimited = 'add_limited',
  AddNormal = 'add_normal',
  ExpiredSubLimited = 'expired_sub_limited',
  ExpiredSubNormal = 'expired_sub_normal',
  OperationAddLimited = 'operation_add_limited',
  OperationAddNormal = 'operation_add_normal',
  OperationSubLimited = 'operation_sub_limited',
  OperationSubNormal = 'operation_sub_normal',
  SubLimited = 'sub_limited',
  SubNormal = 'sub_normal',
  WithdrawalSubLimited = 'withdrawal_sub_limited',
  WithdrawalSubNormal = 'withdrawal_sub_normal',
}

/** [user]  */
export type PointUsage = {
  __typename?: 'PointUsage';
  /** [public]  */
  createdAt: Scalars['DateTime'];
  /** [public]  */
  id: Scalars['ID'];
  /** [public]  */
  kind: PointUsageKind;
  /** [public]  */
  subKind: PointUsageSubKind;
  /** [public]  */
  updatedAt: Scalars['DateTime'];
};

export enum PointUsageKind {
  OnAllPlatform = 'on_all_platform',
  OnEachPlatform = 'on_each_platform',
  OnEachProductCategoryId = 'on_each_product_category_id',
}

export enum PointUsageSubKind {
  AcneTreatment = 'acne_treatment',
  Aga = 'aga',
  AgaOverseas = 'aga_overseas',
  Cosmetic = 'cosmetic',
  Diet = 'diet',
  Dryeye = 'dryeye',
  Ec = 'ec',
  Ed = 'ed',
  Faga = 'faga',
  Flu = 'flu',
  Hypertension = 'hypertension',
  Insurance = 'insurance',
  Kampo = 'kampo',
  Morningafterpill = 'morningafterpill',
  Pill = 'pill',
  PollenAllergy = 'pollen_allergy',
  QuitSmoking = 'quit_smoking',
  ShiftMenstruation = 'shift_menstruation',
  Std = 'std',
  TelemedicineEc = 'telemedicine_ec',
  TelemedicineExam = 'telemedicine_exam',
  Travel = 'travel',
}

export enum PregnantStatus {
  IsNotPregnant = 'is_not_pregnant',
  IsPotentiallyPregnant = 'is_potentially_pregnant',
  IsPregnant = 'is_pregnant',
  PregnantStatusUnconfirmed = 'pregnant_status_unconfirmed',
}

/** Autogenerated input type of PrepareToCompleteAmazonPayCheckoutSessionMutation */
export type PrepareToCompleteAmazonPayCheckoutSessionMutationInput = {
  /** 確認画面で表示された決済金額 */
  amountOnConfirmation: Scalars['Int'];
  checkoutSessionId: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  orderInput: AmazonPayOrderInput;
};

/** Autogenerated return type of PrepareToCompleteAmazonPayCheckoutSessionMutation */
export type PrepareToCompleteAmazonPayCheckoutSessionMutationPayload = {
  __typename?: 'PrepareToCompleteAmazonPayCheckoutSessionMutationPayload';
  amazonPayRedirectUrl: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /**
   * エラーコード表
   * U00001: リクエスト内容が正しくありません
   * U00002: ただいま混み合っております。お時間をおいてから再度お試しください。
   */
  errors: Array<GraphqlError>;
};

/** Autogenerated input type of PrepareToCreateAmazonPayCheckoutSessionMutation */
export type PrepareToCreateAmazonPayCheckoutSessionMutationInput = {
  checkoutCancelUrl: Scalars['String'];
  checkoutResultReturnUrl: Scalars['String'];
  checkoutReviewReturnUrl: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  orderInput: AmazonPayDraftOrderInput;
};

/** Autogenerated return type of PrepareToCreateAmazonPayCheckoutSessionMutation */
export type PrepareToCreateAmazonPayCheckoutSessionMutationPayload = {
  __typename?: 'PrepareToCreateAmazonPayCheckoutSessionMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  createCheckoutSessionConfig: AmazonPayButtonConfig;
  /**
   * エラーコード表
   * U00001: コールバックURLが長すぎます
   * U00001: コールバックURLが不正です
   * U00001: コールバックURLのホストが一致しません
   */
  errors: Array<GraphqlError>;
};

/** Autogenerated input type of PrepareToUpdateAmazonPayCheckoutSessionMutation */
export type PrepareToUpdateAmazonPayCheckoutSessionMutationInput = {
  checkoutSessionId: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  orderInput: AmazonPayDraftOrderInput;
};

/** Autogenerated return type of PrepareToUpdateAmazonPayCheckoutSessionMutation */
export type PrepareToUpdateAmazonPayCheckoutSessionMutationPayload = {
  __typename?: 'PrepareToUpdateAmazonPayCheckoutSessionMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /**
   * エラーコード表
   * U00001: リクエスト内容が正しくありません
   */
  errors: Array<GraphqlError>;
  success: Scalars['Boolean'];
};

export enum ProductCategoryId {
  /** ニキビ治療 */
  AcneTreatment = 'acne_treatment',
  /** 男性AGA */
  Aga = 'aga',
  /** AGA（海外） */
  AgaOverseas = 'aga_overseas',
  /** 美容皮膚 */
  Cosmetic = 'cosmetic',
  /** ダイエット */
  Diet = 'diet',
  /** ドライアイ */
  Dryeye = 'dryeye',
  /** ED */
  Ed = 'ed',
  /** 女性AGA */
  Faga = 'faga',
  /** 発熱外来（保険診療） */
  FeverOutpatient = 'fever_outpatient',
  /** インフル予防 */
  Flu = 'flu',
  /** 生活習慣病（高血圧・脂質異常症） */
  Hypertension = 'hypertension',
  /** 保険 */
  Insurance = 'insurance',
  /** 漢方 */
  Kampo = 'kampo',
  /** アフターピル */
  Morningafterpill = 'morningafterpill',
  /** 低用量ピル */
  Pill = 'pill',
  /** アレルギー科（保険診療） */
  PollenAllergy = 'pollen_allergy',
  /** 禁煙 */
  QuitSmoking = 'quit_smoking',
  /** 月経移動 */
  ShiftMenstruation = 'shift_menstruation',
  /** 性感染症治療 */
  Std = 'std',
  /** トラベル */
  Travel = 'travel',
}

/** [public]  */
export type PushNotificationSetting = {
  __typename?: 'PushNotificationSetting';
  /** [public]  */
  hasCampaign: Scalars['Boolean'];
  /** [public]  */
  hasNews: Scalars['Boolean'];
  /** [public]  */
  hasStatus: Scalars['Boolean'];
  /** [public]  */
  id: Scalars['ID'];
};

export type PushNotificationSettingInput = {
  hasCampaign?: Maybe<Scalars['Boolean']>;
  hasNews?: Maybe<Scalars['Boolean']>;
  hasStatus?: Maybe<Scalars['Boolean']>;
};

/** [public]  */
export type Query = {
  __typename?: 'Query';
  /** [clinic] Age groups classification of users within period */
  ageClassificationOfUsers: Array<AgeGroupsClassification>;
  /** [public] 美容皮膚科の施術内容のセット */
  beautyCounselingReservationTreatments: Array<BeautyCounselingReservationTreatment>;
  /** [public] オフライン美容サブスクのプラン一覧 */
  beautySubscriptionPlans: Array<BeautySubscriptionPlan>;
  /** [clinic] List of capacities( `actual / limit` ) by each slot in specific date */
  capacityResultsByDay: Array<CapacitiesInSlot>;
  /** [public] LINEミニアプリ専用の、分院を統合して扱うclinic一覧 */
  clinics: Array<Clinic>;
  /** [clinic] Vonage Video APIで利用するSession IDとTokenを返却する。 */
  clinicsVideoChatSession: VideoChatSession;
  /** [public] The currently logged in user */
  currentUser?: Maybe<User>;
  /** [clinic] Daily cancel-rate in conditions */
  dailyCancelRate: Array<CancelRate>;
  /** [clinic] List of departments by clinic */
  departmentByClinics: Array<Department>;
  /** [user] 事前問診用の設問を返す */
  diagnoseBlocks: Array<DiagnoseBlock>;
  /** [public] オンライン検査の詳細情報 */
  examinationList: Array<Examination>;
  /** [public] Femapp Insight */
  femappInsight: Insight;
  /** [femapp] Femapp pill */
  femappPill: Array<Pill>;
  /** [femapp] Femapp pill clinic */
  femappPillClinic: Array<PillClinic>;
  /** [public] Femapp Pill Insight */
  femappPillInsight: Array<PillInsight>;
  /** [public] find femapp survey and answer candidates */
  femappSurvey: Survey;
  /** [public] Femapp user */
  femappUser?: Maybe<FemappUser>;
  /** [clinic] Interview for clinic */
  interviewForClinic?: Maybe<Scalars['String']>;
  /** [user] 各種検査結果の最新結果 */
  latestResultByExamination: Array<ExaminationResultItem>;
  /** [line_message] Femapp Insight */
  lineMessageInsight: Insight;
  /** [public] Fetches an object given its ID. */
  node?: Maybe<Node>;
  /** [public] Fetches a list of objects given a list of IDs. */
  nodes: Array<Maybe<Node>>;
  /** [user] 薬情マスタ */
  orcaMedications: OrcaMedicationConnection;
  /** [user] Checkout Sessionに紐付いたAmazonPayDraftOrderInputまたはAmazonPayOrderInputのJSONデータ */
  patientsAmazonPayDraftOrderInputJson: Scalars['JSON'];
  /** [user] コロナ中和抗体検査結果を取得 */
  patientsAntibodyTestResults: Array<AntibodyTestResult>;
  /** [user] 決済前の合計金額確認用 */
  patientsConfirmationEcPaymentAmount: ConfirmationEcPaymentAmount;
  /** [user] オンライン検査の決済前の合計金額確認用 */
  patientsConfirmationExamPaymentAmount: ConfirmationExamPaymentAmount;
  /** [user] 購入する商品の薬剤と重複する定期契約を取得 */
  patientsDuplicatedEcSubscription: DuplicatedEcSubscription;
  /** [user] オンライン診療の商品一覧の取得 */
  patientsEcProducts: Array<EcProduct>;
  /** [user] オンライン診療の商品一覧と処方された薬剤グループの取得 */
  patientsEcProductsAndCheckedEcProductTags: EcProductsAndCheckedEcProductTags;
  /** [user] 最新の決済が失敗している定期配送 */
  patientsEcSubscriptionOrderFailedForSettlement: EcSubscriptionOrderConnection;
  /** [user] オンライン検査結果一覧 */
  patientsExamOnlineTestResults: ExamOnlineTestResults;
  /** [public] オンライン検査の商品一覧の取得 */
  patientsExamProducts: Array<ExamProduct>;
  /** [user] オンライン診療のお薬の希望についての確認で表示するデータの取得 */
  patientsInquiryProductTags: Array<EcProductTag>;
  /**
   * [user] 事前問診の最初の診療科分岐用の設問を返す
   * @deprecated This API is not to be used.
   */
  patientsIntroDiagnoseBlocks: Array<DiagnoseBlock>;
  /** [user] 追加配送時に現状問題ないかの旨を確認する必要があるか否か */
  patientsNeedReconfirmationAtAdditionalShipping: Scalars['Boolean'];
  /** [user] PCR検査結果を取得 */
  patientsPcrResults: Array<PcrResult>;
  /** [user] 低用量ピル診察歴 */
  patientsPriorInterviewPillExamHistory?: Maybe<PillExamHistory>;
  /** [user] ピル事前問診向けのユーザー情報の取得 */
  patientsPriorInterviewPillUser?: Maybe<PillUser>;
  /** [user] 事前問診データの取得 */
  patientsPriorInterviewTelemedicineInterview: TelemedicineInterview;
  /** [user] LINEお薬リマインダー一覧 */
  patientsReminders: Array<Reminder>;
  /** [user] 診察済みの予約回数 */
  patientsReservationCounts: ReservationCount;
  /** [user] 日別予約/来院履歴 */
  patientsReservationHistories: ReservationHistoriesResponse;
  /** [user] 日別予約/来院履歴 詳細 */
  patientsReservationHistory: ReservationHistory;
  /** [user] あるユーザーの全てのステータスの診察の予約回数の合計 */
  patientsReservationTotalCount: ReservationTotalCount;
  /** [user] 予約単位でまとめられたSTD検査結果を取得 */
  patientsStdResults: Array<StdResult>;
  /** [user] 未決済のオンライン診療 */
  patientsTelemedicineReservationWaitingForSettlement: ReservationConnection;
  /** [public] 予約可能か否か */
  patientsTimeSlotsByDepartment: Array<TimeSlotsByDepartment>;
  /** [user] 利用可能なクーポンか否か */
  patientsUseableEcCoupon: UseableEcCoupon;
  /** [user] 利用可能なクーポンか否か */
  patientsUseableExamCoupon: UseableExamCoupon;
  /** [public] ユーザーが予約可能か否か */
  patientsUserReservable: UserReservable;
  /** [user] Vonage Video APIで利用するSession IDとTokenを返却する。 */
  patientsVideoChatSession: VideoChatSession;
  /** [public] クリニック一覧 */
  platformClinics: Array<Clinic>;
  /** [public] 重要なお知らせの最新1件 */
  platformCriticalNotification?: Maybe<CriticalNotification>;
  /** [public] トピックス一覧 */
  platformHealthcareTopics: HealthcareTopicConnection;
  /** [public] 通常のお知らせの内、特にユーザーに知っていただきたいもの */
  platformImportantNotification?: Maybe<Notification>;
  /** [public] 通常お知らせを取得 */
  platformNotifications: NotificationConnection;
  /** [public] プッシュ通知設定 */
  platformPushNotificationSetting: PushNotificationSetting;
  /** [user] お薬リマインダー一覧 */
  platformReminders: Array<Reminder>;
  /** [public] Shopifyのベストセラー商品を取得 */
  platformShopifyBestSellerProducts: ShopifyBestSellerProducts;
  /** [user] Shopifyの定期購買状態を取得 */
  platformSubscriptionEcShops: SubscriptionEcShops;
  /** [user] 定期配送中薬剤 */
  platformSubscriptionOrderProducts: Array<SubscriptionOrderProduct>;
  /** [public] オンライン診療の診療科一覧 */
  platformTelemedicineDepartments: Array<Department>;
  /** [user] ワクチン接種履歴 */
  platformVaccinationHistories: ReservationConnection;
  /** [user] AGA事前問診向けのユーザー情報の取得 */
  priorInterviewAgaUser?: Maybe<AgaUser>;
  /** [public] クイック問診 設問取得 */
  quickInterviewQuestions: Array<QuickInterviewQuestion>;
  /** [clinic] Reservations by number */
  reservationByNumber?: Maybe<Reservation>;
  /** [clinic] Summarized reservation-stats in specified period */
  reservationSummaries: Array<ReservationSummary>;
  /** [clinic] List of reservations count by department */
  reservationsByDay: Array<ReservationsByDay>;
  /** [clinic] List of reservations count by specific condition, and each open-hour in a specified day */
  reservationsByHour: Array<ReservationsByHour>;
  /** [user] 検査結果をID指定で取得 */
  srlLabResult: SrlLabResult;
  /** [public] returns individual Telemedicine Clinic */
  telemedicineClinic: Clinic;
  /** [clinic] Users count of specific time series */
  usersCountTimeSeries: Array<UsersCountInTerm>;
  /** [user] 検査結果の項目の推移を見るためのデータを返す */
  vitalRecords: Array<VitalRecord>;
  /** [clinic] List of waiting minutes by hour */
  waitingTimeByHour: Array<WaitingTimeByHour>;
};

/** [public]  */
export type QueryAgeClassificationOfUsersArgs = {
  periodFrom: Scalars['Date'];
  periodTo: Scalars['Date'];
};

/** [public]  */
export type QueryCapacityResultsByDayArgs = {
  reservedOn: Scalars['Date'];
};

/** [public]  */
export type QueryClinicsVideoChatSessionArgs = {
  telemedicineVisitId: Scalars['ID'];
};

/** [public]  */
export type QueryDailyCancelRateArgs = {
  reservedFrom: Scalars['Date'];
  reservedTo: Scalars['Date'];
};

/** [public]  */
export type QueryDiagnoseBlocksArgs = {
  pageId: Scalars['Int'];
};

/** [public]  */
export type QueryExaminationListArgs = {
  sex?: Maybe<UserSex>;
};

/** [public]  */
export type QueryFemappInsightArgs = {
  stage: FemappPhaseStage;
  status: FemappPhaseStatus;
};

/** [public]  */
export type QueryFemappSurveyArgs = {
  id: Scalars['ID'];
};

/** [public]  */
export type QueryLineMessageInsightArgs = {
  stage: FemappPhaseStage;
  status: FemappPhaseStatus;
};

/** [public]  */
export type QueryNodeArgs = {
  id: Scalars['ID'];
};

/** [public]  */
export type QueryNodesArgs = {
  ids: Array<Scalars['ID']>;
};

/** [public]  */
export type QueryOrcaMedicationsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  codes: Array<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** [public]  */
export type QueryPatientsAmazonPayDraftOrderInputJsonArgs = {
  checkoutSessionId: Scalars['String'];
};

/** [public]  */
export type QueryPatientsConfirmationEcPaymentAmountArgs = {
  departmentId?: Maybe<Scalars['ID']>;
  ecOrder: EcOrderInput;
  payment?: Maybe<PaymentInput>;
  point?: Maybe<Scalars['Int']>;
};

/** [public]  */
export type QueryPatientsConfirmationExamPaymentAmountArgs = {
  examOrder: ExamOrderInput;
  payment?: Maybe<PaymentInput>;
};

/** [public]  */
export type QueryPatientsDuplicatedEcSubscriptionArgs = {
  ecProductIds?: Maybe<Array<Scalars['String']>>;
};

/** [public]  */
export type QueryPatientsEcProductsArgs = {
  departmentId: Scalars['ID'];
  reservationId?: Maybe<Scalars['ID']>;
};

/** [public]  */
export type QueryPatientsEcProductsAndCheckedEcProductTagsArgs = {
  departmentId: Scalars['ID'];
  ecProductId?: Maybe<Scalars['ID']>;
  onlyOnSale?: Maybe<Scalars['Boolean']>;
  onlyRequestRegularCancellation?: Maybe<Scalars['Boolean']>;
  reservationId?: Maybe<Scalars['ID']>;
};

/** [public]  */
export type QueryPatientsEcSubscriptionOrderFailedForSettlementArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** [public]  */
export type QueryPatientsExamProductsArgs = {
  examType: ExamProductExamType;
};

/** [public]  */
export type QueryPatientsInquiryProductTagsArgs = {
  departmentKey: Scalars['String'];
  onlyOnSale?: Maybe<Scalars['Boolean']>;
};

/** [public]  */
export type QueryPatientsIntroDiagnoseBlocksArgs = {
  clinicId: Scalars['ID'];
};

/** [public]  */
export type QueryPatientsNeedReconfirmationAtAdditionalShippingArgs = {
  departmentId: Scalars['String'];
};

/** [public]  */
export type QueryPatientsPriorInterviewTelemedicineInterviewArgs = {
  reservationId: Scalars['Int'];
};

/** [public]  */
export type QueryPatientsReservationHistoriesArgs = {
  after?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
};

/** [public]  */
export type QueryPatientsReservationHistoryArgs = {
  date: Scalars['Date'];
};

/** [public]  */
export type QueryPatientsTelemedicineReservationWaitingForSettlementArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  limitDays?: Maybe<Scalars['Int']>;
  sort?: Maybe<ReservationSort>;
};

/** [public]  */
export type QueryPatientsTimeSlotsByDepartmentArgs = {
  clinicId: Scalars['ID'];
  departmentId: Scalars['ID'];
  selectedDate: Scalars['DateTime'];
};

/** [public]  */
export type QueryPatientsUseableEcCouponArgs = {
  code: Scalars['String'];
  departmentId?: Maybe<Scalars['ID']>;
  ecOrderProducts?: Maybe<Array<EcOrderProductInput>>;
};

/** [public]  */
export type QueryPatientsUseableExamCouponArgs = {
  code: Scalars['String'];
  departmentId?: Maybe<Scalars['ID']>;
  examOrderProducts?: Maybe<Array<ExamOrderProductInput>>;
};

/** [public]  */
export type QueryPatientsUserReservableArgs = {
  excludeReservationId?: Maybe<Scalars['ID']>;
  selectedDate: Scalars['DateTime'];
};

/** [public]  */
export type QueryPatientsVideoChatSessionArgs = {
  telemedicineVisitId: Scalars['ID'];
};

/** [public]  */
export type QueryPlatformHealthcareTopicsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** [public]  */
export type QueryPlatformNotificationsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** [public]  */
export type QueryPlatformPushNotificationSettingArgs = {
  deviceId: Scalars['String'];
};

/** [public]  */
export type QueryPlatformTelemedicineDepartmentsArgs = {
  sex?: Maybe<UserSex>;
};

/** [public]  */
export type QueryPlatformVaccinationHistoriesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** [public]  */
export type QueryQuickInterviewQuestionsArgs = {
  choiceIds?: Maybe<Array<Scalars['ID']>>;
  departmentId: Scalars['Int'];
};

/** [public]  */
export type QueryReservationByNumberArgs = {
  number: Scalars['String'];
};

/** [public]  */
export type QueryReservationSummariesArgs = {
  summarizedFrom: Scalars['Date'];
  summarizedTo: Scalars['Date'];
};

/** [public]  */
export type QueryReservationsByDayArgs = {
  includeCancel?: Maybe<Scalars['Boolean']>;
  reservedFrom: Scalars['Date'];
  reservedTo: Scalars['Date'];
};

/** [public]  */
export type QueryReservationsByHourArgs = {
  includeCancel?: Maybe<Scalars['Boolean']>;
  reservedOn: Scalars['Date'];
};

/** [public]  */
export type QuerySrlLabResultArgs = {
  id: Scalars['ID'];
};

/** [public]  */
export type QueryUsersCountTimeSeriesArgs = {
  termFrom: Scalars['Date'];
  termTo: Scalars['Date'];
  typeOfTerm: Term;
};

/** [public]  */
export type QueryVitalRecordsArgs = {
  endDate?: Maybe<Scalars['Date']>;
  startDate: Scalars['Date'];
  vitalRecordTypes: Array<VitalRecordType>;
};

/** [public]  */
export type QueryWaitingTimeByHourArgs = {
  reservedOn: Scalars['Date'];
};

export type QuickInterviewAnswerInput = {
  /** QuickIntervewChoiceのID */
  id: Scalars['ID'];
  /** 回答した選択肢 */
  label?: Maybe<Scalars['String']>;
  /** 選択肢の質問 */
  question: Scalars['String'];
  /** QuickInterviewQuestion(質問)のID */
  questionId: Scalars['Int'];
  /** QuickInterviewQuestion(質問)の内容を特定するための文字列 */
  questionSignature?: Maybe<Scalars['String']>;
  /** value */
  value?: Maybe<Scalars['String']>;
};

/** [public]  */
export type QuickInterviewChoice = {
  __typename?: 'QuickInterviewChoice';
  /** [public]  */
  createdAt: Scalars['DateTime'];
  /** [public]  */
  id: Scalars['ID'];
  /** [public]  */
  inputAttr?: Maybe<Scalars['JSON']>;
  /** [public]  */
  inputLabel?: Maybe<Scalars['String']>;
  /** [public]  */
  inputType?: Maybe<Scalars['String']>;
  /** [public]  */
  meta?: Maybe<Scalars['JSON']>;
  /** [public]  */
  purposeText?: Maybe<Scalars['String']>;
  /** [public]  */
  questionId: Scalars['Int'];
  /** [public]  */
  quickInterviewQuestion: QuickInterviewQuestion;
  /** [public]  */
  updatedAt?: Maybe<Scalars['DateTime']>;
};

/** [public]  */
export type QuickInterviewQuestion = {
  __typename?: 'QuickInterviewQuestion';
  /** [public]  */
  alertText?: Maybe<Scalars['String']>;
  /** [public]  */
  appliedFrom: Scalars['DateTime'];
  /** [public]  */
  appliedTo?: Maybe<Scalars['DateTime']>;
  /** [public]  */
  createdAt: Scalars['DateTime'];
  /** [public]  */
  department: Department;
  /** [public]  */
  id: Scalars['ID'];
  /** [public]  */
  meta?: Maybe<Scalars['JSON']>;
  /** [public]  */
  question?: Maybe<Scalars['String']>;
  /** [public]  */
  questionSignature?: Maybe<Scalars['String']>;
  /** [public]  */
  quickInterviewChoiceId?: Maybe<Scalars['Int']>;
  /** [public]  */
  quickInterviewChoices?: Maybe<Array<QuickInterviewChoice>>;
  /** [public]  */
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export enum RealReservationCancelReason {
  /** 医師・看護師側でのキャンセル（キャンセル理由に表示されないが、内部で持っておく選択肢） */
  CancelledByClinic = 'CANCELLED_BY_CLINIC',
  /** 処方不可（キャンセル理由に表示されないが、内部で持っておく選択肢） */
  NotPrescription = 'NOT_PRESCRIPTION',
  /** その他 */
  Others = 'OTHERS',
  /** オンラインで受けることにした */
  RealStdChangeToOnline = 'REAL_STD_CHANGE_TO_ONLINE',
  /** もっと近いクリニックに行くことにした */
  RealStdCloserClinic = 'REAL_STD_CLOSER_CLINIC',
  /** 金額が高かった */
  RealStdExpensive = 'REAL_STD_EXPENSIVE',
  /** 時間が合わなくなった */
  RealStdNotOnTime = 'REAL_STD_NOT_ON_TIME',
  /** 性病の専門クリニックを受診することにした */
  RealStdSpecializingInStd = 'REAL_STD_SPECIALIZING_IN_STD',
  /** 事前問診が面倒だった */
  RealStdTroublesomeInterview = 'REAL_STD_TROUBLESOME_INTERVIEW',
  /** プライバシーに不安があった */
  RealStdUnclearPrivacyPolicy = 'REAL_STD_UNCLEAR_PRIVACY_POLICY',
  /** 検査・診療内容がわからず不安だった */
  RealStdUnclearTreatmentInfo = 'REAL_STD_UNCLEAR_TREATMENT_INFO',
  /** 受診せずに様子を見ることにした */
  RealStdWaitAndSee = 'REAL_STD_WAIT_AND_SEE',
}

export type RealReservationCancelReasonInput = {
  /** キャンセル理由 */
  choice: Array<RealReservationCancelReason>;
  /** その他のキャンセル理由(自由記述) */
  otherFields?: Maybe<Scalars['String']>;
};

/** [user]  */
export type Reminder = {
  __typename?: 'Reminder';
  /** [public]  */
  activeDays?: Maybe<Scalars['Int']>;
  /** [public]  */
  clientType: ReminderClient;
  /** [public]  */
  dayOfWeek?: Maybe<Array<Scalars['Int']>>;
  /** [public]  */
  endOn?: Maybe<Scalars['Date']>;
  /** [public]  */
  hasSnooze: Scalars['Boolean'];
  /** [public]  */
  id: Scalars['ID'];
  /** [public]  */
  inactiveDays?: Maybe<Scalars['Int']>;
  /** [public]  */
  intervalDays?: Maybe<Scalars['Int']>;
  /** [public]  */
  memo?: Maybe<Scalars['String']>;
  /** [public]  */
  name: Scalars['String'];
  /** [public]  */
  reminderTimes: Array<ReminderTime>;
  /** [public]  */
  scheduleType: ReminderSchedule;
  /** [public]  */
  startOn: Scalars['Date'];
  /** [public]  */
  stoppedAt?: Maybe<Scalars['DateTime']>;
  /** [public]  */
  user: User;
};

export enum ReminderClient {
  /** アプリプッシュ通知 */
  App = 'APP',
  /** LINE通知 */
  Line = 'LINE',
}

export type ReminderInput = {
  activeDays?: Maybe<Scalars['Int']>;
  clientType?: Maybe<ReminderClient>;
  dayOfWeek?: Maybe<Array<Scalars['Int']>>;
  endOn?: Maybe<Scalars['Date']>;
  hasSnooze?: Maybe<Scalars['Boolean']>;
  inactiveDays?: Maybe<Scalars['Int']>;
  intervalDays?: Maybe<Scalars['Int']>;
  memo?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  reminderTimes?: Maybe<Array<ReminderTimeInput>>;
  scheduleType?: Maybe<ReminderSchedule>;
  startOn?: Maybe<Scalars['Date']>;
  stoppedAt?: Maybe<Scalars['DateTime']>;
};

export enum ReminderSchedule {
  /** 曜日指定 */
  DayOfWeek = 'DAY_OF_WEEK',
  /** 毎日 */
  Everyday = 'EVERYDAY',
  /** 数日間隔 */
  Interval = 'INTERVAL',
  /** 周期(休薬期間あり) */
  Periodic = 'PERIODIC',
}

/** [user]  */
export type ReminderTime = {
  __typename?: 'ReminderTime';
  /** [public]  */
  id: Scalars['ID'];
  /** [public]  */
  nextNotifyAt?: Maybe<Scalars['DateTime']>;
  /** [public]  */
  remindTime: Scalars['Time'];
};

export type ReminderTimeInput = {
  remindTime: Scalars['Time'];
};

/** Autogenerated input type of ResendConfirmationInstructionsMutation */
export type ResendConfirmationInstructionsMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** メールアドレス */
  email: Scalars['String'];
};

/** Autogenerated return type of ResendConfirmationInstructionsMutation */
export type ResendConfirmationInstructionsMutationPayload = {
  __typename?: 'ResendConfirmationInstructionsMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /**
   * エラーコード表
   * U00001: リクエスト内容が正しくありません
   * resend_confirmation_instructions_mutation.invalid_target: 該当ユーザーはログイン前のパスワード変更が許可されていません。
   */
  errors: Array<GraphqlError>;
  /** 認証コードを伴わないリンク型のメールが送られた際にtrue */
  isConfirmationEmail: Scalars['Boolean'];
};

export enum Reservable {
  /** 予約可能 */
  Available = 'available',
  /** 利用不可能 */
  Banned = 'banned',
  /** 予約不可能 */
  Without = 'without',
}

/** [public]  */
export type Reservation = Node & {
  __typename?: 'Reservation';
  /** [public]  */
  additionalInterviewAnswer?: Maybe<AdditionalReservationInterviewAnswer>;
  /** [public]  */
  answersExists: Scalars['Boolean'];
  /** [public]  */
  assignedClinicUserId?: Maybe<Scalars['Int']>;
  /** [public]  */
  callNumber?: Maybe<Scalars['String']>;
  /** [public]  */
  cancelledAt?: Maybe<Scalars['DateTime']>;
  /** [public]  */
  clinic: Clinic;
  /** [public]  */
  clinicId: Scalars['Int'];
  /** [public]  */
  comment?: Maybe<Scalars['String']>;
  /** [public]  */
  createdAt: Scalars['DateTime'];
  /** [public]  */
  department: Department;
  /** [public]  */
  departmentId: Scalars['Int'];
  /** [public]  */
  discardedAt?: Maybe<Scalars['DateTime']>;
  /** [public] 注文内容 */
  ecOrder?: Maybe<EcOrder>;
  /** [public]  */
  finishedAt?: Maybe<Scalars['DateTime']>;
  /** [public]  */
  id: Scalars['ID'];
  /** [public] 問診に回答可能かどうか */
  interviewAnswerable: Scalars['Boolean'];
  /** [public]  */
  interviewAnswers: Array<InterviewAnswer>;
  /** [public]  */
  latestInterviewAnswer?: Maybe<InterviewAnswer>;
  /** [public]  */
  medicalDept?: Maybe<Scalars['Int']>;
  /** [public]  */
  occupancyRate?: Maybe<Scalars['Float']>;
  /** [public]  */
  pcrAbroadInterview?: Maybe<PcrAbroadInterview>;
  /** [public]  */
  proxyType?: Maybe<Scalars['Int']>;
  /** [public]  */
  purpose?: Maybe<Scalars['String']>;
  /** [public]  */
  requireCertificateEn: Scalars['Boolean'];
  /** [public]  */
  requireCertificateEnVietnam: Scalars['Boolean'];
  /** [public]  */
  requireCertificateJa: Scalars['Boolean'];
  /** [public]  */
  reservationHash?: Maybe<Scalars['String']>;
  /** [public]  */
  reservedAt: Scalars['DateTime'];
  /** [public]  */
  reservedVia?: Maybe<Scalars['String']>;
  /** [public]  */
  roomId?: Maybe<Scalars['Int']>;
  /** [public]  */
  startedAt?: Maybe<Scalars['DateTime']>;
  /** [public]  */
  status: ReservationStatus;
  /** [public]  */
  telemedicineInterview?: Maybe<TelemedicineInterview>;
  /** [public]  */
  telemedicineKarte?: Maybe<TelemedicineKarte>;
  /** [public]  */
  telemedicineVisit?: Maybe<TelemedicineVisit>;
  /** [public]  */
  toTimeSlot: TimeSlot;
  /** [public]  */
  updatedAt?: Maybe<Scalars['DateTime']>;
  /** [public]  */
  user?: Maybe<User>;
  /** [public]  */
  userComment?: Maybe<Scalars['String']>;
  /** [public]  */
  userId: Scalars['Int'];
  /** [public]  */
  vaccineName?: Maybe<Scalars['String']>;
  /** [public]  */
  visitedAt?: Maybe<Scalars['DateTime']>;
  /** [public] 予約詳細画面のURL */
  webviewUrl: Scalars['String'];
};

/** Conditional object for grouping Reservation by */
export type ReservationAxis = Department;

export enum ReservationCancelReason {
  /** 医師・看護師側でのキャンセル（キャンセル理由に表示されないが、内部で持っておく選択肢） */
  CancelledByClinic = 'CANCELLED_BY_CLINIC',
  /** 相談だけしたかった */
  Consultation = 'CONSULTATION',
  /** 金額が高かった */
  Expensive = 'EXPENSIVE',
  /** 対面で受診することにした */
  FaceToFace = 'FACE_TO_FACE',
  /** 診療科を間違えて予約した */
  Mistake = 'MISTAKE',
  /** 効果が期待できなさそうだった */
  NotEffective = 'NOT_EFFECTIVE',
  /** 時間が合わなくなった */
  NotOnTime = 'NOT_ON_TIME',
  /** 処方不可（キャンセル理由に表示されないが、内部で持っておく選択肢） */
  NotPrescription = 'NOT_PRESCRIPTION',
  /** その他 */
  Others = 'OTHERS',
  /** 他の病院等で診てもらうことにした */
  OtherHospitals = 'OTHER_HOSPITALS',
  /** 副作用が不安になった */
  SideEffect = 'SIDE_EFFECT',
}

export type ReservationCancelReasonInput = {
  /** キャンセル理由 */
  choice: Array<ReservationCancelReason>;
  /** その他のキャンセル理由(自由記述) */
  otherFields?: Maybe<Scalars['String']>;
};

/** The connection type for Reservation. */
export type ReservationConnection = {
  __typename?: 'ReservationConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ReservationEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Reservation>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** [public]  */
export type ReservationCount = {
  __typename?: 'ReservationCount';
  /** [public]  */
  reservationCount: Array<ReservationCountRow>;
  /** [public]  */
  reservationCountPerReal: Scalars['Int'];
  /** [public]  */
  reservationCountPerTelemedicine: Scalars['Int'];
};

/** [public]  */
export type ReservationCountRow = {
  __typename?: 'ReservationCountRow';
  /** [public]  */
  count: Scalars['Int'];
  /** [public]  */
  departmentName: Scalars['String'];
};

/** An edge in a connection. */
export type ReservationEdge = {
  __typename?: 'ReservationEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Reservation>;
};

/** [public]  */
export type ReservationHistoriesResponse = {
  __typename?: 'ReservationHistoriesResponse';
  /** [public]  */
  endCursor: Scalars['String'];
  /** [public]  */
  hasNextPage: Scalars['Boolean'];
  /** [public]  */
  histories: Array<ReservationHistory>;
};

/** [public]  */
export type ReservationHistory = {
  __typename?: 'ReservationHistory';
  /** [public]  */
  date: Scalars['Date'];
  /** [public]  */
  id: Scalars['ID'];
  /** [public] 一覧ではページングを考慮し同一データが複数含まれる。また、予約に関するIDのみのレスポンスしている */
  orcaVisits: Array<OrcaVisit>;
  /** [public]  */
  reservations: Array<Reservation>;
};

export type ReservationInput = {
  clinicId: Scalars['ID'];
  departmentId: Scalars['ID'];
  quickInterviewId?: Maybe<Scalars['ID']>;
  reservedAt: Scalars['DateTime'];
  telemedicineVia?: Maybe<TelemedicineVia>;
};

export enum ReservationSort {
  /** 昇順 */
  Ascend = 'ascend',
  /** 降順 */
  Descend = 'descend',
}

export enum ReservationStarted {
  /** 診察開始以降 */
  Since = 'since',
  /** 診察開始前まで */
  Until = 'until',
}

export enum ReservationStatus {
  /** キャンセル済み */
  Cancelled = 'cancelled',
  /** 診察終了 */
  Finished = 'finished',
  /** 検査結果待ち */
  Inspected = 'inspected',
  /** 退出(会計済み) */
  Left = 'left',
  /** 不通 */
  NoCommunication = 'no_communication',
  /** 準備中 */
  Preparation = 'preparation',
  /** 予約済み */
  Reserved = 'reserved',
  /** 診察開始 */
  Started = 'started',
  /** カウンセリング中 */
  StartedCounseling = 'started_counseling',
  /** 看護師対応中 */
  StartedNurse = 'started_nurse',
  /** 訪問、受付済 */
  Visited = 'visited',
  /** カウンセリング待ち */
  WaitingCounseling = 'waiting_counseling',
  /** Dr待ち */
  WaitingDoctor = 'waiting_doctor',
  /** 看護師待ち */
  WaitingNurse = 'waiting_nurse',
}

/** [public]  */
export type ReservationSummary = {
  __typename?: 'ReservationSummary';
  /** [public]  */
  axis?: Maybe<ReservationAxis>;
  /** [public]  */
  cancelledCount: Scalars['Int'];
  /** [public]  */
  clinicId: Scalars['Int'];
  /** [public]  */
  finishedCount: Scalars['Int'];
  /** [public]  */
  summarizedOn: Scalars['Date'];
  /** [public]  */
  totalCount: Scalars['Int'];
  /** [public]  */
  unappearedCount: Scalars['Int'];
  /** [public]  */
  visitedCount: Scalars['Int'];
};

/** [public]  */
export type ReservationTotalCount = {
  __typename?: 'ReservationTotalCount';
  /** [public]  */
  count: Scalars['Int'];
};

/** [public]  */
export type ReservationsByDay = {
  __typename?: 'ReservationsByDay';
  /** [public]  */
  axis: ReservationAxis;
  /** [public]  */
  reservationsCount: Scalars['Int'];
  /** [public]  */
  reservedOn: Scalars['Date'];
};

/** [public]  */
export type ReservationsByHour = {
  __typename?: 'ReservationsByHour';
  /** [public]  */
  axis: ReservationAxis;
  /** [public]  */
  reservationsCount: Scalars['Int'];
  /** [public]  */
  reservedHour: Scalars['Int'];
};

export enum ReservedVia {
  /** クリフォアアプリ */
  ClinicforApp = 'clinicfor_app',
  /** 新共通 web */
  LineWeb = 'line_web',
  /** 新共通 web LP */
  LineWebLp = 'line_web_lp',
}

/** Autogenerated input type of RestartReminderMutation */
export type RestartReminderMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of RestartReminderMutation */
export type RestartReminderMutationPayload = {
  __typename?: 'RestartReminderMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /**
   * エラーコード表
   * I00001: システムエラーです。お時間をおいてから再度お試しください。
   * I00010: リクエストが許可されていません
   * U00001: リクエスト内容が正しくありません
   * U00022: 該当リソースにはアクセスできません。
   */
  errors: Array<GraphqlError>;
  reminder?: Maybe<Reminder>;
};

/** Autogenerated input type of SaveAgaTelemedicineInterviewMutation */
export type SaveAgaTelemedicineInterviewMutationInput = {
  agaInterview: Scalars['JSON'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  reservationId: Scalars['ID'];
  step: Scalars['String'];
};

/** Autogenerated return type of SaveAgaTelemedicineInterviewMutation */
export type SaveAgaTelemedicineInterviewMutationPayload = {
  __typename?: 'SaveAgaTelemedicineInterviewMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /**
   * エラーコード表
   * U00001: リクエスト内容が正しくありません
   * U00010: 入力項目に誤りがあります
   */
  errors: Array<GraphqlError>;
  result?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of SavePillTelemedicineInterviewMutation */
export type SavePillTelemedicineInterviewMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  pillInterview: Scalars['JSON'];
  reservationId: Scalars['ID'];
  step: Scalars['String'];
};

/** Autogenerated return type of SavePillTelemedicineInterviewMutation */
export type SavePillTelemedicineInterviewMutationPayload = {
  __typename?: 'SavePillTelemedicineInterviewMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /**
   * エラーコード表
   * U00001: リクエスト内容が正しくありません (その他エラーの際の汎用コード)
   */
  errors: Array<GraphqlError>;
  telemedicineInterviewId?: Maybe<Scalars['ID']>;
};

/** [public]  */
export type SelfCheckedMedicalInformation = Node & {
  __typename?: 'SelfCheckedMedicalInformation';
  /** [public]  */
  alcoholFrequency?: Maybe<AlcoholFrequency>;
  /** [public]  */
  allergies?: Maybe<Scalars['String']>;
  /**
   * [public]
   * @deprecated 利用されていないフィールドです。
   */
  bmi?: Maybe<Scalars['Float']>;
  /** [public]  */
  breastFeedingStatus: BreastFeedingStatus;
  /** [public]  */
  createdAt: Scalars['Date'];
  /**
   * [public]
   * @deprecated 利用されていないフィールドです。
   */
  currentHospitals?: Maybe<Scalars['String']>;
  /** [public]  */
  currentMedicines?: Maybe<Scalars['String']>;
  /**
   * [public]
   * @deprecated 利用されていないフィールドです。
   */
  familyMedicalHistory?: Maybe<Scalars['String']>;
  /** [public]  */
  hasAllergy?: Maybe<Scalars['Boolean']>;
  /**
   * [public]
   * @deprecated 利用されていないフィールドです。
   */
  height?: Maybe<Scalars['Float']>;
  /** [public] 今までに入院した病気・けがを教えて下さい。 */
  hospitalizedMedicalHistory?: Maybe<Scalars['String']>;
  /** [public]  */
  id: Scalars['ID'];
  /** [public]  */
  informativeMatter?: Maybe<Scalars['String']>;
  /** [public]  */
  isTakingMedicine: Scalars['Boolean'];
  /** [public]  */
  lastCheckedAt: Scalars['Date'];
  /** [public] 今までにかかったことがある病気を選択してください。 */
  medicalHistories: Array<MedicalHistory>;
  /**
   * [public]
   * @deprecated 利用されていないフィールドです。
   */
  medicalHistory?: Maybe<Scalars['String']>;
  /** [public] マイナ保険証による診療情報取得に同意しますか？ */
  mynumberMedicalInformationConsent: MynumberMedicalInformationConsent;
  /** [public] 他の病院にかかっている病名と病院名を教えてください。 */
  otherHospital?: Maybe<Scalars['String']>;
  /** [public] クリニックフォア以外で定期的に処方されている薬を教えてください。 */
  otherHospitalMedicines: Array<OtherHospitalMedicine>;
  /** [public]  */
  pregnantStatus: PregnantStatus;
  /** [public]  */
  smokingFrequency?: Maybe<SmokingFrequency>;
  /**
   * [public]
   * @deprecated 利用されていないフィールドです。
   */
  smokingFromAge?: Maybe<Scalars['Int']>;
  /** [public]  */
  updatedAt: Scalars['Date'];
  /**
   * [public]
   * @deprecated 利用されていないフィールドです。
   */
  vaccinationRecord?: Maybe<Scalars['String']>;
  /**
   * [public]
   * @deprecated 利用されていないフィールドです。
   */
  visitedGynecologyDescription?: Maybe<Scalars['String']>;
  /**
   * [public]
   * @deprecated 利用されていないフィールドです。
   */
  visitedGynecologyLastYear?: Maybe<Scalars['Boolean']>;
  /**
   * [public]
   * @deprecated 利用されていないフィールドです。
   */
  weight?: Maybe<Scalars['Float']>;
};

export type SelfCheckedMedicalInformationInput = {
  alcoholFrequency?: Maybe<AlcoholFrequency>;
  /** アレルギーの内容。セットするにはhasAllergyをtrueにしてください。 */
  allergies?: Maybe<Scalars['String']>;
  breastFeedingStatus: BreastFeedingStatus;
  currentHospitals?: Maybe<Scalars['String']>;
  /** 現在服用中の薬。セットするにはisTakingMedicineをtrueにしてください。 */
  currentMedicines?: Maybe<Scalars['String']>;
  familyMedicalHistory?: Maybe<Scalars['String']>;
  hasAllergy: Scalars['Boolean'];
  height?: Maybe<Scalars['Float']>;
  /** 今までに入院した病気・けがを教えて下さい。 */
  hospitalizedMedicalHistory?: Maybe<Scalars['String']>;
  informativeMatter?: Maybe<Scalars['String']>;
  isTakingMedicine: Scalars['Boolean'];
  lastCheckedAt: Scalars['Date'];
  /** 今までにかかったことがある病気を選択してください。 */
  medicalHistories?: Maybe<Array<MedicalHistory>>;
  medicalHistory?: Maybe<Scalars['String']>;
  /** マイナ保険証による診療情報取得に同意しますか？ */
  mynumberMedicalInformationConsent?: Maybe<MynumberMedicalInformationConsent>;
  /** 他の病院にかかっている病名と病院名を教えてください。 */
  otherHospital?: Maybe<Scalars['String']>;
  /** クリニックフォア以外で定期的に処方されている薬を教えてください。 */
  otherHospitalMedicines?: Maybe<Array<OtherHospitalMedicine>>;
  pregnantStatus: PregnantStatus;
  smokingFrequency?: Maybe<SmokingFrequency>;
  smokingFromAge?: Maybe<Scalars['Int']>;
  vaccinationRecord?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Float']>;
};

/** Autogenerated input type of SendAgaChangeClinicCouponMutation */
export type SendAgaChangeClinicCouponMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  reservationId: Scalars['ID'];
};

/** Autogenerated return type of SendAgaChangeClinicCouponMutation */
export type SendAgaChangeClinicCouponMutationPayload = {
  __typename?: 'SendAgaChangeClinicCouponMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /**
   * エラーコード表
   * U00001: リクエスト内容が正しくありません (その他エラーの際の汎用コード)
   */
  errors: Array<GraphqlError>;
  result: Scalars['String'];
};

/** Autogenerated input type of SendAgaHairKeepPlanCouponMutation */
export type SendAgaHairKeepPlanCouponMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  reservationId: Scalars['ID'];
};

/** Autogenerated return type of SendAgaHairKeepPlanCouponMutation */
export type SendAgaHairKeepPlanCouponMutationPayload = {
  __typename?: 'SendAgaHairKeepPlanCouponMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /**
   * エラーコード表
   * U00001: リクエスト内容が正しくありません (その他エラーの際の汎用コード)
   */
  errors: Array<GraphqlError>;
  result: Scalars['String'];
};

/** Autogenerated input type of SendChangingClinicCouponMutation */
export type SendChangingClinicCouponMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  reservationId: Scalars['ID'];
};

/** Autogenerated return type of SendChangingClinicCouponMutation */
export type SendChangingClinicCouponMutationPayload = {
  __typename?: 'SendChangingClinicCouponMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /**
   * エラーコード表
   * U00001: リクエスト内容が正しくありません (その他エラーの際の汎用コード)
   */
  errors: Array<GraphqlError>;
  result: Scalars['String'];
};

/** Autogenerated input type of SendCouponMailMutation */
export type SendCouponMailMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  couponMailType: CouponMail;
};

/** Autogenerated return type of SendCouponMailMutation */
export type SendCouponMailMutationPayload = {
  __typename?: 'SendCouponMailMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /**
   * エラーコード表
   * U00001: リクエスト内容が正しくありません (その他エラーの際の汎用コード)
   */
  errors: Array<GraphqlError>;
  result: Scalars['String'];
};

/** Autogenerated input type of SetupPlatformUserMutation */
export type SetupPlatformUserMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  deviceId: Scalars['String'];
};

/** Autogenerated return type of SetupPlatformUserMutation */
export type SetupPlatformUserMutationPayload = {
  __typename?: 'SetupPlatformUserMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  pushNotificationSetting: PushNotificationSetting;
};

/** [public]  */
export type ShopifyBestSellerProducts = {
  __typename?: 'ShopifyBestSellerProducts';
  /** [public]  */
  cfb: ShopifyProductConnection;
  /** [public]  */
  lens: ShopifyProductConnection;
  /** [public]  */
  otc: ShopifyProductConnection;
};

/** [public]  */
export type ShopifyBestSellerProductsCfbArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** [public]  */
export type ShopifyBestSellerProductsLensArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** [public]  */
export type ShopifyBestSellerProductsOtcArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** [public]  */
export type ShopifyProduct = {
  __typename?: 'ShopifyProduct';
  /** [public]  */
  id: Scalars['ID'];
  /** [public]  */
  image: Scalars['String'];
  /** [public]  */
  label?: Maybe<Scalars['String']>;
  /** [public]  */
  minimumPrice?: Maybe<Scalars['Int']>;
  /** [public]  */
  name: Scalars['String'];
  /** [public]  */
  price?: Maybe<Scalars['Int']>;
  /** [public]  */
  unit?: Maybe<Scalars['String']>;
  /** [public]  */
  vendor?: Maybe<Scalars['String']>;
  /** [public]  */
  webviewUrl: Scalars['String'];
};

/** The connection type for ShopifyProduct. */
export type ShopifyProductConnection = {
  __typename?: 'ShopifyProductConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ShopifyProductEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<ShopifyProduct>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type ShopifyProductEdge = {
  __typename?: 'ShopifyProductEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<ShopifyProduct>;
};

export enum ShopifySubscriptionStatus {
  Active = 'ACTIVE',
  Cancelled = 'CANCELLED',
  Expired = 'EXPIRED',
  Failed = 'FAILED',
  NeverSubscribed = 'NEVER_SUBSCRIBED',
  Paused = 'PAUSED',
}

/** Autogenerated input type of SignUpForV2Mutation */
export type SignUpForV2MutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of SignUpForV2Mutation */
export type SignUpForV2MutationPayload = {
  __typename?: 'SignUpForV2MutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /**
   * エラーコード表
   * I00001: システムエラーです。お時間をおいてから再度お試しください。
   * U00001: リクエスト内容が正しくありません
   * U00001: LINEアカウントがすでに登録されています。
   * U00020: IDトークンの有効期限が切れました。再度トークンを取得してください。
   */
  errors: Array<GraphqlError>;
  isSuccess?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated input type of SkipEcSubscriptionOrderMutation */
export type SkipEcSubscriptionOrderMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** 定期配送ID */
  ecSubscriptionOrderId: Scalars['ID'];
  /** 次回発送日 */
  nextScheduledToBeShippedAt: Scalars['Date'];
};

/** Autogenerated return type of SkipEcSubscriptionOrderMutation */
export type SkipEcSubscriptionOrderMutationPayload = {
  __typename?: 'SkipEcSubscriptionOrderMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** 定期配送 */
  ecSubscriptionOrder?: Maybe<EcSubscriptionOrder>;
  /**
   *         エラーコード表
   *         U00001: リクエスト内容が正しくありません
   *         skip_ec_subscription_order_mutation.already_cancelled:
   * こちらの定期配送はすでにキャンセルされております。お薬をご希望の場合、診察予約を行っていただくか追加配送をご利用ください。
   *         skip_ec_subscription_order_mutation.prohibit_past_date: 明日以降の日付を指定してください。
   *         skip_ec_subscription_order_mutation.could_not_skipped:
   * 次回発送日の変更ができませんでした。希望する変更日を online-support@clinicfor.life までお問い合わせください。
   */
  errors: Array<GraphqlError>;
};

export enum SmokingFrequency {
  /** 一日に10本程度 */
  SmokingFrequencyAbout_10InADay = 'smoking_frequency_about_10_in_a_day',
  /** 一日に20本程度 */
  SmokingFrequencyAbout_20InADay = 'smoking_frequency_about_20_in_a_day',
  /** 一日10本以下 */
  SmokingFrequencyLessThan_10InADay = 'smoking_frequency_less_than_10_in_a_day',
  /** 一日に40本以上 */
  SmokingFrequencyMoreThan_40InADay = 'smoking_frequency_more_than_40_in_a_day',
  /** 吸わない */
  SmokingFrequencyNone = 'smoking_frequency_none',
  /** 吸っていた */
  SmokingFrequencyStopped = 'smoking_frequency_stopped',
}

/** [user]  */
export type SpecifiedStdResult = {
  __typename?: 'SpecifiedStdResult';
  /** [public]  */
  examinationName: Scalars['String'];
  /** [public]  */
  id: Scalars['ID'];
  /** [public] 血液精密検査が必要な場合に表示するメッセージ */
  message?: Maybe<Scalars['String']>;
  /** [public]  */
  result: StdResultResult;
  /** [public]  */
  resultText?: Maybe<Scalars['String']>;
  /** [public]  */
  specimenName: Scalars['String'];
};

/** [user]  */
export type SrlLabResult = Node & {
  __typename?: 'SrlLabResult';
  /** [public]  */
  clinic: Clinic;
  /** [public]  */
  contentJson: Scalars['JSON'];
  /** [public]  */
  id: Scalars['ID'];
  /** [public]  */
  orcaPatientId: Scalars['Int'];
  /** [public]  */
  requestId?: Maybe<Scalars['Int']>;
  /** [public]  */
  reservationId?: Maybe<Scalars['Int']>;
};

/** The connection type for SrlLabResult. */
export type SrlLabResultConnection = {
  __typename?: 'SrlLabResultConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<SrlLabResultEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<SrlLabResult>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type SrlLabResultEdge = {
  __typename?: 'SrlLabResultEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<SrlLabResult>;
};

/** [public]  */
export type StdMenu = {
  __typename?: 'StdMenu';
  /** [public]  */
  additionalExam: Scalars['Boolean'];
  /** [public]  */
  displayOrder: Scalars['Int'];
  /** [public]  */
  displayType: StdMenuDisplayType;
  /** [public]  */
  id: Scalars['ID'];
  /** [public]  */
  name: Scalars['String'];
  /** [public]  */
  sex: StdMenuSex;
  /** [public]  */
  status: StdMenuStatus;
};

export enum StdMenuDisplayType {
  /** 対面検査 */
  InClinic = 'in_clinic',
  /** オンライン検査 */
  Online = 'online',
}

export enum StdMenuSex {
  /** 女性 */
  Female = 'female',
  /** 男性 */
  Male = 'male',
}

export enum StdMenuStatus {
  /** 利用中 */
  Active = 'active',
  /** 停止中 */
  Suspend = 'suspend',
}

/** [user]  */
export type StdResult = {
  __typename?: 'StdResult';
  /** [public]  */
  hasInvalidResult: Scalars['Boolean'];
  /** [public]  */
  hasPositiveResult: Scalars['Boolean'];
  /** [public]  */
  id: Scalars['ID'];
  /** [public]  */
  reservedAt: Scalars['DateTime'];
  /** [public]  */
  specifiedStdResults: Array<SpecifiedStdResult>;
};

export enum StdResultResult {
  Equiv = 'equiv',
  FreeText = 'free_text',
  Negative = 'negative',
  Positive = 'positive',
  ResultInvalid = 'result_invalid',
}

/** Autogenerated input type of StopReminderMutation */
export type StopReminderMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of StopReminderMutation */
export type StopReminderMutationPayload = {
  __typename?: 'StopReminderMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /**
   * エラーコード表
   * I00001: システムエラーです。お時間をおいてから再度お試しください。
   * I00010: リクエストが許可されていません
   * U00001: リクエスト内容が正しくありません
   * U00022: 該当リソースにはアクセスできません。
   */
  errors: Array<GraphqlError>;
  reminder?: Maybe<Reminder>;
};

/** [public]  */
export type StudentIdCard = {
  __typename?: 'StudentIdCard';
  /** [public]  */
  createdAt: Scalars['DateTime'];
  /** [public]  */
  id: Scalars['ID'];
  /** [public]  */
  largeImageUrl?: Maybe<Scalars['String']>;
  /** [public]  */
  updatedAt: Scalars['DateTime'];
  /** [public]  */
  userId: Scalars['Int'];
};

/** [public]  */
export type SubscribedMailContent = {
  __typename?: 'SubscribedMailContent';
  /** [public]  */
  id: Scalars['ID'];
  /** [public]  */
  includesClinicInfo: Scalars['Boolean'];
  /** [public]  */
  includesHealthcareInfo: Scalars['Boolean'];
  /** [public]  */
  includesLineNotification: Scalars['Boolean'];
  /** [public]  */
  includesManInfo: Scalars['Boolean'];
  /** [public]  */
  includesPlatformNewsletter: Scalars['Boolean'];
  /** [public]  */
  includesPromotionInfo: Scalars['Boolean'];
  /** [public]  */
  includesWomanInfo: Scalars['Boolean'];
};

/** [public]  */
export type SubscriptionEcShop = {
  __typename?: 'SubscriptionEcShop';
  /** [public]  */
  status: ShopifySubscriptionStatus;
  /** [public]  */
  webviewUrl: Scalars['String'];
};

/** [public]  */
export type SubscriptionEcShops = {
  __typename?: 'SubscriptionEcShops';
  /** [public]  */
  cfb: SubscriptionEcShop;
  /** [public]  */
  id?: Maybe<Scalars['ID']>;
  /** [public]  */
  lens: SubscriptionEcShop;
  /** [public]  */
  otc: SubscriptionEcShop;
};

/** [user]  */
export type SubscriptionOrderProduct = Node & {
  __typename?: 'SubscriptionOrderProduct';
  /** [public]  */
  id: Scalars['ID'];
  /** [public]  */
  imageUrl?: Maybe<Scalars['String']>;
  /** [public]  */
  name: Scalars['String'];
  /** [public] 次回配送予定日 */
  nextShippedOn?: Maybe<Scalars['Date']>;
  /** [public] active: 継続中, cancelled: 停止済 */
  status: EcSubscriptionOrderStatus;
  /** [public]  */
  storeName: Scalars['String'];
  /** [public] オンライン診療追加配送画面のURL。キャンセルされた定期配送の場合のみ値を持つ */
  webviewAdditionalDeliveryUrl?: Maybe<Scalars['String']>;
  /** [public] オンライン診療定期配送詳細画面のURL。アクティブな定期配送の場合のみ値を持つ */
  webviewDetailUrl?: Maybe<Scalars['String']>;
};

/** [public]  */
export type Survey = {
  __typename?: 'Survey';
  /** [public]  */
  answers: Array<SurveyAnswer>;
};

/** [public]  */
export type SurveyAnswer = {
  __typename?: 'SurveyAnswer';
  /** [public]  */
  id: Scalars['ID'];
  /** [public]  */
  text: Scalars['String'];
};

/** Autogenerated input type of TakeDrugMutation */
export type TakeDrugMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  reminderResultId: Scalars['ID'];
};

/** Autogenerated return type of TakeDrugMutation */
export type TakeDrugMutationPayload = {
  __typename?: 'TakeDrugMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /**
   * エラーコード表
   * I00001: システムエラーです。お時間をおいてから再度お試しください。
   * I00010: リクエストが許可されていません
   * U00001: リクエスト内容が正しくありません
   * U00022: 該当リソースにはアクセスできません。
   */
  errors: Array<GraphqlError>;
  isSuccess?: Maybe<Scalars['Boolean']>;
};

export type TelemedicineInquiryInput = {
  /** 事前問診に全て回答したか否か */
  isFinished?: Maybe<Scalars['Boolean']>;
  reservationId: Scalars['ID'];
  result?: Maybe<Scalars['JSON']>;
  savedPageItemId: Scalars['ID'];
};

/** [user]  */
export type TelemedicineInterview = {
  __typename?: 'TelemedicineInterview';
  /** [public] AGA問診状態 */
  agaCurrentStep?: Maybe<Scalars['String']>;
  /** [public] AGA問診回答 */
  agaInterview?: Maybe<Scalars['JSON']>;
  /** [public] AGA問診バージョン */
  agaInterviewVersion: Scalars['Int'];
  /** [public] 問診回答日時 */
  answeredAt?: Maybe<Scalars['DateTime']>;
  /** [public]  */
  createdAt: Scalars['DateTime'];
  /** [public] Googleフォーム事前問診のURL */
  googleformUrl: Scalars['String'];
  /** [public]  */
  id: Scalars['ID'];
  /** [public] 事前問診に全て回答したか否か */
  isFinished: Scalars['Boolean'];
  /** [public] 低用量ピル問診状態 */
  pillCurrentStep?: Maybe<Scalars['String']>;
  /** [public] 低用量ピル問診回答 */
  pillInterview?: Maybe<Scalars['JSON']>;
  /** [public] 低用量ピル問診バージョン */
  pillInterviewVersion: Scalars['Int'];
  /** [public] 処方可否 */
  processable: Scalars['Boolean'];
  /** [public] 予約 */
  reservation?: Maybe<Reservation>;
  /** [public] 予約ハッシュ値 */
  reservationHash: Scalars['String'];
  /** [public] 事前問診回答 */
  result?: Maybe<Scalars['JSON']>;
  /** [public] 最終的に回答が完了しているpage_itemのid */
  savedPageItemId: Scalars['ID'];
  /** [public] オンライン診療の方法 */
  telemedicineVia?: Maybe<TelemedicineVia>;
  /** [public]  */
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type TelemedicineInterviewInput = {
  reservationId: Scalars['ID'];
  result?: Maybe<Scalars['JSON']>;
};

/** [user]  */
export type TelemedicineKarte = {
  __typename?: 'TelemedicineKarte';
  /** [public]  */
  id: Scalars['ID'];
  /** [public] カルテ本文 */
  soap?: Maybe<Scalars['JSON']>;
  /** [public]  */
  telemedicineKarteImages?: Maybe<Array<TelemedicineKarteImage>>;
};

/** [user]  */
export type TelemedicineKarteImage = {
  __typename?: 'TelemedicineKarteImage';
  /** [public]  */
  id: Scalars['ID'];
  /** [public]  */
  imageType?: Maybe<DetailImage>;
  /** [public]  */
  largeImageUrl?: Maybe<Scalars['String']>;
};

/** [public]  */
export type TelemedicineMenuDetail = {
  __typename?: 'TelemedicineMenuDetail';
  /** [public]  */
  detailContents: Array<TelemedicineMenuDetailDetailContent>;
  /** [public]  */
  id: Scalars['ID'];
  /** [public]  */
  name: Scalars['String'];
  /** [public]  */
  stepType: TelemedicineMenuDetailStep;
  /** [public]  */
  targetUsers: Array<TelemedicineMenuDetailTargetUser>;
};

/** [public]  */
export type TelemedicineMenuDetailDetailContent = {
  __typename?: 'TelemedicineMenuDetailDetailContent';
  /** [public]  */
  text: Scalars['String'];
  /** [public]  */
  webviewUrl: Scalars['String'];
};

export enum TelemedicineMenuDetailStep {
  /** オンライン検査 */
  Examination = 'examination',
  /** 薬を受け取りに行く */
  PickUpMedication = 'pick_up_medication',
  /** 薬の配達 */
  ReceiveMedication = 'receive_medication',
}

/** [public]  */
export type TelemedicineMenuDetailTargetUser = {
  __typename?: 'TelemedicineMenuDetailTargetUser';
  /** [public]  */
  icon: Scalars['String'];
  /** [public]  */
  text: Scalars['String'];
};

/** [public]  */
export type TelemedicinePrescription = {
  __typename?: 'TelemedicinePrescription';
  /** [public]  */
  department: Department;
  /** [public]  */
  id: Scalars['ID'];
  /** [public]  */
  name?: Maybe<Scalars['String']>;
};

export enum TelemedicineVia {
  /** 電話 */
  Tel = 'tel',
  /** ビデオチャット */
  Video = 'video',
}

/** [user]  */
export type TelemedicineVisit = {
  __typename?: 'TelemedicineVisit';
  /** [public]  */
  id: Scalars['ID'];
  /** [public] オンライン診療の方法 */
  telemedicineVia: TelemedicineVia;
  /** [public] Vonage Video APIで利用するSession ID */
  videoChatSessionId?: Maybe<Scalars['String']>;
};

export enum Term {
  Daily = 'Daily',
  Monthly = 'Monthly',
  Weekly = 'Weekly',
  Yearly = 'Yearly',
}

/** [public]  */
export type TimeSlot = {
  __typename?: 'TimeSlot';
  /** [public]  */
  endDatetime: Scalars['DateTime'];
  /** [public]  */
  startDatetime: Scalars['DateTime'];
};

/** [public]  */
export type TimeSlotsByDepartment = {
  __typename?: 'TimeSlotsByDepartment';
  /** [public]  */
  endDatetime: Scalars['DateTime'];
  /** [public]  */
  reservable: Reservable;
  /** [public]  */
  startDatetime: Scalars['DateTime'];
};

/** Autogenerated input type of UpdateAllCyclesMutation */
export type UpdateAllCyclesMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  cyclesAttributes: Array<FemappCycleInput>;
};

/** Autogenerated return type of UpdateAllCyclesMutation */
export type UpdateAllCyclesMutationPayload = {
  __typename?: 'UpdateAllCyclesMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  cycleState?: Maybe<CycleState>;
  /**
   * エラーコード表
   * I00001: システムエラーです。お時間をおいてから再度お試しください。
   * I00010: リクエストが許可されていません
   * U00001: リクエスト内容が正しくありません
   * U00001: 生理開始日が同じデータがあります。別日を選択してください。
   * U00001: 生理開始日は、生年月日以降の日付を選択してください。
   * U00001: 生理開始日は、登録済の生理期間内には設定できません。登録済の生理情報を削除するか、別日を選択してください。
   * U00001: 生理終了日は、生理開始日以降の日付を選択してください。
   * U00001: 生理終了日は、登録済の生理期間内には設定できません。登録済の生理情報を削除するか、別日を選択してください。
   * U00001: 登録しようとした生理期間の間に、登録済の生理日があります。登録済の生理情報を削除するか、別日を選択してください。
   * U00020: IDトークンの有効期限が切れました。再度トークンを取得してください。
   */
  errors: Array<GraphqlError>;
};

/** Autogenerated input type of UpdateCycleMutation */
export type UpdateCycleMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  cycleAttributes: FemappCycleInput;
  id: Scalars['ID'];
};

/** Autogenerated return type of UpdateCycleMutation */
export type UpdateCycleMutationPayload = {
  __typename?: 'UpdateCycleMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  cycleState?: Maybe<CycleState>;
  /**
   * エラーコード表
   * I00001: システムエラーです。お時間をおいてから再度お試しください。
   * I00010: リクエストが許可されていません
   * U00001: リクエスト内容が正しくありません
   * U00001: 生理開始日が同じデータがあります。別日を選択してください。
   * U00001: 生理開始日は、生年月日以降の日付を選択してください。
   * U00001: 生理開始日は、登録済の生理期間内には設定できません。登録済の生理情報を削除するか、別日を選択してください。
   * U00001: 生理終了日は、生理開始日以降の日付を選択してください。
   * U00001: 生理終了日は、登録済の生理期間内には設定できません。登録済の生理情報を削除するか、別日を選択してください。
   * U00001: 登録しようとした生理期間の間に、登録済の生理日があります。登録済の生理情報を削除するか、別日を選択してください。
   * U00020: IDトークンの有効期限が切れました。再度トークンを取得してください。
   * U00021: ユーザー情報が不足しています。ユーザー情報を登録してから再度お試しください
   * U00022: 該当リソースにはアクセスできません。
   */
  errors: Array<GraphqlError>;
};

/** Autogenerated input type of UpdateEcSubscriptionOrderMutation */
export type UpdateEcSubscriptionOrderMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** 定期配送ID */
  ecSubscriptionOrderId: Scalars['ID'];
  /** 郵送先情報を変更する際に利用 */
  ecUserShippingAddress?: Maybe<EcUserShippingAddressInput>;
  /** 決済手段を変更する際に利用 */
  payment?: Maybe<PaymentInput>;
};

/** Autogenerated return type of UpdateEcSubscriptionOrderMutation */
export type UpdateEcSubscriptionOrderMutationPayload = {
  __typename?: 'UpdateEcSubscriptionOrderMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** 定期配送 */
  ecSubscriptionOrder?: Maybe<EcSubscriptionOrder>;
  /**
   *   エラーコード表
   *   U00001: リクエスト内容が正しくありません
   *   update_ec_subscription_order_mutation.shipping_address_is_required: 配送先を入力してください。(郵便番号はハイフン無しで入力してください。）
   *   update_ec_subscription_order_mutation.not_permitted_for_corporate_benefit: 企業負担は支払い方法を変更できません
   *   update_ec_subscription_order_mutation.only_stripe_for_ec_corporate_benefit: 企業優待使用時はクレジットカード支払いのみ可能です
   *         ec_payment_task.invalid_payment_service_type: 非対応の支払い手段です。
   * ec_payment_task.settlement_error: (決済失敗時につきエラー内容は可変)。
   * ec_payment_task.create_error: 決済に失敗しました。お手数をおかけしますが、もう一度購入のお手続きをお願い致します。（何度も購入に失敗する場合は、恐れ入りますがお問い合わせいただけますようお願い致します。
   * ec_payment_task.cancel_subscription_error: AmazonPayのお支払い停止処理中にエラーが発生しました。しばらく時間を空けて再度お試しください。
   */
  errors: Array<GraphqlError>;
};

/** Autogenerated input type of UpdateEmailMutation */
export type UpdateEmailMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  newEmailAddress: Scalars['String'];
  platformType?: Maybe<PlatformType>;
};

/** Autogenerated return type of UpdateEmailMutation */
export type UpdateEmailMutationPayload = {
  __typename?: 'UpdateEmailMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /**
   * エラーコード表
   * U00001: リクエスト内容が正しくありません (その他エラーの際の汎用コード)
   * update_email.same_email_address_as_current_user: 今のメールアドレスと同じです。
   * update_email.same_email_address_as_another_user: 既に登録済みのメールアドレスです。
   */
  errors: Array<GraphqlError>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of UpdateNoteMutation */
export type UpdateNoteMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  noteAttributes: FemappNoteInput;
};

/** Autogenerated return type of UpdateNoteMutation */
export type UpdateNoteMutationPayload = {
  __typename?: 'UpdateNoteMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /**
   * エラーコード表
   * I00001: システムエラーです。お時間をおいてから再度お試しください。
   * I00010: リクエストが許可されていません
   * U00001: リクエスト内容が正しくありません
   * U00001: 日付が同じデータがあります。別日を選択してください。
   * U00001: 基礎体温は、32度〜42度の範囲で選択してください。
   * U00001: メモは、1000文字以内で入力してください
   * U00020: IDトークンの有効期限が切れました。再度トークンを取得してください。
   * U00022: 該当リソースにはアクセスできません。
   */
  errors: Array<GraphqlError>;
  note?: Maybe<Note>;
};

/** Autogenerated input type of UpdatePatientUserMutation */
export type UpdatePatientUserMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  userAttributes: UserInformationInput;
};

/** Autogenerated return type of UpdatePatientUserMutation */
export type UpdatePatientUserMutationPayload = {
  __typename?: 'UpdatePatientUserMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /**
   * エラーコード表
   * U00001: リクエスト内容が正しくありません (その他エラーの際の汎用コード)
   * user.family_name_kana.not_katakana: 受診者姓（全角カタカナ）は全角カタカナで入力してください
   * user.given_name_kana.not_katakana: 受診者名（全角カタカナ）は全角カタカナで入力してください
   * user.birthday_cannot_be_in_the_future: 生年月日が未来日となっています
   */
  errors: Array<GraphqlError>;
  user?: Maybe<User>;
};

/** Autogenerated input type of UpdatePushSettingMutation */
export type UpdatePushSettingMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  deviceId: Scalars['String'];
  id: Scalars['ID'];
  pushSettingAttributes: PushNotificationSettingInput;
};

/** Autogenerated return type of UpdatePushSettingMutation */
export type UpdatePushSettingMutationPayload = {
  __typename?: 'UpdatePushSettingMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /**
   * エラーコード表
   * I00001: システムエラーです。お時間をおいてから再度お試しください。
   * I00010: リクエストが許可されていません
   * U00001: リクエスト内容が正しくありません
   * U00022: 該当リソースにはアクセスできません。
   */
  errors: Array<GraphqlError>;
  pushNotificationSetting?: Maybe<PushNotificationSetting>;
};

/** Autogenerated input type of UpdateReminderMutation */
export type UpdateReminderMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  reminderAttributes: ReminderInput;
};

/** Autogenerated return type of UpdateReminderMutation */
export type UpdateReminderMutationPayload = {
  __typename?: 'UpdateReminderMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /**
   * エラーコード表
   * I00001: システムエラーです。お時間をおいてから再度お試しください。
   * I00010: リクエストが許可されていません
   * U00001: リクエスト内容が正しくありません
   * U00022: 該当リソースにはアクセスできません。
   */
  errors: Array<GraphqlError>;
  reminder?: Maybe<Reminder>;
};

/** Autogenerated input type of UpdateReservationMutation */
export type UpdateReservationMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  reservationAttributes: ReservationInput;
  reservationId: Scalars['ID'];
};

/** Autogenerated return type of UpdateReservationMutation */
export type UpdateReservationMutationPayload = {
  __typename?: 'UpdateReservationMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /**
   * エラーコード表
   * U00001: リクエスト内容が正しくありません (その他エラーの際の汎用コード)
   * reservation.exceeded_the_slot_capacity: 指定の予約時間は既に予約がいっぱいです。
   * reservation.reserved_with_the_past_date: 指定の予約時間を予約できませんでした。過去の予約を取ることはできません。
   * reservation.department_banned_within_period: 診療科は予約できない時間帯です
   * reservation.out_of_clinic_service: 指定の予約時間クリニックの営業期間外です
   * reservation.editable_time_limit_has_passed: 予約時刻前15分をすぎると変更・キャンセルできません。
   * reservation.allow_editing_with_order_status: こちらのご予約は変更できません。
   * update_reservation_mutation.cannot_be_changed_clinic: こちらのご予約はクリニックの変更ができません。
   */
  errors: Array<GraphqlError>;
  reservation?: Maybe<Reservation>;
};

/** Autogenerated input type of UpdateSelfCheckedMedicalInformationMutation */
export type UpdateSelfCheckedMedicalInformationMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  selfCheckedMedicalInformationAttributes: SelfCheckedMedicalInformationInput;
};

/** Autogenerated return type of UpdateSelfCheckedMedicalInformationMutation */
export type UpdateSelfCheckedMedicalInformationMutationPayload = {
  __typename?: 'UpdateSelfCheckedMedicalInformationMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /**
   * エラーコード表
   * U00001: リクエスト内容が正しくありません (その他エラーの際の汎用コード)
   * TODO
   */
  errors: Array<GraphqlError>;
  user?: Maybe<User>;
};

/** Autogenerated input type of UpdateSubscribedMailContentMutation */
export type UpdateSubscribedMailContentMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  subscribedMail: UserSubscribedMailInput;
};

/** Autogenerated return type of UpdateSubscribedMailContentMutation */
export type UpdateSubscribedMailContentMutationPayload = {
  __typename?: 'UpdateSubscribedMailContentMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /**
   * エラーコード表
   * U00001: リクエスト内容が正しくありません (その他エラーの際の汎用コード)
   */
  errors: Array<GraphqlError>;
  user?: Maybe<User>;
};

/** Autogenerated input type of UpdateTelemedicineInquiryMutation */
export type UpdateTelemedicineInquiryMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  input: TelemedicineInquiryInput;
};

/** Autogenerated return type of UpdateTelemedicineInquiryMutation */
export type UpdateTelemedicineInquiryMutationPayload = {
  __typename?: 'UpdateTelemedicineInquiryMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /**
   * エラーコード表
   * U00001: リクエスト内容が正しくありません
   */
  errors: Array<GraphqlError>;
  telemedicineInterview?: Maybe<TelemedicineInterview>;
};

/** Autogenerated input type of UpdateTelemedicineInterviewMutation */
export type UpdateTelemedicineInterviewMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  input: TelemedicineInterviewInput;
};

/** Autogenerated return type of UpdateTelemedicineInterviewMutation */
export type UpdateTelemedicineInterviewMutationPayload = {
  __typename?: 'UpdateTelemedicineInterviewMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /**
   * エラーコード表
   * U00001: リクエスト内容が正しくありません
   */
  errors: Array<GraphqlError>;
  telemedicineInterview?: Maybe<TelemedicineInterview>;
};

/** Autogenerated input type of UpdateUserBehaviorMutation */
export type UpdateUserBehaviorMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  userBehaviorAttributes: FemappUserBehaviorInput;
};

/** Autogenerated return type of UpdateUserBehaviorMutation */
export type UpdateUserBehaviorMutationPayload = {
  __typename?: 'UpdateUserBehaviorMutationPayload';
  behavior?: Maybe<UserBehavior>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /**
   * エラーコード表
   * I00001: システムエラーです。お時間をおいてから再度お試しください。
   * I00010: リクエストが許可されていません
   * U00001: リクエスト内容が正しくありません
   * U00022: 該当リソースにはアクセスできません。
   */
  errors: Array<GraphqlError>;
};

/** Autogenerated input type of UpdateUserInformationMutation */
export type UpdateUserInformationMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  subscribedMail: UserSubscribedMailInput;
  userAttributes: UserInformationInput;
};

/** Autogenerated return type of UpdateUserInformationMutation */
export type UpdateUserInformationMutationPayload = {
  __typename?: 'UpdateUserInformationMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /**
   * エラーコード表
   * U00001: リクエスト内容が正しくありません (その他エラーの際の汎用コード)
   * user.family_name_kana.not_katakana: 受診者姓（全角カタカナ）は全角カタカナで入力してください
   * user.given_name_kana.not_katakana: 受診者名（全角カタカナ）は全角カタカナで入力してください
   * user.birthday_cannot_be_in_the_future: 生年月日が未来日となっています
   */
  errors: Array<GraphqlError>;
  user?: Maybe<User>;
};

/** Autogenerated input type of UpdateUserMutation */
export type UpdateUserMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  userAttributes: FemappUserInput;
};

/** Autogenerated return type of UpdateUserMutation */
export type UpdateUserMutationPayload = {
  __typename?: 'UpdateUserMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /**
   * エラーコード表
   * I00001: システムエラーです。お時間をおいてから再度お試しください。
   * I00010: リクエストが許可されていません
   * U00001: リクエスト内容が正しくありません
   * U00001: 生理の周期は、生理の長さよりも長い日にちを選択してください。
   * U00001: 生理の周期は、15日〜150日の範囲で選択してください。
   * U00001: 生理の周期は、1日〜14日の範囲で選択してください。
   * U00020: IDトークンの有効期限が切れました。再度トークンを取得してください。)
   */
  errors: Array<GraphqlError>;
  user?: Maybe<FemappUser>;
};

/** Autogenerated input type of UpsertCosmeticAdditionalInterviewAnswerMutation */
export type UpsertCosmeticAdditionalInterviewAnswerMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  cosmeticAdditionalInterviewAnswer: CosmeticAdditionalInterviewAnswerInput;
};

/** Autogenerated return type of UpsertCosmeticAdditionalInterviewAnswerMutation */
export type UpsertCosmeticAdditionalInterviewAnswerMutationPayload = {
  __typename?: 'UpsertCosmeticAdditionalInterviewAnswerMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  cosmeticAdditionalInterviewAnswer?: Maybe<CosmeticAdditionalInterviewAnswer>;
  /**
   * エラーコード表
   * U00001: リクエスト内容が正しくありません
   * U00002: ただいま混み合っております。お時間をおいてから再度お試しください。
   */
  errors: Array<GraphqlError>;
};

/** Autogenerated input type of UpsertMedicalCheckupAdditionalInterviewAnswerMutation */
export type UpsertMedicalCheckupAdditionalInterviewAnswerMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  medicalCheckupAdditionalInterviewAnswer: MedicalCheckupAdditionalInterviewAnswerInput;
};

/** Autogenerated return type of UpsertMedicalCheckupAdditionalInterviewAnswerMutation */
export type UpsertMedicalCheckupAdditionalInterviewAnswerMutationPayload = {
  __typename?: 'UpsertMedicalCheckupAdditionalInterviewAnswerMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /**
   * エラーコード表
   * U00001: リクエスト内容が正しくありません
   * U00002: ただいま混み合っております。お時間をおいてから再度お試しください。
   */
  errors: Array<GraphqlError>;
  medicalCheckupAdditionalInterviewAnswer?: Maybe<MedicalCheckupAdditionalInterviewAnswer>;
};

/** Autogenerated input type of UpsertOpenIdConnectionMutation */
export type UpsertOpenIdConnectionMutationInput = {
  accessToken: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of UpsertOpenIdConnectionMutation */
export type UpsertOpenIdConnectionMutationPayload = {
  __typename?: 'UpsertOpenIdConnectionMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /**
   * エラーコード表
   * U00001: リクエスト内容が正しくありません (その他エラーの際の汎用コード)
   */
  errors: Array<GraphqlError>;
  user?: Maybe<User>;
};

/** Autogenerated input type of UpsertPcrAbroadInterviewMutation */
export type UpsertPcrAbroadInterviewMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  pcrAbroadInterview: PcrAbroadInterviewInput;
};

/** Autogenerated return type of UpsertPcrAbroadInterviewMutation */
export type UpsertPcrAbroadInterviewMutationPayload = {
  __typename?: 'UpsertPcrAbroadInterviewMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /**
   * エラーコード表
   * U00001: リクエスト内容が正しくありません
   * U00002: ただいま混み合っております。お時間をおいてから再度お試しください。
   */
  errors: Array<GraphqlError>;
  pcrAbroadInterview?: Maybe<PcrAbroadInterview>;
};

/** [public]  */
export type UseableEcCoupon = {
  __typename?: 'UseableEcCoupon';
  /** [public]  */
  ecCorporateBenefit?: Maybe<EcCorporateBenefit>;
  /** [public]  */
  ecCoupon?: Maybe<EcCoupon>;
  /**
   * [public]           エラーコード表
   *           U00001: リクエスト内容が正しくありません (その他エラーの際の汎用コード)
   *           useable_ec_coupon.invalid_code_error: このクーポンコードは正しくありません。
   *               ec_order_useable_coupon_validator.valid_ec_coupon.expired: 入力されたクーポンは期限切れです
   *     ec_order_useable_coupon_validator.valid_ec_coupon.already_used: このクーポンは使用済みです
   *     ec_order_useable_coupon_validator.valid_ec_coupon.not_target_category: このクーポンはこちらの診療科では利用できません
   *     ec_order_useable_coupon_validator.valid_ec_coupon.not_target_product: 割引対象の商品が選択されていません。
   *     ec_order_useable_coupon_validator.valid_ec_coupon.unusable: このクーポンはご利用いただけません。
   *     ec_order_useable_coupon_validator.valid_ec_coupon_department: 保険診療では企業優待のみご利用可能です。
   *     ec_order_useable_coupon_validator.valid_ec_corporate_benefit.expired: 入力されたクーポンは期限切れです
   */
  errors: Array<GraphqlError>;
};

/** [public]  */
export type UseableExamCoupon = {
  __typename?: 'UseableExamCoupon';
  /**
   * [public]           エラーコード表
   *           U00001: リクエスト内容が正しくありません (その他エラーの際の汎用コード)
   *           useable_exam_coupon.invalid_code_error: このクーポンコードは正しくありません。
   *               exam_order_useable_coupon_validator.valid_exam_coupon.expired: 入力されたクーポンは期限切れです
   *     exam_order_useable_coupon_validator.valid_exam_coupon.already_used: このクーポンは使用済みです
   *     exam_order_useable_coupon_validator.valid_exam_coupon.not_target_category: このクーポンはこちらの検査では利用できません
   *     exam_order_useable_coupon_validator.valid_exam_coupon.not_target_product: 割引対象の商品が選択されていません。
   *     exam_order_useable_coupon_validator.valid_exam_coupon.unusable: このクーポンはご利用いただけません。
   */
  errors: Array<GraphqlError>;
  /** [public]  */
  examCoupon?: Maybe<ExamCoupon>;
};

/** [public]  */
export type User = {
  __typename?: 'User';
  /** [public]  */
  acceptsFeedbackAsk: Scalars['Boolean'];
  /** [public]  */
  additionalInterviewAnswer?: Maybe<AdditionalUserInterviewAnswer>;
  /** [public]  */
  addressPostcode?: Maybe<Scalars['String']>;
  /** [public]  */
  addressPrefectureId?: Maybe<Scalars['Int']>;
  /** [public]  */
  addressPrefectureName?: Maybe<Scalars['String']>;
  /** [public]  */
  addressStreet?: Maybe<Scalars['String']>;
  /** [public] アレルギー検査結果一覧 */
  allergySrlLabResults: SrlLabResultConnection;
  /** [public]  */
  birthday?: Maybe<Scalars['DateTime']>;
  /** [public] 血液検査結果一覧 */
  bloodSrlLabResults: SrlLabResultConnection;
  /** [public] 最後に利用した配送先住所 */
  currentShippingAddress?: Maybe<EcUserShippingAddress>;
  /** [public] 過去の注文履歴が存在するかを確認 */
  ecOrderProductRecord: Scalars['Boolean'];
  /** [public] オンライン診療の注文内容 */
  ecOrders: EcOrderConnection;
  /** [public] 定期配送 */
  ecSubscriptionOrders: EcSubscriptionOrderConnection;
  /** [public]  */
  email?: Maybe<Scalars['String']>;
  /** [public] 血液検査結果 */
  examBloodResults?: Maybe<ExamBloodResultConnection>;
  /** [public] オンライン検査の最後に利用した配送先住所 */
  examCurrentShippingAddress?: Maybe<ExamUserShippingAddress>;
  /** [public] HPV検査結果 */
  examHpvResults?: Maybe<ExamHpvResultConnection>;
  /** [public] オンライン検査の注文内容 */
  examOrders: ExamOrderConnection;
  /** [public] 性感染症検査結果 */
  examStdResults?: Maybe<ExamStdResultConnection>;
  /** [public]  */
  familyName?: Maybe<Scalars['String']>;
  /** [public]  */
  familyNameKana?: Maybe<Scalars['String']>;
  /** [public]  */
  givenName?: Maybe<Scalars['String']>;
  /** [public]  */
  givenNameKana?: Maybe<Scalars['String']>;
  /** [public] LINE公式アカウントID連携済みか否か */
  hasLineOfficialAccountUser: Scalars['Boolean'];
  /** [public]  */
  id: Scalars['ID'];
  /** [public] 保険証 */
  insuranceCard?: Maybe<InsuranceCard>;
  /** [public] 前回受診したオンライン診療科 */
  lastVisitedTelemedicineDepartment?: Maybe<Department>;
  /** [public] 支払い方法 */
  paymentMethods: Array<PaymentMethod>;
  /** [public] ポイント履歴 */
  points: PointConnection;
  /** [public] 前回受診したクリニック */
  recentReservedClinicStore?: Maybe<Clinic>;
  /** [public] 予約一覧 */
  reservations: ReservationConnection;
  /** [public]  */
  selfCheckedMedicalInformation?: Maybe<SelfCheckedMedicalInformation>;
  /** [public]  */
  sex?: Maybe<UserSex>;
  /** [public] 対面STD検査を受けたことがあるか */
  stdExamined: Scalars['Boolean'];
  /** [public] 学生証 */
  studentIdCard?: Maybe<StudentIdCard>;
  /** [public] 受信設定 */
  subscribedMailContent?: Maybe<SubscribedMailContent>;
  /** [public]  */
  telNumber?: Maybe<Scalars['String']>;
  /** [public]  */
  tocAgreedAt?: Maybe<Scalars['DateTime']>;
  /** [public]  */
  unconfirmedEmail?: Maybe<Scalars['String']>;
  /** [public]  */
  userInvitationCode: UserInvitationCode;
  /** [public]  */
  userInvitedCode?: Maybe<UserInvitedCode>;
  /** [public] 患者データ */
  userOrcaPatients: Array<UserOrcaPatient>;
  /** [public] 利用可能ポイント */
  userPointSummary: UserPointSummary;
  /** [public]  */
  userUuid: UserUuid;
  /** [public] パスワード未設定ユーザーか否か */
  withoutPassword: Scalars['Boolean'];
};

/** [public]  */
export type UserAllergySrlLabResultsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** [public]  */
export type UserBloodSrlLabResultsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** [public]  */
export type UserEcOrderProductRecordArgs = {
  departmentName?: Maybe<Scalars['String']>;
};

/** [public]  */
export type UserEcOrdersArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** [public]  */
export type UserEcSubscriptionOrdersArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  status?: Maybe<Array<Maybe<EcSubscriptionOrderStatus>>>;
};

/** [public]  */
export type UserExamBloodResultsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  ids?: Maybe<Array<Scalars['Int']>>;
  last?: Maybe<Scalars['Int']>;
};

/** [public]  */
export type UserExamHpvResultsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  ids?: Maybe<Array<Scalars['Int']>>;
  last?: Maybe<Scalars['Int']>;
};

/** [public]  */
export type UserExamOrdersArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** [public]  */
export type UserExamStdResultsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  ids?: Maybe<Array<Scalars['Int']>>;
  last?: Maybe<Scalars['Int']>;
};

/** [public]  */
export type UserPointsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** [public]  */
export type UserReservationsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  sort?: Maybe<ReservationSort>;
  started?: Maybe<ReservationStarted>;
  status?: Maybe<Array<Maybe<ReservationStatus>>>;
};

/** [public]  */
export type UserBehavior = Node & {
  __typename?: 'UserBehavior';
  /** [public]  */
  behaviorType: FemappUserBehavior;
  /** [public]  */
  datetime?: Maybe<Scalars['DateTime']>;
  /** [public]  */
  id: Scalars['ID'];
};

export type UserInformationInput = {
  acceptsFeedbackAsk?: Maybe<Scalars['Boolean']>;
  addressPostcode?: Maybe<Scalars['String']>;
  addressPrefectureName?: Maybe<Scalars['String']>;
  addressStreet?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['DateTime']>;
  familyName?: Maybe<Scalars['String']>;
  familyNameKana?: Maybe<Scalars['String']>;
  givenName?: Maybe<Scalars['String']>;
  givenNameKana?: Maybe<Scalars['String']>;
  sex?: Maybe<UserSex>;
  telNumber?: Maybe<Scalars['String']>;
  tocAgreedAt?: Maybe<Scalars['DateTime']>;
};

/** [public]  */
export type UserInvitationCode = {
  __typename?: 'UserInvitationCode';
  /** [public]  */
  id: Scalars['ID'];
  /** [public]  */
  invitationCode: Scalars['String'];
};

/** [public]  */
export type UserInvitedCode = {
  __typename?: 'UserInvitedCode';
  /** [public]  */
  id: Scalars['ID'];
  /** [public]  */
  purchasedAt?: Maybe<Scalars['DateTime']>;
  /** [public]  */
  registeredAt?: Maybe<Scalars['DateTime']>;
  /** [public]  */
  status: UserInvitedCodeStatus;
  /** [public]  */
  userInvitationCode: UserInvitationCode;
};

export enum UserInvitedCodeStatus {
  /** 決済済 */
  Purchased = 'PURCHASED',
  /** 登録済 */
  Registered = 'REGISTERED',
}

/** [public]  */
export type UserOrcaPatient = {
  __typename?: 'UserOrcaPatient';
  /** [public]  */
  clinic: Clinic;
  /** [public]  */
  id: Scalars['ID'];
  /** [public]  */
  orcaPatientId: Scalars['String'];
};

/** [public]  */
export type UserPointSummary = {
  __typename?: 'UserPointSummary';
  /** [public]  */
  limitedPoint: Scalars['Int'];
  /** [public]  */
  pointsByUsageExpiration: Array<PointAggregatedByUsageExpiration>;
  /** [public]  */
  totalPoint: Scalars['Int'];
};

/** [public]  */
export type UserReservable = {
  __typename?: 'UserReservable';
  /**
   * [public]           エラーコード表
   *           U00001: リクエスト内容が正しくありません (その他エラーの際の汎用コード)
   *           reservation.over_the_reservation_limit: 指定の予約時間を予約できませんでした。お一人当たりの予約数が上限に達しています。
   *           reservation.reach_reserving_limit_in_a_day: 指定の予約時間を予約できませんでした。同日の予約上限数に達しています。
   */
  errors: Array<GraphqlError>;
  /** [public] deprecated */
  messages: Array<Scalars['String']>;
  /** [public]  */
  reservable: Scalars['Boolean'];
};

export enum UserSex {
  /** 女性 */
  Female = 'female',
  /** 男性 */
  Male = 'male',
}

export type UserSubscribedMailInput = {
  includesClinicInfo?: Maybe<Scalars['Boolean']>;
  includesHealthcareInfo?: Maybe<Scalars['Boolean']>;
  includesLineNotification?: Maybe<Scalars['Boolean']>;
  includesManInfo?: Maybe<Scalars['Boolean']>;
  includesPlatformNewsletter?: Maybe<Scalars['Boolean']>;
  includesPromotionInfo?: Maybe<Scalars['Boolean']>;
  includesWomanInfo?: Maybe<Scalars['Boolean']>;
};

/** [public]  */
export type UserUuid = {
  __typename?: 'UserUuid';
  /** [public]  */
  id: Scalars['ID'];
  /** [public]  */
  uuid: Scalars['String'];
};

/** [public]  */
export type UsersCountInTerm = {
  __typename?: 'UsersCountInTerm';
  /** [public]  */
  newUsersCount: Scalars['Int'];
  /** [public]  */
  termOn: Scalars['Date'];
  /** [public]  */
  totalUsersCount: Scalars['Int'];
  /** [public]  */
  withdrawedUsersCount: Scalars['Int'];
};

/** [public]  */
export type VideoChatSession = {
  __typename?: 'VideoChatSession';
  /** [public]  */
  sessionId: Scalars['String'];
  /** [public]  */
  token: Scalars['String'];
};

/** [user]  */
export type VitalRecord = {
  __typename?: 'VitalRecord';
  /** [public]  */
  clinic: Clinic;
  /** [public]  */
  collectionDate: Scalars['Date'];
  /** [public]  */
  id: Scalars['ID'];
  /** [public]  */
  orcaPatientId: Scalars['Int'];
  /** [public]  */
  srlLabResult?: Maybe<SrlLabResult>;
  /** [public]  */
  standardValueMax?: Maybe<Scalars['Float']>;
  /** [public]  */
  standardValueMin?: Maybe<Scalars['Float']>;
  /** [public]  */
  value: Scalars['Float'];
  /** [public]  */
  vitalRecordType: Scalars['String'];
};

export enum VitalRecordType {
  HdlCholesterol = 'HDL_CHOLESTEROL',
  Hemoglobin = 'HEMOGLOBIN',
  LdlCholesterol = 'LDL_CHOLESTEROL',
  TotalCholesterol = 'TOTAL_CHOLESTEROL',
  Triglycerides = 'TRIGLYCERIDES',
}

/** [public]  */
export type WaitingTimeByHour = {
  __typename?: 'WaitingTimeByHour';
  /** [public]  */
  reservationsCount: Scalars['Int'];
  /** [public]  */
  reservedHour: Scalars['Int'];
  /** [public]  */
  reservedOn: Scalars['Date'];
  /** [public]  */
  waitingMinutesAvg: Scalars['Float'];
};

export enum WithdrawalReason {
  /** アカウントが重複したので削除したい */
  AccountDuplicated = 'account_duplicated',
  /** 他の受診機関を利用することになったため */
  Competitor = 'competitor',
  /** 料金が高いと感じたため */
  Expense = 'expense',
  /** その他 */
  Other = 'other',
  /** 完治したなどの理由で受診する必要がなくなったため */
  Recovered = 'recovered',
  /** サービス内容に満足できなかったため */
  Service = 'service',
  /** 機能や使い勝手に満足できなかったため */
  System = 'system',
}

/** Autogenerated input type of WithdrawalRequestsMutation */
export type WithdrawalRequestsMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  feedbackComment: Scalars['String'];
  withdrawalReasonType: WithdrawalReason;
};

/** Autogenerated return type of WithdrawalRequestsMutation */
export type WithdrawalRequestsMutationPayload = {
  __typename?: 'WithdrawalRequestsMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /**
   *         エラーコード表
   *         U00001: リクエスト内容が正しくありません (その他エラーの際の汎用コード)
   *         U00003: 発送が完了していないお薬があるため、退会処理を実行することができません
   *         users.withdrawal_requests.create.ec_subscriptions_remain:
   * 継続中の定期購入商品がございます。お手数をおかけしますが、先に各サービスページから定期をキャンセルした後、再度退会の申し込みを行なってください。
   */
  errors: Array<GraphqlError>;
  success: Scalars['Boolean'];
};

export enum YamatoCool {
  Refrigerated = 'refrigerated',
}

export type NoteInfoFragment = { __typename?: 'Note' } & Pick<
  Note,
  | 'id'
  | 'date'
  | 'flowAmount'
  | 'dischargeAmount'
  | 'dischargeCondition'
  | 'sexualActivity'
  | 'bodyTemperature'
  | 'memo'
> & {
    physicalConditions: Array<
      { __typename?: 'PhysicalCondition' } & Pick<PhysicalCondition, 'name'>
    >;
    mentalConditions: Array<{ __typename?: 'MentalCondition' } & Pick<MentalCondition, 'name'>>;
  };

export type UserBehaviorInfoFragment = { __typename?: 'UserBehavior' } & Pick<
  UserBehavior,
  'id' | 'behaviorType' | 'datetime'
>;

export type CreateCycleMutationVariables = Exact<{
  cycleAttributes: FemappCycleInput;
}>;

export type CreateCycleMutation = { __typename?: 'Mutation' } & {
  femappCreateCycle?: Maybe<
    { __typename?: 'CreateCycleMutationPayload' } & {
      cycleState?: Maybe<
        { __typename?: 'CycleState' } & Pick<
          CycleState,
          'status' | 'stage' | 'statusWhenReadingSummary' | 'stageWhenReadingSummary'
        > & {
            cycles: Array<
              { __typename?: 'Cycle' } & Pick<
                Cycle,
                | 'id'
                | 'periodStartOn'
                | 'periodLength'
                | 'ovulationScheduledOn'
                | 'pmsStartOn'
                | 'pmsEndOn'
              >
            >;
          }
      >;
      errors: Array<
        { __typename?: 'GraphqlError' } & Pick<GraphqlError, 'message'> & {
            extensions: { __typename?: 'Extension' } & Pick<
              Extension,
              'code' | 'domain' | 'appCode'
            >;
          }
      >;
    }
  >;
};

export type CreateNoteMutationVariables = Exact<{
  noteAttributes: FemappNoteInput;
}>;

export type CreateNoteMutation = { __typename?: 'Mutation' } & {
  femappCreateNote?: Maybe<
    { __typename?: 'CreateNoteMutationPayload' } & {
      note?: Maybe<
        { __typename?: 'Note' } & Pick<
          Note,
          | 'id'
          | 'date'
          | 'flowAmount'
          | 'dischargeAmount'
          | 'dischargeCondition'
          | 'sexualActivity'
          | 'pillIntake'
          | 'bodyTemperature'
          | 'memo'
        > & {
            physicalConditions: Array<
              { __typename?: 'PhysicalCondition' } & Pick<PhysicalCondition, 'name'>
            >;
            mentalConditions: Array<
              { __typename?: 'MentalCondition' } & Pick<MentalCondition, 'name'>
            >;
          }
      >;
      errors: Array<
        { __typename?: 'GraphqlError' } & Pick<GraphqlError, 'message'> & {
            extensions: { __typename?: 'Extension' } & Pick<
              Extension,
              'code' | 'domain' | 'appCode'
            >;
          }
      >;
    }
  >;
};

export type CreateUserBehaviorMutationVariables = Exact<{
  userBehaviorAttributes: FemappUserBehaviorInput;
}>;

export type CreateUserBehaviorMutation = { __typename?: 'Mutation' } & {
  femappCreateUserBehavior?: Maybe<
    { __typename?: 'CreateUserBehaviorMutationPayload' } & {
      behavior?: Maybe<
        { __typename?: 'UserBehavior' } & Pick<UserBehavior, 'id' | 'behaviorType' | 'datetime'>
      >;
      errors: Array<
        { __typename?: 'GraphqlError' } & Pick<GraphqlError, 'message'> & {
            extensions: { __typename?: 'Extension' } & Pick<
              Extension,
              'code' | 'domain' | 'appCode'
            >;
          }
      >;
    }
  >;
};

export type DeleteCycleMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeleteCycleMutation = { __typename?: 'Mutation' } & {
  femappDeleteCycle?: Maybe<
    { __typename?: 'DeleteCycleMutationPayload' } & {
      cycleState?: Maybe<
        { __typename?: 'CycleState' } & Pick<
          CycleState,
          'status' | 'stage' | 'statusWhenReadingSummary' | 'stageWhenReadingSummary'
        > & {
            cycles: Array<
              { __typename?: 'Cycle' } & Pick<
                Cycle,
                | 'id'
                | 'periodStartOn'
                | 'periodLength'
                | 'ovulationScheduledOn'
                | 'pmsStartOn'
                | 'pmsEndOn'
              >
            >;
          }
      >;
      errors: Array<
        { __typename?: 'GraphqlError' } & Pick<GraphqlError, 'message'> & {
            extensions: { __typename?: 'Extension' } & Pick<
              Extension,
              'code' | 'domain' | 'appCode'
            >;
          }
      >;
    }
  >;
};

export type DeleteNoteMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeleteNoteMutation = { __typename?: 'Mutation' } & {
  femappDeleteNote?: Maybe<
    { __typename?: 'DeleteNoteMutationPayload' } & Pick<DeleteNoteMutationPayload, 'id'> & {
        errors: Array<
          { __typename?: 'GraphqlError' } & Pick<GraphqlError, 'message'> & {
              extensions: { __typename?: 'Extension' } & Pick<
                Extension,
                'code' | 'domain' | 'appCode'
              >;
            }
        >;
      }
  >;
};

export type SignUpV2MutationVariables = Exact<{
  clientMutationId: Scalars['String'];
}>;

export type SignUpV2Mutation = { __typename?: 'Mutation' } & {
  femappSignUpForV2?: Maybe<
    { __typename?: 'SignUpForV2MutationPayload' } & Pick<
      SignUpForV2MutationPayload,
      'isSuccess'
    > & {
        errors: Array<
          { __typename?: 'GraphqlError' } & Pick<GraphqlError, 'message'> & {
              extensions: { __typename?: 'Extension' } & Pick<
                Extension,
                'code' | 'domain' | 'appCode'
              >;
            }
        >;
      }
  >;
};

export type UpdateAllCyclesMutationVariables = Exact<{
  cyclesAttributes: Array<FemappCycleInput> | FemappCycleInput;
}>;

export type UpdateAllCyclesMutation = { __typename?: 'Mutation' } & {
  femappUpdateAllCycles?: Maybe<
    { __typename?: 'UpdateAllCyclesMutationPayload' } & {
      cycleState?: Maybe<
        { __typename?: 'CycleState' } & Pick<
          CycleState,
          'status' | 'stage' | 'statusWhenReadingSummary' | 'stageWhenReadingSummary'
        > & {
            cycles: Array<
              { __typename?: 'Cycle' } & Pick<
                Cycle,
                | 'id'
                | 'periodStartOn'
                | 'periodLength'
                | 'ovulationScheduledOn'
                | 'pmsStartOn'
                | 'pmsEndOn'
              >
            >;
          }
      >;
      errors: Array<
        { __typename?: 'GraphqlError' } & Pick<GraphqlError, 'message'> & {
            extensions: { __typename?: 'Extension' } & Pick<
              Extension,
              'code' | 'domain' | 'appCode'
            >;
          }
      >;
    }
  >;
};

export type UpdateNoteMutationVariables = Exact<{
  id: Scalars['ID'];
  noteAttributes: FemappNoteInput;
}>;

export type UpdateNoteMutation = { __typename?: 'Mutation' } & {
  femappUpdateNote?: Maybe<
    { __typename?: 'UpdateNoteMutationPayload' } & {
      note?: Maybe<
        { __typename?: 'Note' } & Pick<
          Note,
          | 'id'
          | 'date'
          | 'flowAmount'
          | 'dischargeAmount'
          | 'dischargeCondition'
          | 'sexualActivity'
          | 'pillIntake'
          | 'bodyTemperature'
          | 'memo'
        > & {
            physicalConditions: Array<
              { __typename?: 'PhysicalCondition' } & Pick<PhysicalCondition, 'name'>
            >;
            mentalConditions: Array<
              { __typename?: 'MentalCondition' } & Pick<MentalCondition, 'name'>
            >;
          }
      >;
      errors: Array<
        { __typename?: 'GraphqlError' } & Pick<GraphqlError, 'message'> & {
            extensions: { __typename?: 'Extension' } & Pick<
              Extension,
              'code' | 'domain' | 'appCode'
            >;
          }
      >;
    }
  >;
};

export type UpdateUserMutationVariables = Exact<{
  userAttributes: FemappUserInput;
}>;

export type UpdateUserMutation = { __typename?: 'Mutation' } & {
  femappUpdateUser?: Maybe<
    { __typename?: 'UpdateUserMutationPayload' } & {
      user?: Maybe<
        { __typename?: 'FemappUser' } & Pick<
          FemappUser,
          | 'id'
          | 'birthday'
          | 'email'
          | 'regularCycleLength'
          | 'regularPeriodLength'
          | 'hasPillPlugin'
          | 'hasPillNotification'
          | 'pillRemindedAt'
          | 'sheetStartDate'
          | 'hasPlaceboPeriodNotification'
          | 'hasPillSheetImage'
          | 'hasEmotionalReminderMessage'
          | 'hasAcceptedAccountLinking'
          | 'hasClinicforUser'
          | 'hasReservationsFromLineMiniapp'
        > & {
            pill?: Maybe<
              { __typename?: 'Pill' } & Pick<
                Pill,
                'id' | 'name' | 'activeDrugSize' | 'placeboDrugSize'
              >
            >;
            pillClinic?: Maybe<{ __typename?: 'PillClinic' } & Pick<PillClinic, 'id' | 'name'>>;
          }
      >;
      errors: Array<
        { __typename?: 'GraphqlError' } & Pick<GraphqlError, 'message'> & {
            extensions: { __typename?: 'Extension' } & Pick<
              Extension,
              'code' | 'domain' | 'appCode'
            >;
          }
      >;
    }
  >;
};

export type UpdateUserBehaviorMutationVariables = Exact<{
  id: Scalars['ID'];
  userBehaviorAttributes: FemappUserBehaviorInput;
}>;

export type UpdateUserBehaviorMutation = { __typename?: 'Mutation' } & {
  femappUpdateUserBehavior?: Maybe<
    { __typename?: 'UpdateUserBehaviorMutationPayload' } & {
      behavior?: Maybe<
        { __typename?: 'UserBehavior' } & Pick<UserBehavior, 'id' | 'behaviorType' | 'datetime'>
      >;
      errors: Array<
        { __typename?: 'GraphqlError' } & Pick<GraphqlError, 'message'> & {
            extensions: { __typename?: 'Extension' } & Pick<
              Extension,
              'code' | 'domain' | 'appCode'
            >;
          }
      >;
    }
  >;
};

export type InsightQueryVariables = Exact<{
  status: FemappPhaseStatus;
  stage: FemappPhaseStage;
}>;

export type InsightQuery = { __typename?: 'Query' } & {
  femappInsight: { __typename?: 'Insight' } & Pick<Insight, 'status' | 'stage'> & {
      advices: Array<{ __typename?: 'Advice' } & Pick<Advice, 'viewpoint' | 'heading' | 'content'>>;
      articles: Array<
        { __typename?: 'Article' } & Pick<
          Article,
          | 'title'
          | 'subtitle'
          | 'heading'
          | 'content'
          | 'urlAddition'
          | 'articleUrl'
          | 'thumbnailUrl'
        >
      >;
    };
};

export type PillQueryVariables = Exact<{ [key: string]: never }>;

export type PillQuery = { __typename?: 'Query' } & {
  femappPill: Array<
    { __typename?: 'Pill' } & Pick<Pill, 'id' | 'name' | 'activeDrugSize' | 'placeboDrugSize'>
  >;
};

export type PillClinicQueryVariables = Exact<{ [key: string]: never }>;

export type PillClinicQuery = { __typename?: 'Query' } & {
  femappPillClinic: Array<{ __typename?: 'PillClinic' } & Pick<PillClinic, 'id' | 'name'>>;
};

export type PillInsightQueryVariables = Exact<{ [key: string]: never }>;

export type PillInsightQuery = { __typename?: 'Query' } & {
  femappPillInsight: Array<
    { __typename?: 'PillInsight' } & Pick<PillInsight, 'sheetNumber' | 'tabletNumber'> & {
        general: { __typename?: 'GeneralInsight' } & Pick<GeneralInsight, 'description'>;
        beauty: { __typename?: 'BeautyInsight' } & Pick<
          BeautyInsight,
          'title' | 'subtitle' | 'image' | 'description'
        >;
        diet: { __typename?: 'DietInsight' } & Pick<DietInsight, 'name' | 'image' | 'description'>;
      }
  >;
};

export type PillInsightMinQueryVariables = Exact<{ [key: string]: never }>;

export type PillInsightMinQuery = { __typename?: 'Query' } & {
  femappPillInsight: Array<{ __typename?: 'PillInsight' } & Pick<PillInsight, 'sheetNumber'>>;
};

export type UserQueryVariables = Exact<{
  noteDateFrom: Scalars['Date'];
  noteDateTo: Scalars['Date'];
}>;

export type UserQuery = { __typename?: 'Query' } & {
  femappUser?: Maybe<
    { __typename?: 'FemappUser' } & Pick<
      FemappUser,
      | 'id'
      | 'displayedId'
      | 'birthday'
      | 'email'
      | 'regularCycleLength'
      | 'regularPeriodLength'
      | 'hasPillPlugin'
      | 'hasPillNotification'
      | 'pillRemindedAt'
      | 'sheetStartDate'
      | 'hasPlaceboPeriodNotification'
      | 'hasPillSheetImage'
      | 'hasEmotionalReminderMessage'
      | 'hasAcceptedAccountLinking'
      | 'hasClinicforUser'
      | 'hasReservationsFromLineMiniapp'
    > & {
        behaviors: Array<
          { __typename?: 'UserBehavior' } & Pick<UserBehavior, 'id' | 'behaviorType' | 'datetime'>
        >;
        cycleState: { __typename?: 'CycleState' } & Pick<
          CycleState,
          'status' | 'stage' | 'statusWhenReadingSummary' | 'stageWhenReadingSummary'
        > & {
            cycles: Array<
              { __typename?: 'Cycle' } & Pick<
                Cycle,
                | 'id'
                | 'periodStartOn'
                | 'periodLength'
                | 'ovulationScheduledOn'
                | 'pmsStartOn'
                | 'pmsEndOn'
              >
            >;
          };
        notes: Array<
          { __typename?: 'Note' } & Pick<
            Note,
            | 'id'
            | 'date'
            | 'flowAmount'
            | 'dischargeAmount'
            | 'dischargeCondition'
            | 'sexualActivity'
            | 'pillIntake'
            | 'bodyTemperature'
            | 'memo'
          > & {
              physicalConditions: Array<
                { __typename?: 'PhysicalCondition' } & Pick<PhysicalCondition, 'name'>
              >;
              mentalConditions: Array<
                { __typename?: 'MentalCondition' } & Pick<MentalCondition, 'name'>
              >;
            }
        >;
        pill?: Maybe<
          { __typename?: 'Pill' } & Pick<
            Pill,
            | 'id'
            | 'name'
            | 'activeDrugSize'
            | 'placeboDrugSize'
            | 'description'
            | 'canContinuousIntake'
          >
        >;
        pillClinic?: Maybe<{ __typename?: 'PillClinic' } & Pick<PillClinic, 'id' | 'name'>>;
      }
  >;
};

export type UserCycleQueryVariables = Exact<{ [key: string]: never }>;

export type UserCycleQuery = { __typename?: 'Query' } & {
  femappUser?: Maybe<
    { __typename?: 'FemappUser' } & Pick<FemappUser, 'id'> & {
        cycleState: { __typename?: 'CycleState' } & Pick<
          CycleState,
          'status' | 'stage' | 'statusWhenReadingSummary' | 'stageWhenReadingSummary'
        > & {
            cycles: Array<
              { __typename?: 'Cycle' } & Pick<
                Cycle,
                | 'id'
                | 'periodStartOn'
                | 'periodLength'
                | 'ovulationScheduledOn'
                | 'pmsStartOn'
                | 'pmsEndOn'
              >
            >;
          };
      }
  >;
};

export type UserNoteQueryVariables = Exact<{
  noteDateFrom: Scalars['Date'];
  noteDateTo: Scalars['Date'];
}>;

export type UserNoteQuery = { __typename?: 'Query' } & {
  femappUser?: Maybe<
    { __typename?: 'FemappUser' } & {
      notes: Array<
        { __typename?: 'Note' } & Pick<
          Note,
          | 'id'
          | 'date'
          | 'flowAmount'
          | 'dischargeAmount'
          | 'dischargeCondition'
          | 'sexualActivity'
          | 'pillIntake'
          | 'bodyTemperature'
          | 'memo'
        > & {
            physicalConditions: Array<
              { __typename?: 'PhysicalCondition' } & Pick<PhysicalCondition, 'name'>
            >;
            mentalConditions: Array<
              { __typename?: 'MentalCondition' } & Pick<MentalCondition, 'name'>
            >;
          }
      >;
    }
  >;
};

export const NoteInfoFragmentDoc = gql`
  fragment noteInfo on Note {
    id
    date
    flowAmount
    dischargeAmount
    dischargeCondition
    sexualActivity
    physicalConditions {
      name
    }
    mentalConditions {
      name
    }
    bodyTemperature
    memo
  }
`;
export const UserBehaviorInfoFragmentDoc = gql`
  fragment userBehaviorInfo on UserBehavior {
    id
    behaviorType
    datetime
  }
`;
export const CreateCycleDocument = gql`
  mutation CreateCycle($cycleAttributes: FemappCycleInput!) {
    femappCreateCycle(input: { cycleAttributes: $cycleAttributes }) {
      cycleState {
        status
        stage
        statusWhenReadingSummary
        stageWhenReadingSummary
        cycles {
          id
          periodStartOn
          periodLength
          ovulationScheduledOn
          pmsStartOn
          pmsEndOn
        }
      }
      errors {
        message
        extensions {
          code
          domain
          appCode
        }
      }
    }
  }
`;
export type CreateCycleMutationFn = Apollo.MutationFunction<
  CreateCycleMutation,
  CreateCycleMutationVariables
>;

/**
 * __useCreateCycleMutation__
 *
 * To run a mutation, you first call `useCreateCycleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCycleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCycleMutation, { data, loading, error }] = useCreateCycleMutation({
 *   variables: {
 *      cycleAttributes: // value for 'cycleAttributes'
 *   },
 * });
 */
export function useCreateCycleMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateCycleMutation, CreateCycleMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateCycleMutation, CreateCycleMutationVariables>(
    CreateCycleDocument,
    options,
  );
}
export type CreateCycleMutationHookResult = ReturnType<typeof useCreateCycleMutation>;
export type CreateCycleMutationResult = Apollo.MutationResult<CreateCycleMutation>;
export type CreateCycleMutationOptions = Apollo.BaseMutationOptions<
  CreateCycleMutation,
  CreateCycleMutationVariables
>;
export const CreateNoteDocument = gql`
  mutation CreateNote($noteAttributes: FemappNoteInput!) {
    femappCreateNote(input: { noteAttributes: $noteAttributes }) {
      note {
        id
        date
        flowAmount
        dischargeAmount
        dischargeCondition
        sexualActivity
        physicalConditions {
          name
        }
        mentalConditions {
          name
        }
        pillIntake
        bodyTemperature
        memo
      }
      errors {
        message
        extensions {
          code
          domain
          appCode
        }
      }
    }
  }
`;
export type CreateNoteMutationFn = Apollo.MutationFunction<
  CreateNoteMutation,
  CreateNoteMutationVariables
>;

/**
 * __useCreateNoteMutation__
 *
 * To run a mutation, you first call `useCreateNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNoteMutation, { data, loading, error }] = useCreateNoteMutation({
 *   variables: {
 *      noteAttributes: // value for 'noteAttributes'
 *   },
 * });
 */
export function useCreateNoteMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateNoteMutation, CreateNoteMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateNoteMutation, CreateNoteMutationVariables>(
    CreateNoteDocument,
    options,
  );
}
export type CreateNoteMutationHookResult = ReturnType<typeof useCreateNoteMutation>;
export type CreateNoteMutationResult = Apollo.MutationResult<CreateNoteMutation>;
export type CreateNoteMutationOptions = Apollo.BaseMutationOptions<
  CreateNoteMutation,
  CreateNoteMutationVariables
>;
export const CreateUserBehaviorDocument = gql`
  mutation CreateUserBehavior($userBehaviorAttributes: FemappUserBehaviorInput!) {
    femappCreateUserBehavior(input: { userBehaviorAttributes: $userBehaviorAttributes }) {
      behavior {
        id
        behaviorType
        datetime
      }
      errors {
        message
        extensions {
          code
          domain
          appCode
        }
      }
    }
  }
`;
export type CreateUserBehaviorMutationFn = Apollo.MutationFunction<
  CreateUserBehaviorMutation,
  CreateUserBehaviorMutationVariables
>;

/**
 * __useCreateUserBehaviorMutation__
 *
 * To run a mutation, you first call `useCreateUserBehaviorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserBehaviorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserBehaviorMutation, { data, loading, error }] = useCreateUserBehaviorMutation({
 *   variables: {
 *      userBehaviorAttributes: // value for 'userBehaviorAttributes'
 *   },
 * });
 */
export function useCreateUserBehaviorMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateUserBehaviorMutation,
    CreateUserBehaviorMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateUserBehaviorMutation, CreateUserBehaviorMutationVariables>(
    CreateUserBehaviorDocument,
    options,
  );
}
export type CreateUserBehaviorMutationHookResult = ReturnType<typeof useCreateUserBehaviorMutation>;
export type CreateUserBehaviorMutationResult = Apollo.MutationResult<CreateUserBehaviorMutation>;
export type CreateUserBehaviorMutationOptions = Apollo.BaseMutationOptions<
  CreateUserBehaviorMutation,
  CreateUserBehaviorMutationVariables
>;
export const DeleteCycleDocument = gql`
  mutation DeleteCycle($id: ID!) {
    femappDeleteCycle(input: { id: $id }) {
      cycleState {
        status
        stage
        statusWhenReadingSummary
        stageWhenReadingSummary
        cycles {
          id
          periodStartOn
          periodLength
          ovulationScheduledOn
          pmsStartOn
          pmsEndOn
        }
      }
      errors {
        message
        extensions {
          code
          domain
          appCode
        }
      }
    }
  }
`;
export type DeleteCycleMutationFn = Apollo.MutationFunction<
  DeleteCycleMutation,
  DeleteCycleMutationVariables
>;

/**
 * __useDeleteCycleMutation__
 *
 * To run a mutation, you first call `useDeleteCycleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCycleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCycleMutation, { data, loading, error }] = useDeleteCycleMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCycleMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteCycleMutation, DeleteCycleMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteCycleMutation, DeleteCycleMutationVariables>(
    DeleteCycleDocument,
    options,
  );
}
export type DeleteCycleMutationHookResult = ReturnType<typeof useDeleteCycleMutation>;
export type DeleteCycleMutationResult = Apollo.MutationResult<DeleteCycleMutation>;
export type DeleteCycleMutationOptions = Apollo.BaseMutationOptions<
  DeleteCycleMutation,
  DeleteCycleMutationVariables
>;
export const DeleteNoteDocument = gql`
  mutation DeleteNote($id: ID!) {
    femappDeleteNote(input: { id: $id }) {
      id
      errors {
        message
        extensions {
          code
          domain
          appCode
        }
      }
    }
  }
`;
export type DeleteNoteMutationFn = Apollo.MutationFunction<
  DeleteNoteMutation,
  DeleteNoteMutationVariables
>;

/**
 * __useDeleteNoteMutation__
 *
 * To run a mutation, you first call `useDeleteNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteNoteMutation, { data, loading, error }] = useDeleteNoteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteNoteMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteNoteMutation, DeleteNoteMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteNoteMutation, DeleteNoteMutationVariables>(
    DeleteNoteDocument,
    options,
  );
}
export type DeleteNoteMutationHookResult = ReturnType<typeof useDeleteNoteMutation>;
export type DeleteNoteMutationResult = Apollo.MutationResult<DeleteNoteMutation>;
export type DeleteNoteMutationOptions = Apollo.BaseMutationOptions<
  DeleteNoteMutation,
  DeleteNoteMutationVariables
>;
export const SignUpV2Document = gql`
  mutation SignUpV2($clientMutationId: String!) {
    femappSignUpForV2(input: { clientMutationId: $clientMutationId }) {
      isSuccess
      errors {
        message
        extensions {
          code
          domain
          appCode
        }
      }
    }
  }
`;
export type SignUpV2MutationFn = Apollo.MutationFunction<
  SignUpV2Mutation,
  SignUpV2MutationVariables
>;

/**
 * __useSignUpV2Mutation__
 *
 * To run a mutation, you first call `useSignUpV2Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignUpV2Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signUpV2Mutation, { data, loading, error }] = useSignUpV2Mutation({
 *   variables: {
 *      clientMutationId: // value for 'clientMutationId'
 *   },
 * });
 */
export function useSignUpV2Mutation(
  baseOptions?: Apollo.MutationHookOptions<SignUpV2Mutation, SignUpV2MutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SignUpV2Mutation, SignUpV2MutationVariables>(SignUpV2Document, options);
}
export type SignUpV2MutationHookResult = ReturnType<typeof useSignUpV2Mutation>;
export type SignUpV2MutationResult = Apollo.MutationResult<SignUpV2Mutation>;
export type SignUpV2MutationOptions = Apollo.BaseMutationOptions<
  SignUpV2Mutation,
  SignUpV2MutationVariables
>;
export const UpdateAllCyclesDocument = gql`
  mutation UpdateAllCycles($cyclesAttributes: [FemappCycleInput!]!) {
    femappUpdateAllCycles(input: { cyclesAttributes: $cyclesAttributes }) {
      cycleState {
        status
        stage
        statusWhenReadingSummary
        stageWhenReadingSummary
        cycles {
          id
          periodStartOn
          periodLength
          ovulationScheduledOn
          pmsStartOn
          pmsEndOn
        }
      }
      errors {
        message
        extensions {
          code
          domain
          appCode
        }
      }
    }
  }
`;
export type UpdateAllCyclesMutationFn = Apollo.MutationFunction<
  UpdateAllCyclesMutation,
  UpdateAllCyclesMutationVariables
>;

/**
 * __useUpdateAllCyclesMutation__
 *
 * To run a mutation, you first call `useUpdateAllCyclesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAllCyclesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAllCyclesMutation, { data, loading, error }] = useUpdateAllCyclesMutation({
 *   variables: {
 *      cyclesAttributes: // value for 'cyclesAttributes'
 *   },
 * });
 */
export function useUpdateAllCyclesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAllCyclesMutation,
    UpdateAllCyclesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateAllCyclesMutation, UpdateAllCyclesMutationVariables>(
    UpdateAllCyclesDocument,
    options,
  );
}
export type UpdateAllCyclesMutationHookResult = ReturnType<typeof useUpdateAllCyclesMutation>;
export type UpdateAllCyclesMutationResult = Apollo.MutationResult<UpdateAllCyclesMutation>;
export type UpdateAllCyclesMutationOptions = Apollo.BaseMutationOptions<
  UpdateAllCyclesMutation,
  UpdateAllCyclesMutationVariables
>;
export const UpdateNoteDocument = gql`
  mutation UpdateNote($id: ID!, $noteAttributes: FemappNoteInput!) {
    femappUpdateNote(input: { id: $id, noteAttributes: $noteAttributes }) {
      note {
        id
        date
        flowAmount
        dischargeAmount
        dischargeCondition
        sexualActivity
        physicalConditions {
          name
        }
        mentalConditions {
          name
        }
        pillIntake
        bodyTemperature
        memo
      }
      errors {
        message
        extensions {
          code
          domain
          appCode
        }
      }
    }
  }
`;
export type UpdateNoteMutationFn = Apollo.MutationFunction<
  UpdateNoteMutation,
  UpdateNoteMutationVariables
>;

/**
 * __useUpdateNoteMutation__
 *
 * To run a mutation, you first call `useUpdateNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNoteMutation, { data, loading, error }] = useUpdateNoteMutation({
 *   variables: {
 *      id: // value for 'id'
 *      noteAttributes: // value for 'noteAttributes'
 *   },
 * });
 */
export function useUpdateNoteMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateNoteMutation, UpdateNoteMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateNoteMutation, UpdateNoteMutationVariables>(
    UpdateNoteDocument,
    options,
  );
}
export type UpdateNoteMutationHookResult = ReturnType<typeof useUpdateNoteMutation>;
export type UpdateNoteMutationResult = Apollo.MutationResult<UpdateNoteMutation>;
export type UpdateNoteMutationOptions = Apollo.BaseMutationOptions<
  UpdateNoteMutation,
  UpdateNoteMutationVariables
>;
export const UpdateUserDocument = gql`
  mutation UpdateUser($userAttributes: FemappUserInput!) {
    femappUpdateUser(input: { userAttributes: $userAttributes }) {
      user {
        id
        birthday
        email
        regularCycleLength
        regularPeriodLength
        hasPillPlugin
        hasPillNotification
        pillRemindedAt
        sheetStartDate
        hasPlaceboPeriodNotification
        hasPillSheetImage
        hasEmotionalReminderMessage
        pill {
          id
          name
          activeDrugSize
          placeboDrugSize
        }
        pillClinic {
          id
          name
        }
        hasAcceptedAccountLinking
        hasClinicforUser
        hasReservationsFromLineMiniapp
      }
      errors {
        message
        extensions {
          code
          domain
          appCode
        }
      }
    }
  }
`;
export type UpdateUserMutationFn = Apollo.MutationFunction<
  UpdateUserMutation,
  UpdateUserMutationVariables
>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      userAttributes: // value for 'userAttributes'
 *   },
 * });
 */
export function useUpdateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(
    UpdateUserDocument,
    options,
  );
}
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserMutation,
  UpdateUserMutationVariables
>;
export const UpdateUserBehaviorDocument = gql`
  mutation UpdateUserBehavior($id: ID!, $userBehaviorAttributes: FemappUserBehaviorInput!) {
    femappUpdateUserBehavior(input: { id: $id, userBehaviorAttributes: $userBehaviorAttributes }) {
      behavior {
        id
        behaviorType
        datetime
      }
      errors {
        message
        extensions {
          code
          domain
          appCode
        }
      }
    }
  }
`;
export type UpdateUserBehaviorMutationFn = Apollo.MutationFunction<
  UpdateUserBehaviorMutation,
  UpdateUserBehaviorMutationVariables
>;

/**
 * __useUpdateUserBehaviorMutation__
 *
 * To run a mutation, you first call `useUpdateUserBehaviorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserBehaviorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserBehaviorMutation, { data, loading, error }] = useUpdateUserBehaviorMutation({
 *   variables: {
 *      id: // value for 'id'
 *      userBehaviorAttributes: // value for 'userBehaviorAttributes'
 *   },
 * });
 */
export function useUpdateUserBehaviorMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserBehaviorMutation,
    UpdateUserBehaviorMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateUserBehaviorMutation, UpdateUserBehaviorMutationVariables>(
    UpdateUserBehaviorDocument,
    options,
  );
}
export type UpdateUserBehaviorMutationHookResult = ReturnType<typeof useUpdateUserBehaviorMutation>;
export type UpdateUserBehaviorMutationResult = Apollo.MutationResult<UpdateUserBehaviorMutation>;
export type UpdateUserBehaviorMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserBehaviorMutation,
  UpdateUserBehaviorMutationVariables
>;
export const InsightDocument = gql`
  query Insight($status: FemappPhaseStatus!, $stage: FemappPhaseStage!) {
    femappInsight(status: $status, stage: $stage) {
      status
      stage
      advices {
        viewpoint
        heading
        content
      }
      articles {
        title
        subtitle
        heading
        content
        urlAddition
        articleUrl
        thumbnailUrl
      }
    }
  }
`;

/**
 * __useInsightQuery__
 *
 * To run a query within a React component, call `useInsightQuery` and pass it any options that fit your needs.
 * When your component renders, `useInsightQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInsightQuery({
 *   variables: {
 *      status: // value for 'status'
 *      stage: // value for 'stage'
 *   },
 * });
 */
export function useInsightQuery(
  baseOptions: Apollo.QueryHookOptions<InsightQuery, InsightQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<InsightQuery, InsightQueryVariables>(InsightDocument, options);
}
export function useInsightLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<InsightQuery, InsightQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<InsightQuery, InsightQueryVariables>(InsightDocument, options);
}
export type InsightQueryHookResult = ReturnType<typeof useInsightQuery>;
export type InsightLazyQueryHookResult = ReturnType<typeof useInsightLazyQuery>;
export type InsightQueryResult = Apollo.QueryResult<InsightQuery, InsightQueryVariables>;
export const PillDocument = gql`
  query Pill {
    femappPill {
      id
      name
      activeDrugSize
      placeboDrugSize
    }
  }
`;

/**
 * __usePillQuery__
 *
 * To run a query within a React component, call `usePillQuery` and pass it any options that fit your needs.
 * When your component renders, `usePillQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePillQuery({
 *   variables: {
 *   },
 * });
 */
export function usePillQuery(baseOptions?: Apollo.QueryHookOptions<PillQuery, PillQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PillQuery, PillQueryVariables>(PillDocument, options);
}
export function usePillLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PillQuery, PillQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PillQuery, PillQueryVariables>(PillDocument, options);
}
export type PillQueryHookResult = ReturnType<typeof usePillQuery>;
export type PillLazyQueryHookResult = ReturnType<typeof usePillLazyQuery>;
export type PillQueryResult = Apollo.QueryResult<PillQuery, PillQueryVariables>;
export const PillClinicDocument = gql`
  query PillClinic {
    femappPillClinic {
      id
      name
    }
  }
`;

/**
 * __usePillClinicQuery__
 *
 * To run a query within a React component, call `usePillClinicQuery` and pass it any options that fit your needs.
 * When your component renders, `usePillClinicQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePillClinicQuery({
 *   variables: {
 *   },
 * });
 */
export function usePillClinicQuery(
  baseOptions?: Apollo.QueryHookOptions<PillClinicQuery, PillClinicQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PillClinicQuery, PillClinicQueryVariables>(PillClinicDocument, options);
}
export function usePillClinicLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PillClinicQuery, PillClinicQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PillClinicQuery, PillClinicQueryVariables>(
    PillClinicDocument,
    options,
  );
}
export type PillClinicQueryHookResult = ReturnType<typeof usePillClinicQuery>;
export type PillClinicLazyQueryHookResult = ReturnType<typeof usePillClinicLazyQuery>;
export type PillClinicQueryResult = Apollo.QueryResult<PillClinicQuery, PillClinicQueryVariables>;
export const PillInsightDocument = gql`
  query PillInsight {
    femappPillInsight {
      sheetNumber
      tabletNumber
      general {
        description
      }
      beauty {
        title
        subtitle
        image
        description
      }
      diet {
        name
        image
        description
      }
    }
  }
`;

/**
 * __usePillInsightQuery__
 *
 * To run a query within a React component, call `usePillInsightQuery` and pass it any options that fit your needs.
 * When your component renders, `usePillInsightQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePillInsightQuery({
 *   variables: {
 *   },
 * });
 */
export function usePillInsightQuery(
  baseOptions?: Apollo.QueryHookOptions<PillInsightQuery, PillInsightQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PillInsightQuery, PillInsightQueryVariables>(PillInsightDocument, options);
}
export function usePillInsightLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PillInsightQuery, PillInsightQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PillInsightQuery, PillInsightQueryVariables>(
    PillInsightDocument,
    options,
  );
}
export type PillInsightQueryHookResult = ReturnType<typeof usePillInsightQuery>;
export type PillInsightLazyQueryHookResult = ReturnType<typeof usePillInsightLazyQuery>;
export type PillInsightQueryResult = Apollo.QueryResult<
  PillInsightQuery,
  PillInsightQueryVariables
>;
export const PillInsightMinDocument = gql`
  query PillInsightMin {
    femappPillInsight {
      sheetNumber
    }
  }
`;

/**
 * __usePillInsightMinQuery__
 *
 * To run a query within a React component, call `usePillInsightMinQuery` and pass it any options that fit your needs.
 * When your component renders, `usePillInsightMinQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePillInsightMinQuery({
 *   variables: {
 *   },
 * });
 */
export function usePillInsightMinQuery(
  baseOptions?: Apollo.QueryHookOptions<PillInsightMinQuery, PillInsightMinQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PillInsightMinQuery, PillInsightMinQueryVariables>(
    PillInsightMinDocument,
    options,
  );
}
export function usePillInsightMinLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PillInsightMinQuery, PillInsightMinQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PillInsightMinQuery, PillInsightMinQueryVariables>(
    PillInsightMinDocument,
    options,
  );
}
export type PillInsightMinQueryHookResult = ReturnType<typeof usePillInsightMinQuery>;
export type PillInsightMinLazyQueryHookResult = ReturnType<typeof usePillInsightMinLazyQuery>;
export type PillInsightMinQueryResult = Apollo.QueryResult<
  PillInsightMinQuery,
  PillInsightMinQueryVariables
>;
export const UserDocument = gql`
  query User($noteDateFrom: Date!, $noteDateTo: Date!) {
    femappUser {
      id
      displayedId
      birthday
      email
      regularCycleLength
      regularPeriodLength
      behaviors {
        id
        behaviorType
        datetime
      }
      cycleState {
        status
        stage
        statusWhenReadingSummary
        stageWhenReadingSummary
        cycles {
          id
          periodStartOn
          periodLength
          ovulationScheduledOn
          pmsStartOn
          pmsEndOn
        }
      }
      notes(dateFrom: $noteDateFrom, dateTo: $noteDateTo) {
        id
        date
        flowAmount
        dischargeAmount
        dischargeCondition
        sexualActivity
        physicalConditions {
          name
        }
        mentalConditions {
          name
        }
        pillIntake
        bodyTemperature
        memo
      }
      hasPillPlugin
      hasPillNotification
      pillRemindedAt
      sheetStartDate
      hasPlaceboPeriodNotification
      hasPillSheetImage
      hasEmotionalReminderMessage
      pill {
        id
        name
        activeDrugSize
        placeboDrugSize
        description
        canContinuousIntake
      }
      pillClinic {
        id
        name
      }
      hasAcceptedAccountLinking
      hasClinicforUser
      hasReservationsFromLineMiniapp
    }
  }
`;

/**
 * __useUserQuery__
 *
 * To run a query within a React component, call `useUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserQuery({
 *   variables: {
 *      noteDateFrom: // value for 'noteDateFrom'
 *      noteDateTo: // value for 'noteDateTo'
 *   },
 * });
 */
export function useUserQuery(baseOptions: Apollo.QueryHookOptions<UserQuery, UserQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserQuery, UserQueryVariables>(UserDocument, options);
}
export function useUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UserQuery, UserQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UserQuery, UserQueryVariables>(UserDocument, options);
}
export type UserQueryHookResult = ReturnType<typeof useUserQuery>;
export type UserLazyQueryHookResult = ReturnType<typeof useUserLazyQuery>;
export type UserQueryResult = Apollo.QueryResult<UserQuery, UserQueryVariables>;
export const UserCycleDocument = gql`
  query UserCycle {
    femappUser {
      id
      cycleState {
        status
        stage
        statusWhenReadingSummary
        stageWhenReadingSummary
        cycles {
          id
          periodStartOn
          periodLength
          ovulationScheduledOn
          pmsStartOn
          pmsEndOn
        }
      }
    }
  }
`;

/**
 * __useUserCycleQuery__
 *
 * To run a query within a React component, call `useUserCycleQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserCycleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserCycleQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserCycleQuery(
  baseOptions?: Apollo.QueryHookOptions<UserCycleQuery, UserCycleQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserCycleQuery, UserCycleQueryVariables>(UserCycleDocument, options);
}
export function useUserCycleLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UserCycleQuery, UserCycleQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UserCycleQuery, UserCycleQueryVariables>(UserCycleDocument, options);
}
export type UserCycleQueryHookResult = ReturnType<typeof useUserCycleQuery>;
export type UserCycleLazyQueryHookResult = ReturnType<typeof useUserCycleLazyQuery>;
export type UserCycleQueryResult = Apollo.QueryResult<UserCycleQuery, UserCycleQueryVariables>;
export const UserNoteDocument = gql`
  query UserNote($noteDateFrom: Date!, $noteDateTo: Date!) {
    femappUser {
      notes(dateFrom: $noteDateFrom, dateTo: $noteDateTo) {
        id
        date
        flowAmount
        dischargeAmount
        dischargeCondition
        sexualActivity
        pillIntake
        physicalConditions {
          name
        }
        mentalConditions {
          name
        }
        bodyTemperature
        memo
      }
    }
  }
`;

/**
 * __useUserNoteQuery__
 *
 * To run a query within a React component, call `useUserNoteQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserNoteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserNoteQuery({
 *   variables: {
 *      noteDateFrom: // value for 'noteDateFrom'
 *      noteDateTo: // value for 'noteDateTo'
 *   },
 * });
 */
export function useUserNoteQuery(
  baseOptions: Apollo.QueryHookOptions<UserNoteQuery, UserNoteQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserNoteQuery, UserNoteQueryVariables>(UserNoteDocument, options);
}
export function useUserNoteLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UserNoteQuery, UserNoteQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UserNoteQuery, UserNoteQueryVariables>(UserNoteDocument, options);
}
export type UserNoteQueryHookResult = ReturnType<typeof useUserNoteQuery>;
export type UserNoteLazyQueryHookResult = ReturnType<typeof useUserNoteLazyQuery>;
export type UserNoteQueryResult = Apollo.QueryResult<UserNoteQuery, UserNoteQueryVariables>;
