import { Cycle, CycleState, FemappCycleInput, Note } from 'src/generated/graphql';
import { addDays, formatDateToStr, getDays } from 'src/lib/dateFormatter';
import { pillCycleLength } from 'src/lib/pillIntakes';

export type CycleMutationResponse = Record<string, { cycleState: CycleState }>;

export const calcMonthDiff = (before: Date, after: Date): number =>
  after.getFullYear() * 12 + after.getMonth() - (before.getFullYear() * 12 + before.getMonth());

export const cyclesToDates = (cycles: Cycle[]): Date[] => {
  const res: Date[] = [];
  cycles.map((cycle) => {
    if (!cycle.id) return;
    [...Array(cycle.periodLength).keys()].map((k) => {
      const targetDate = new Date(cycle.periodStartOn);
      targetDate.setDate(targetDate.getDate() + k);
      res.push(new Date(targetDate.getTime()));
    });
  });
  return res;
};

/*
 * 引数の cycles から、明日以降で、最も (今日に) 近い periodStartOn を取得する。
 * 明日以降にperiodStartOn が存在しない場合は、null を返却する。
 */
export const cyclesToNextPeriodStartOn = (cycles: Cycle[]): Date | null => {
  const today = getToday();
  const nextCycle = cycles.find((cycle) => new Date(cycle.periodStartOn) > today);
  return nextCycle ? new Date(nextCycle.periodStartOn) : null;
};

export const removeDuplicateDate = (dates: Date[]): Date[] =>
  Array.from(new Set(dates.map((d) => formatDateToStr(d)))).map((d) => new Date(d));

/*
 * 引数として受け取る、チェックされた日付の配列 Date[] を、
 * period (1つの生理期間) の配列 { period_starto_on: Date, period_length: number}[] 、
 * に変換する関数。
 *
 * チェックされた日付は、連続している日付のまとまりごとに、period として変換される。
 * (= 1 日以上間隔が空いた日付は、それぞれ別の period として変換される)
 *
 * 例えば、
 * チェックされた日付が [2/1, 2/2, 2/4, 2/7, 2/8, 2/9] である場合、
 * 変換後の period の配列は
 * [
 *  { period_start_on: 2/1, period_length: 2},
 *  { period_start_on: 2/4, period_length: 1},
 *  { period_start_on: 2/7, period_length: 3},
 *  ] 、
 * となる。詳しくはテストを参照。
 *
 * 最終的には、1 つの period が、 femapp_cycles テーブルの 1 つのレコードとして、保存される。
 * (テーブル名は femapp_cycles だが、保存されているのは period なので、注意が必要かもしれない)
 */
export const datesToCycles = (dates: Date[]): FemappCycleInput[] => {
  if (!dates.length) return [];

  const initPeriod = (date: Date): Date[] => [date];

  const isLastPeriod = (period: Date[], date: Date): boolean => {
    const periodLastDate = new Date(period.slice(-1)[0]);
    return formatDateToStr(addDays(periodLastDate, 1)) === formatDateToStr(date);
  };

  // 念のため重複した日付があれば削除する
  const notDuplicatedDates = removeDuplicateDate(dates);

  // 昇順に並び替える
  const sortedDates = notDuplicatedDates.sort((a, b) => (a > b ? 1 : -1));

  // この後の forloop で使用する各種変数
  let period: Date[] = [];
  const periods: Date[][] = [];
  const lastIdx = sortedDates.length - 1;

  // 連続した日付のまとまりを period として、 periods に push していく。
  for (const [idx, date] of sortedDates.entries()) {
    // date が sortedDates において、一番最初の日付である場合
    if (idx === 0) {
      period = initPeriod(date);
      continue;
    }

    // 現時点での period の最後の日付と、date が連続していた場合
    if (isLastPeriod(period, date)) {
      period.push(date);
      // 最後の idx だった場合
      if (idx === lastIdx) break;
      continue;
    } else {
      periods.push(period); // 現時点での period は date を push せずに、periods に push する
      period = initPeriod(date); // 新たに period を作り、 date を push する
    }
  }
  periods.push(period);

  // 整形して返却
  return periods.map((period) => ({
    periodLength: period.length,
    periodStartOn: formatDateToStr(period[0]),
  }));
};

export const dateToCalendarIndex = (date: Date, calendarActiveStartDates: Date[]): number =>
  calendarActiveStartDates
    .map((date) => formatDateToStr(date))
    .indexOf(formatDateToStr(dateToMonthFirstDay(date)));

export const dateToMonthFirstDay = (d: Date): Date =>
  new Date(`${d.getFullYear()}-${('0' + (d.getMonth() + 1)).slice(-2)}-01`);

export const datesToMonthFirstDayList = (dates: Date[]): Date[] => {
  const targetTimes: number[] = [];
  dates.map((targetDate) => {
    const targetFirstDate = dateToMonthFirstDay(targetDate);
    targetTimes.push(targetFirstDate.getTime());
  });
  const notDuplicateTargetTimes: number[] = Array.from(new Set(targetTimes));
  const targetDates = notDuplicateTargetTimes.map((time) => new Date(time));
  return targetDates;
};

export const getDateDiff = (beforeDate: Date, afterDate: Date): number =>
  (afterDate.getTime() - beforeDate.getTime()) / (1000 * 60 * 60 * 24);

export const getToday = (): Date => new Date(formatDateToStr(new Date()));

export const isAfterToday = (date: Date): boolean => {
  const today = getToday();
  return formatDateToStr(date) > formatDateToStr(today);
};

/*
 * 生理編集カレンダーにおいて、各チェックボックスを更新できるかどうかを、制御する関数。
 *
 * period_start_on が翌日以降のレコードを、 femapp_cycles テーブルに保存させない、という意図がある。
 */
export const isTappableCheckbox = (date: Date, checkedDates: Date[]): boolean => {
  // 今日以前であれば (= 未来日でなければ) タップ可能
  if (formatDateToStr(date) <= formatDateToStr(getToday())) return true;
  // 未来日は、checkdDatesの一番最後の日より後ろの日付は、タップできない
  if (!checkedDates.length) return false;
  const lastCheckedDate: Date = checkedDates.sort((a, b) => (a > b ? 1 : -1)).slice(-1)[0];
  const isAfterLastCheckedDate =
    formatDateToStr(date) > formatDateToStr(addDays(lastCheckedDate, 1));
  return !isAfterLastCheckedDate;
};

export const isDisableTile = (activeStartDate: Date, date: Date): boolean =>
  activeStartDate.getMonth() != date.getMonth();

export const isIncludedDate = (date: Date, dates: Date[]): boolean => {
  return dates.map((d) => formatDateToStr(d)).includes(formatDateToStr(date));
};

/**
 * date が "checkedDates に含まれている日付の中で、いずれかの翌日に該当するか否か" を返す
 */
export const isNextToAnyCheckedDate = (date: Date, checkedDates: Date[]): boolean => {
  const nextDate: Date | undefined = checkedDates.find((d) => {
    const prevDate = new Date(date);
    prevDate.setDate(prevDate.getDate() - 1);
    if (formatDateToStr(d) === formatDateToStr(prevDate)) {
      return d;
    }
  });
  return Boolean(nextDate);
};

export const isToday = (date: Date): boolean => {
  const today = getToday();
  return formatDateToStr(date) === formatDateToStr(today);
};

/**
 * 日付をタップした際に、チェック済みの生理日のリストを、更新する処理
 */
export const updateCheckedDatesByTapping = (
  tappedDate: Date,
  checkedDates: Date[],
  maxAutoCheckedRange = 1,
): Date[] => {
  /**
   * checkedDates から tappedDate を除外する処理
   *
   * - 今日以降の場合
   *   - tappedDate 以降の日付を全て取り除く
   * - それ以外の場合
   *   - tappedDate のみを取り除く
   */
  const remove = (tappedDate: Date, checkedDates: Date[]): Date[] => {
    if (formatDateToStr(tappedDate) >= formatDateToStr(getToday()))
      return checkedDates.filter((d) => formatDateToStr(d) < formatDateToStr(tappedDate));
    return checkedDates.filter((d) => formatDateToStr(d) != formatDateToStr(tappedDate));
  };

  /**
   * checkedDates に tappedDate を追加する処理
   *
   * - tappedDate が checkedDates に含まれているいずれかの日の、翌日の場合
   *   - tappedDate だけ加える
   * - それ以外の場合
   *   - tappedDate を含めてそこから [maxAutoCheckedRange] 日後まで加える
   *   - ただし、その [maxAutoCheckedRange] 日間の間に、すでに checkedDates に含まれている日付があった場合は、その直前の日付までを加える
   */
  const add = (tappedDate: Date, checkedDates: Date[], maxAutoCheckedRange: number): Date[] => {
    if (maxAutoCheckedRange === 1 || isNextToAnyCheckedDate(tappedDate, checkedDates))
      return [...checkedDates, tappedDate]; // (isNextToAnyCheckedDate(...) === true) ===  tappedDate が checkedDates に含まれている日の翌日の場合
    const sortedDates = checkedDates.sort((a, b) => (a > b ? 1 : -1));
    const addedDates: Date[] = [];
    for (const idx of [...Array(maxAutoCheckedRange).keys()]) {
      const targetDate = new Date(tappedDate);
      targetDate.setDate(targetDate.getDate() + idx);
      if (sortedDates.find((d) => formatDateToStr(d) === formatDateToStr(targetDate))) {
        break;
      }
      addedDates.push(targetDate);
    }
    return [...checkedDates, ...addedDates];
  };

  const updatedDates = isIncludedDate(tappedDate, checkedDates)
    ? remove(tappedDate, checkedDates)
    : add(tappedDate, checkedDates, maxAutoCheckedRange);

  return updatedDates.sort((a, b) => (a > b ? 1 : -1)); // 昇順に sort して返す
};

export const countTabletNumber = (sheetStartDate: Date): number => {
  const tabletNumber = (getDays(getToday(), sheetStartDate) + 1) % pillCycleLength;
  return tabletNumber === 0 ? 28 : tabletNumber;
};

export type PillInfoBoxTexts = {
  tabletNumber: string;
  drawalDate: string;
  remainedActiveDrugDate: string;
  remainedSheetDate: string;
  isActiveDrugPeriod: boolean;
  isPlaceboDrugPeriod: boolean;
};

/*
 * ユーザーが手動で編集したデータが 1 つもでもあれば True 、そうでなければ False を返す。
 */
export const isNoteEditedByUser = (note: Note): boolean => {
  const nonUserEditableFields = ['__typename', 'date', 'id']; // これらのフィールドはユーザーが編集したデータとしてカウントしない。
  const noDataStateString = [[], null, undefined].map((v) => String(v)); // Array を比較したいが、 [] == [] は false なので、string 型に変換する。
  return Object.entries(note).some(
    ([k, v]) => !nonUserEditableFields.includes(k) && !noDataStateString.includes(String(v)),
  );
};
