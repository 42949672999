import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  DefaultOptions,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { captureMessage } from '@sentry/nextjs';
import ApolloLinkTimeout from 'apollo-link-timeout';

const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: 'cache-first',
    errorPolicy: 'all',
  },
  query: {
    fetchPolicy: 'cache-first',
    errorPolicy: 'all',
  },
  mutate: {
    errorPolicy: 'all',
  },
};

const cache = new InMemoryCache({
  typePolicies: {
    Cycle: {
      // Cycleはidがnullのデータが返ってくるためキーをperiodStartOnとする
      keyFields: ['periodStartOn'],
    },
  },
});

/**
 * @param {string} token - LIFFのIDトークン。バックエンドサーバーにおけるユーザー認証に利用。
 * @param {string} ssgSecret - SSGでAPI呼び出ししているかどうか判断するためのシークレット。https://github.com/lincwell/femapp/pull/357
 */
export const graphqlClient = (
  token: string | undefined = undefined,
  ssgSecret = '',
): ApolloClient<NormalizedCacheObject> => {
  const uri: string | undefined = process.env.NEXT_PUBLIC_API_ENDPOINT;
  if (!uri) {
    throw new Error('envファイルが存在しないか、NEXT_PUBLIC_API_ENDPOINTが定義されていません。');
  }
  const httpLink = createHttpLink({ uri: uri });
  const timeoutLink = new ApolloLinkTimeout(30000); // 30 second timeout
  const timeoutHttpLink = timeoutLink.concat(httpLink);

  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
        'x-ssg-secret': ssgSecret,
      },
    };
  });

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path }) => {
        captureMessage(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
        );
      });
    }
    if (networkError) captureMessage(`[Network error]: ${networkError}`);
  });

  return new ApolloClient({
    link: ApolloLink.from([errorLink, authLink, timeoutHttpLink]),
    cache: cache,
    defaultOptions: defaultOptions,
  });
};
