import { css } from 'styled-components';

export const resetInputTextAreaCss = css`
  appearance: none;
  resize: none;
  padding: 0;
  border: 0;
  outline: none;
  background: transparent;
`;

export const centeredFlex = css`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const centeredFlexLine = css`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
`;
