import { NextPage } from 'next';
import { useRouter } from 'next/router';
import React, { useEffect } from 'react';
import { useLiff } from 'src/contexts/liff';
import { useSignUpV2Mutation, UserCycleDocument } from 'src/generated/graphql';
import { useLineMessagingService } from 'src/hooks/useLineMessagingService';

/*
 * femapp_users テーブルに、利用規約に同意済みのユーザーのレコードを作成するためのページ。
 * レコード作成後に /user にリダイレクトさせる。
 */
const SignUp: NextPage = () => {
  const router = useRouter();
  const refetchPolicy = {
    refetchQueries: [{ query: UserCycleDocument }],
    awaitRefetchQueries: true,
  };
  const [signUpV2Mutation] = useSignUpV2Mutation(refetchPolicy);
  const { login, loggedIn } = useLiff();
  const { pushMessage, changeRichMenu } = useLineMessagingService();

  useEffect(() => {
    if (!loggedIn && login) login();
  }, [loggedIn, login]);

  useEffect(() => {
    const createUser = () => {
      // ランダムな文字列を作成
      signUpV2Mutation({
        variables: { clientMutationId: Math.random().toString(36).slice(-8) },
      }).then(({ data, errors }) => {
        if (errors) {
          if (errors[0].extensions?.code === 'U00001') {
            alert(errors[0].extensions.message);
          } else {
            throw new Error('Could not create user.');
          }
        }
        if (data?.femappSignUpForV2?.isSuccess) {
          pushMessage('appExplanation'); // 初期登録完了後のメッセージをLINEで送信
          changeRichMenu('pill'); // ピルモードで開始
          router.push('/user?state=hasJustRegistred'); // 設定ページにリダイレクト
        } else {
          throw new Error('Could not sign up');
        }
      });
    };
    if (loggedIn) {
      createUser();
    }
  }, [changeRichMenu, loggedIn, pushMessage, router, signUpV2Mutation]);

  return <></>;
};

export default SignUp;
