import { AppProps } from 'next/app';
import Head from 'next/head';
import React, { useEffect } from 'react';
import TagManager from 'react-gtm-module';
import { ActionProvider, useActionProvider } from 'src/contexts/actions';
import { AuthorizedApolloProvider } from 'src/contexts/authorized_apollo';
import { LiffProvider } from 'src/contexts/liff';
import {
  FemappUser,
  useUserNoteQuery,
  usePillInsightMinQuery,
  useUserQuery,
} from 'src/generated/graphql';
import { getToday } from 'src/lib/calendar';
import { formatDateToStr, addDays } from 'src/lib/dateFormatter';
import Custom500 from 'src/pages/500';
import SignUp from 'src/pages/signup';
import GlobalStyle from 'styles/global_style';

type InitializeStateProps = {
  Component: AppProps['Component'];
  pageProps: AppProps['pageProps'];
};

const InitializeState = ({ Component, pageProps }: InitializeStateProps) => {
  const today = getToday();
  const { dispatch } = useActionProvider();

  const userQueryResult = useUserQuery({
    variables: {
      noteDateFrom: formatDateToStr(addDays(today, -365)),
      noteDateTo: formatDateToStr(today),
    },
  }); // 引数の variables は API の仕様から削除できた時点で、フロントからも削除する
  const pillInsightMinQueryResult = usePillInsightMinQuery();
  const userNoteQueryResult = useUserNoteQuery({
    variables: {
      noteDateFrom: formatDateToStr(addDays(today, -365)),
      noteDateTo: formatDateToStr(today),
    },
  });

  useEffect(() => {
    if (userQueryResult.data?.femappUser) {
      dispatch({ type: 'setfemappUser', payload: userQueryResult.data.femappUser as FemappUser });
      dispatch({
        type: 'setfemappUserBehaviors',
        payload: userQueryResult.data.femappUser.behaviors,
      });
      dispatch({ type: 'setfemappCycle', payload: userQueryResult.data.femappUser.cycleState });
    }
  }, [dispatch, userQueryResult.data]);

  useEffect(() => {
    if (userNoteQueryResult.data?.femappUser?.notes) {
      dispatch({ type: 'setFemappUserNotes', payload: userNoteQueryResult.data.femappUser.notes });
    }
  }, [dispatch, userNoteQueryResult.data?.femappUser?.notes]);

  useEffect(() => {
    if (pillInsightMinQueryResult.data?.femappPillInsight) {
      const pillInsights = pillInsightMinQueryResult.data.femappPillInsight.map(
        (i) => i.sheetNumber,
      );
      const maxPillInsightSheetNumber = Math.max(...pillInsights);
      dispatch({ type: 'setMaxPillInsightSheetNumber', payload: maxPillInsightSheetNumber });
    }
  }, [dispatch, pillInsightMinQueryResult.data]);

  if (userQueryResult.loading) return null;
  if (userQueryResult.error) return <Custom500 />;
  if (!userQueryResult.data?.femappUser) return <SignUp />;

  return <Component {...pageProps} />;
};

const App = ({ Component, pageProps }: AppProps): JSX.Element => {
  useEffect(() => {
    const gtmId = process.env.NEXT_PUBLIC_GTM_ID;
    if (process.env.NODE_ENV === 'production' && gtmId) {
      TagManager.initialize({ gtmId: gtmId });
    }
  }, []);

  return (
    <LiffProvider>
      <GlobalStyle />
      <AuthorizedApolloProvider>
        <ActionProvider>
          <Head>
            <title>sai＋ダイアリー</title>
            <meta
              name="viewport"
              content="width=device-width,initial-scale=1.0,minimum-scale=1.0,maximum-scale=1.0"
            />
            <link
              rel="preload"
              href="/fonts/Noto_Sans_JP/NotoSansJP-Regular.woff2"
              as="font"
              type="font/woff2"
              crossOrigin=""
            />
            <link
              rel="preload"
              href="/fonts/Noto_Sans_JP/NotoSansJP-Bold.woff2"
              as="font"
              type="font/woff2"
              crossOrigin=""
            />
            <link rel="stylesheet" href="https://use.typekit.net/upc0mnb.css"></link>
            <link rel="preconnect" href="https://api.line.me" crossOrigin=""></link>
            <link rel="preconnect" href="https//static.line-scdn.net" crossOrigin=""></link>
            <link rel="preconnect" href={process.env.NEXT_PUBLIC_API_HOST} crossOrigin=""></link>
            <link
              rel="preconnect"
              href={`https://${process.env.NEXT_PUBLIC_SENTRY_DOMAIN}`}
              crossOrigin=""
            ></link>
          </Head>
          <InitializeState Component={Component} pageProps={pageProps} />
        </ActionProvider>
      </AuthorizedApolloProvider>
    </LiffProvider>
  );
};

export default App;
