export const colors = {
  white: '#FFFFFF',
  offWhite: '#F2F2F5',
  darkBlack: '#000000',
  black: '#484848',
  blackGrey: '#2F2F2F',
  charcoalGrey: '#4A4A4A',
  darkGrey: '#9F9F9F',
  deepGrey: '#B2B0AF',
  dimGrey: '#656565',
  grey: '#C4C4C4',
  warmGrey: '#E5E5E5',
  culturedGrey: '#F6F6F6',
  brightGrey: '#C7C6CB',
  lightGrey: '#F5F5F5',
  silverGrey: '#B9B9B9',
  platinumGrey: '#E9E9EA',
  gainsboroGrey: '#DDDDDD',
  paleGrey: '#F9F9F9',
  lightBeige: '#FEEBE7',
  beige: '#F9DCD4',
  darkBeige: '#EF857B',
  deepBeige: '#F7B9AF',
  camel: '#B9966C',
  brown: '#8E6039',
  warnOrange: '#EB675A',
  lightOrange: '#F5F0E6',
  green: '#BBFFA3',
  deepGreen: '#64BB85',
  red: '#F57B62',
  skyBlue: '#ACD3DE',
  cream: '#F3EAE2',
  forestGreen: '#2B4B47',
  windowBlue: '#D9E4E8',

  // Platform
  line: '#00B900',
};

export const colorOptions = {
  WHITE: 'white',
  OFF_WHITE: 'off-white',
  BLACK: 'black',
  GREY: 'grey',
  SILVER_GREY: 'silver-grey',
  BEIGE: 'beige',
  DARK_BEIGE: 'dark-beige',
  ORANGE: 'orange',
  OFF_WHITE_ORANGE: 'off-white-orange',
  CANCEL: 'cancel',
  CHECKED: 'checked',
  UNCHECKED: 'unchecked',

  // Platform
  LINE: 'line',
};
