export const isDate = (str: string): boolean => {
  return !!str.match(/^\d{4}-\d{2}-\d{2}$/);
};

export const isTime = (str: string): boolean => {
  return !!str.match(/^\d{2}:\d{2}$/);
};

export const formatDateToStr = (date: Date): string => {
  const year = date.getFullYear();
  // 0詰め
  const month = ('0' + (date.getMonth() + 1)).slice(-2);
  const day = ('0' + date.getDate()).slice(-2);

  return year + '-' + month + '-' + day;
};

export const formatDateToJaStr = (date: Date): string => {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();

  return year + '年' + month + '月' + day + '日';
};

export const formatDateToTimeStr = (date: Date, hasFullHours = false): string => {
  const hour = hasFullHours ? ('0' + date.getHours()).slice(-2) : date.getHours();
  const minute = ('0' + date.getMinutes()).slice(-2);

  return `${hour}:${minute}`;
};

export const formatMonthDay = (date: Date): string => {
  const month = (date.getMonth() + 1).toString();
  const day = date.getDate().toString();

  const dayOfWeek = '日月火水木金土'.slice(date.getDay(), date.getDay() + 1);
  return month + '/' + day + '(' + dayOfWeek + ')';
};

export const getBetweenDates = (startDate: Date, endDate: Date): Date[] => {
  const dates: Date[] = [];
  const targetDate = new Date(startDate.getTime());
  while (targetDate <= endDate) {
    dates.push(new Date(targetDate.getTime()));
    targetDate.setDate(targetDate.getDate() + 1);
  }
  return dates;
};

export const getRangeDaysArr = (from: number, to: number): { key: string; value: string }[] => {
  const daysArr: { key: string; value: string }[] = [];
  Array.from({ length: (from - to) / -1 + 1 }, (_, i) => {
    daysArr.push({ key: `${to + i * -1}`, value: `${to + i * -1}日` });
  });
  // 昇順に入れ替え
  return daysArr.reverse();
};

export const getYearArr = (from: number, to: number): { key: string; value: string }[] => {
  const yearArr: { key: string; value: string }[] = [];
  Array.from({ length: (from - to) / -1 + 1 }, (_, i) => {
    yearArr.push({
      key: `${to + i * -1}`,
      value: `${to + i * -1}年`,
    });
  });
  return yearArr;
};

export const getMonthArr = (): { key: number; value: string }[] => {
  const monthArr: { key: number; value: string }[] = [];
  const numArr = Array.from({ length: 12 }, (_, i) => i + 1);
  numArr.map((val) => {
    monthArr.push({ key: val, value: `${val}月` });
  });
  return monthArr;
};

export const getDayArr = (month: number): { key: string; value: string }[] => {
  const dayLength = [2, 4, 6, 9, 11].includes(month) ? (month === 2 ? 29 : 30) : 31;
  const daysArr: { key: string; value: string }[] = [];
  const numArr = Array.from({ length: dayLength }, (_, i) => i + 1);
  numArr.map((val) => {
    daysArr.push({ key: `${val}`, value: `${val}日` });
  });
  return daysArr;
};

export const getDays = (followingDate: Date, previousDate: Date): number => {
  return Math.floor(
    (new Date(followingDate).getTime() - new Date(previousDate).getTime()) / 86400000,
  );
};

export const addDays = (date: Date, days: number): Date => {
  const addedDate = new Date(date);
  addedDate.setDate(addedDate.getDate() + days);

  return addedDate;
};
