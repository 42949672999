import React from 'react';
import ReactLoading from 'react-loading';
import { colors } from 'styles/styles';

const style: React.CSSProperties = {
  position: 'fixed',
  top: 'calc(50% - 20px)',
  left: 'calc(50% - 20px)',
};

export const LoadingSpinner = (): JSX.Element => (
  <div style={style}>
    <ReactLoading type={'spin'} color={colors.black} height={'40px'} width={'40px'} />
  </div>
);
