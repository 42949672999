import { createGlobalStyle } from 'styled-components';
import { colors } from 'styles/styles';

const GlobalStyle = createGlobalStyle` 
  html,
  body {
    margin: 0;
    padding: 0;
    height: auto;
    overflow-x: hidden;
  }
  @font-face {
    font-family: 'Noto_Sans_JP_Regular';
    src: local("Noto Sans CJK JP Regular"),
         url('/fonts/Noto_Sans_JP/NotoSansJP-Regular.woff2');
    font-style: normal;
    font-weight: 400;
    font-display: swap;
  }
  @font-face {
    font-family: 'Noto_Sans_JP_Bold';
    src: local("Noto Sans CJK JP Bold"),
         url('/fonts/Noto_Sans_JP/NotoSansJP-Bold.woff2');
    font-style: normal;
    font-weight: 400;
    font-display: swap;
  }
  * {
    font-family: 'Noto_Sans_JP_Regular', sans-serif;
  }
  p, div, a, span, input, select, textarea ,label ,button {
    color: ${colors.charcoalGrey};
    letter-spacing: 0.01em;
  }
  /* react-mobile-datepickerで作成するモーダル用 */
  body .datepicker-modal {
    position: fixed;
    .datepicker {
      font-size: 1rem;
      .datepicker-content {
        .datepicker-wheel {
          border-top : 2px solid ${colors.brightGrey};
          border-bottom : 2px solid ${colors.brightGrey};
        }
        li {
          font-size: 1.1rem;
        }
      }
    }
  }
`;

export default GlobalStyle;
