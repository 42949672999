import React from 'react';
import styled from 'styled-components';
import { colors, colorOptions } from 'styles/styles';

export type ButtonProps = {
  children: React.ReactNode;
  themeColor: string;
  color?: string;
  className?: string;
  width?: string;
  height?: string;
  lineHeight?: string;
  fontSize?: string;
  borderRadius?: string;
  border?: string;
  borderTop?: string;
  borderRight?: string;
  borderLeft?: string;
  borderBottom?: string;
  backgroundColor?: string;
  margin?: string;
  padding?: string;
  disabled?: boolean;
  shadow?: boolean;
  nonBold?: boolean;
  onClick?: () => void;
};

const StyledButton = styled.button<ButtonProps>`
  /* color */
  ${(props) => {
    if (props.disabled) {
      return `
      color: ${colors.silverGrey};
      background-color: ${colors.gainsboroGrey};
    `;
    } else if (props.themeColor === colorOptions.WHITE) {
      return `
      color: ${colors.black};
      background-color: ${colors.white};
    `;
    } else if (props.themeColor === colorOptions.BLACK) {
      return `
      color: ${colors.white};
      background-color: ${colors.blackGrey};
    `;
    } else if (props.themeColor === colorOptions.GREY) {
      return `
      color: ${colors.white};
      background-color: ${colors.deepGrey};
    `;
    } else if (props.themeColor === colorOptions.SILVER_GREY) {
      return `
      color: ${colors.silverGrey};
      background-color: ${colors.white};
    `;
    } else if (props.themeColor === colorOptions.BEIGE) {
      return `
      color: ${colors.black};
      background-color: ${colors.deepBeige};
    `;
    } else if (props.themeColor === colorOptions.ORANGE) {
      return `
      color: ${colors.deepBeige};
      background-color: ${colors.white};
    `;
    } else if (props.themeColor === colorOptions.DARK_BEIGE) {
      return `
      color: ${colors.darkBeige};
      background-color: ${colors.white};
    `;
    } else if (props.themeColor === colorOptions.OFF_WHITE_ORANGE) {
      return `
      color: ${colors.red};
      background-color: ${colors.lightGrey};
    `;
    } else if (props.themeColor === colorOptions.OFF_WHITE) {
      return `
      color: ${colors.charcoalGrey};
      background-color: ${colors.platinumGrey};
    `;
    } else if (props.themeColor === colorOptions.CANCEL) {
      return `
      color: ${colors.deepGrey};
      background-color: ${colors.white};
    `;
    } else if (props.themeColor === colorOptions.CHECKED) {
      return `
      color: ${colors.red};
      background-color: ${colors.beige};
    `;
    } else if (props.themeColor === colorOptions.UNCHECKED) {
      return `
      color: ${colors.charcoalGrey};
      background-color: ${colors.lightGrey};
    `;
    } else if (props.themeColor === colorOptions.LINE) {
      return `
      color: ${colors.white};
      background-color: ${colors.line};
    `;
    }
  }}

  font-size: ${(props) => (props.fontSize ? props.fontSize : '0.8rem')};
  padding: ${(props) => (props.padding ? props.padding : '0.8rem 3rem')};
  @media screen and (max-height: 667px) {
    padding: ${(props) => (props.padding ? props.padding : '0.7rem 2.5rem')};
  }
  margin: ${(props) => (props.margin ? props.margin : '0.5rem')};
  width: ${(props) => (props.width ? props.width : null)};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : 'normal')};
  border-radius: ${(props) => (props.borderRadius ? props.borderRadius : '50px')};
  border-top: ${(props) => (props.borderTop ? props.borderTop : 'none')};
  border-right: ${(props) => (props.borderRight ? props.borderRight : 'none')};
  border-left: ${(props) => (props.borderLeft ? props.borderLeft : 'none')};
  border-bottom: ${(props) => (props.borderBottom ? props.borderBottom : 'none')};
  box-shadow: ${(props) => (props.shadow ? '0px 2px 6px rgba(0, 0, 0, 0.25)' : 'none')};
  white-space: pre;
  cursor: pointer;
  font-family: 'Noto_Sans_JP_Bold', sans-serif;
  font-family: ${(props) =>
    props.nonBold ? "'Noto_Sans_JP', sans-serif" : "'Noto_Sans_JP_Bold', sans-serif"};
  :hover {
    opacity: 0.7;
  }
  :focus {
    outline: 0 !important;
  }
  pointer-events: ${(props) => (props.disabled ? 'none' : null)};
`;

export const Button = (props: ButtonProps): JSX.Element => {
  return <StyledButton {...props}>{props.children}</StyledButton>;
};
