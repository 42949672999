import type Liff from '@line/liff';
import React, { createContext, useContext, useEffect, useState } from 'react';

const LiffContext = createContext<typeof Liff>(undefined);

export const LiffProvider: React.FC = ({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element => {
  const [liff, setLiff] = useState<typeof Liff>();

  useEffect(() => {
    (async () => {
      const liff = (await import('@line/liff')).default;
      await liff.init({ liffId: process.env.NEXT_PUBLIC_LIFF_ID });
      setLiff(liff);
    })();
  }, []);

  return <LiffContext.Provider value={liff}>{children}</LiffContext.Provider>;
};

type UseLiffReturn = {
  idToken?: string;
  tokenPayload?: ReturnType<typeof Liff.getDecodedIDToken>;
  initialized: boolean;
  inliffClient: boolean;
  loggedIn: boolean;
  closeWindow?: () => void;
  isExpires: () => boolean;
  login?: () => void;
  logout?: () => void;
  shareTargetPicker: typeof Liff.shareTargetPicker;
  isApiAvailable: typeof Liff.isApiAvailable;
};

export const useLiff = (): UseLiffReturn => {
  const liff = useContext(LiffContext);
  if (!liff) {
    return {
      initialized: false,
      inliffClient: false,
      loggedIn: false,
      isExpires: () => false,
      shareTargetPicker: async () => undefined,
      isApiAvailable: () => false,
    };
  }

  const isExpires = (): boolean => {
    if (!liff.isLoggedIn()) {
      return false;
    }

    const expirationTime = liff.getDecodedIDToken().exp;
    return expirationTime < Date.now() / 1000;
  };

  return {
    idToken: liff.getIDToken(),
    tokenPayload: liff.getDecodedIDToken(),
    initialized: true,
    inliffClient: liff.isInClient(),
    loggedIn: liff.isLoggedIn(),
    closeWindow: liff.closeWindow,
    isExpires: isExpires,
    login: liff.login,
    logout: liff.logout,
    shareTargetPicker: liff.shareTargetPicker,
    isApiAvailable: liff.isApiAvailable,
  };
};
